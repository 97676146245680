const Datenschutz = () => {
    return (
        <div className="bg-primary p-6 md:p-10">
            <div className="container mx-auto bg-white p-5 md:p-10 rounded-3xl shadow-lg">
                <div className="flex flex-col items-center text-gray-500 space-y-10 min-h-screen">
                    <h1 className="text-xl sm:text-3xl md:text-5xl font-bold text-center">Datenschutzerklärung</h1>
                    <div className="w-full md:w-3/4 lg:w-1/2">
                        <h2 className="font-semibold text-xl">Inhaltsverzeichnis</h2>
                        <ul className="list-disc list-inside">
                            <li><a  href="#einleitung-ueberblick" className="text-blue-500 hover:underline">Einleitung und Überblick</a></li>
                            <li><a  href="#anwendungsbereich" className="text-blue-500 hover:underline">Anwendungsbereich</a></li>
                            <li><a  href="#rechtsgrundlagen" className="text-blue-500 hover:underline">Rechtsgrundlagen</a></li>
                            <li><a  href="#kontaktdaten-verantwortliche" className="text-blue-500 hover:underline">Kontaktdaten des Verantwortlichen</a></li>
                            <li><a  href="#speicherdauer" className="text-blue-500 hover:underline">Speicherdauer</a></li>
                            <li><a  href="#rechte-dsgvo" className="text-blue-500 hover:underline">Rechte laut Datenschutz-Grundverordnung</a></li>
                            <li><a  href="#sicherheit-datenverarbeitung" className="text-blue-500 hover:underline">Sicherheit der Datenverarbeitung</a></li>
                            <li><a  href="#kommunikation" className="text-blue-500 hover:underline">Kommunikation</a></li>
                            <li><a  href="#cookies" className="text-blue-500 hover:underline">Cookies</a></li>
                            <li><a  href="#webhosting-einleitung" className="text-blue-500 hover:underline">Webhosting Einleitung</a></li>
                            <li><a  href="#web-analytics-einleitung" className="text-blue-500 hover:underline">Web Analytics Einleitung</a></li>
                            <li><a  href="#social-media-einleitung" className="text-blue-500 hover:underline">Social Media Einleitung</a></li>
                            <li><a  href="#webdesign-einleitung" className="text-blue-500 hover:underline">Webdesign Einleitung</a></li>
                            <li><a  href="#online-kartendienste-einleitung" className="text-blue-500 hover:underline">Online-Kartendienste Einleitung</a></li>
                            <li><a  href="#erklaerung-verwendeter-begriffe" className="text-blue-500 hover:underline">Erklärung verwendeter Begriffe</a></li>
                            <li><a  href="#schlusswort" className="text-blue-500 hover:underline">Schlusswort</a></li>
                        </ul>
                    </div>
                    <div id="einleitung-ueberblick" className="w-full md:w-3/4  space-y-4 break-all">
                        <h2 className="text-2xl font-semibold">Einleitung und Überblick</h2>
                        <h2 id="einleitung-ueberblick" >Einleitung und Überblick</h2>
                        <p>Wir haben diese Datenschutzerklärung (Fassung 11.11.2023-112667801) verfasst, um Ihnen gemäß der Vorgaben der <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112667801#d1e2269-1-1" target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br />
                            <strong >Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
                        <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong >Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden Informationen geboten und <strong >Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
                            Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
                        <h2 id="anwendungsbereich" >Anwendungsbereich</h2>
                        <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
                        <ul >
                            <li >alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
                            <li >Social Media Auftritte und E-Mail-Kommunikation</li>
                            <li >mobile Apps für Smartphones und andere Geräte</li>
                        </ul>
                        <p>
                            <strong >Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
                        <h2 id="rechtsgrundlagen" >Rechtsgrundlagen</h2>
                        <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.<br />
                            Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a> nachlesen.</p>
                        <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
                        <ol>
                            <li >
                                <strong >Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
                            <li >
                                <strong >Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
                            <li >
                                <strong >Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
                            <li >
                                <strong >Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
                        </ol>
                        <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
                        <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
                        <ul >
                            <li >In <strong >Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong >Datenschutzgesetz</strong>), kurz <strong >DSG</strong>.</li>
                            <li >In <strong >Deutschland</strong> gilt das <strong >Bundesdatenschutzgesetz</strong>, kurz<strong > BDSG</strong>.</li>
                        </ul>
                        <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>
                        <h2 id="kontaktdaten-verantwortliche" >Kontaktdaten des Verantwortlichen</h2>
                        <p>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br />
                            <span>Hasan Buga<br />
                                Amerlingstraße 19<br />
                                1060 Wien</span>
                        </p>
                        <h2 id="speicherdauer" >Speicherdauer</h2>
                        <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
                        <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
                        <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
                        <h2 id="rechte-dsgvo" >Rechte laut Datenschutz-Grundverordnung</h2>
                        <p>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
                        <ul >
                            <li >Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
                                <ul >
                                    <li >zu welchem Zweck wir die Verarbeitung durchführen;</li>
                                    <li >die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
                                    <li >wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;</li>
                                    <li >wie lange die Daten gespeichert werden;</li>
                                    <li >das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
                                    <li >dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);</li>
                                    <li >die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
                                    <li >ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
                                </ul>
                            </li>
                            <li >Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
                            <li >Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
                            <li >Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
                            <li >Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
                            <li >Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
                                <ul >
                                    <li >Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
                                    <li >Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
                                    <li >Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
                                </ul>
                            </li>
                            <li >Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
                            <li >Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt.</li>
                        </ul>
                        <p>
                            <strong >Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
                        <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.dsb.gv.at/?tid=112667801" target="_blank" rel="noopener">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noopener">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:</p>
                        <h2 id="oesterreich-datenschutzbehoerde" >Österreich Datenschutzbehörde</h2>
                        <p>
                            <strong >Leiterin: </strong>Mag. Dr. Andrea Jelinek<strong >
                                <br />
                                Adresse: </strong>Barichgasse 40-42, 1030 Wien<strong >
                                <br />
                                Telefonnr.: </strong>+43 1 52 152-0<strong >
                                <br />
                                E-Mail-Adresse: </strong>
                            <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="mailto:dsb@dsb.gv.at" target="_blank" rel="noopener">dsb@dsb.gv.at</a>
                            <strong >
                                <br />
                                Website: </strong>
                            <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.dsb.gv.at/" target="_blank" rel="noopener">https://www.dsb.gv.at/</a>
                        </p>
                        <h2 id="sicherheit-datenverarbeitung" >Sicherheit der Datenverarbeitung</h2>
                        <p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen schließen können.</p>
                        <p>Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.</p>
                        <h2 id="tls-verschluesselung-https" >TLS-Verschlüsselung mit https</h2>
                        <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.<br />
                            Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist &#8211; niemand kann &#8220;mithören&#8221;.</p>
                        <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den Datenschutz durch Technikgestaltung (<a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112667801" target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.<br />
                            Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18" /> links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
                            Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach &#8220;Hypertext Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu erhalten.</p>
                        <h2 id="kommunikation" >Kommunikation</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Kommunikation Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br />
                                        &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br />
                                        &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br />
                                        &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
                        <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben solange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
                        <h3 >Betroffene Personen</h3>
                        <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
                        <h3 >Telefon</h3>
                        <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
                        <h3 >E-Mail</h3>
                        <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
                        <h3 >Online Formulare</h3>
                        <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
                        <h3 >Rechtsgrundlagen</h3>
                        <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
                        <ul >
                            <li >Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
                            <li >Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
                            <li >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
                        </ul>
                        <h2 id="cookies" >Cookies</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Cookies Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Besucher der Website<br />
                                        &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
                                        &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
                                        &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren variieren<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 >Was sind Cookies?</h3>
                        <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br />
                            Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</p>
                        <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
                        <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
                        <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
                        <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>
                        <p>
                            <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg" alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%" />
                        </p>
                        <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>
                        <p>So können zum Beispiel Cookie-Daten aussehen:</p>
                        <p>
                            <strong >Name:</strong> _ga<br />
                            <strong >Wert:</strong> GA1.2.1326744211.152112667801-9<br />
                            <strong >Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br />
                            <strong >Ablaufdatum:</strong> nach 2 Jahren</p>
                        <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
                        <ul >
                            <li >Mindestens 4096 Bytes pro Cookie</li>
                            <li >Mindestens 50 Cookies pro Domain</li>
                            <li >Mindestens 3000 Cookies insgesamt</li>
                        </ul>
                        <h3 >Welche Arten von Cookies gibt es?</h3>
                        <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
                        <p>Man kann 4 Arten von Cookies unterscheiden:</p>
                        <p>
                            <strong >Unerlässliche Cookies<br />
                            </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
                        <p>
                            <strong >Zweckmäßige Cookies<br />
                            </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</p>
                        <p>
                            <strong >Zielorientierte Cookies<br />
                            </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
                        <p>
                            <strong >Werbe-Cookies<br />
                            </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
                        <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
                        <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://datatracker.ietf.org/doc/html/rfc6265">https://datatracker.ietf.org/doc/html/rfc6265</a>, dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management Mechanism&#8221;.</p>
                        <h3 >Zweck der Verarbeitung über Cookies</h3>
                        <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.</p>
                        <h3 >Welche Daten werden verarbeitet?</h3>
                        <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.</p>
                        <h3 >Speicherdauer von Cookies</h3>
                        <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
                        <p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.</p>
                        <h3 >Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
                        <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
                        <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
                        <p>
                            <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://support.google.com/chrome/answer/95647?tid=112667801" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
                        </p>
                        <p>
                            <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=112667801" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
                        </p>
                        <p>
                            <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=112667801" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
                        </p>
                        <p>
                            <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=112667801">Internet Explorer: Löschen und Verwalten von Cookies</a>
                        </p>
                        <p>
                            <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=112667801">Microsoft Edge: Löschen und Verwalten von Cookies</a>
                        </p>
                        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome Browsers.</p>
                        <h3 >Rechtsgrundlage</h3>
                        <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine <strong >Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
                        <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen <strong >berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
                        <p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
                        <p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte Software Cookies verwendet.</p>
                        <h2 id="webhosting-einleitung" >Webhosting Einleitung</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Webhosting Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Besucher der Website<br />
                                        &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />
                                        &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br />
                                        &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 >Was ist Webhosting?</h3>
                        <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
                        <p>Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.</p>
                        <p>Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!</p>
                        <p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet oder Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>
                        <p>Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und dem Hosting-Provider.</p>
                        <p>
                            <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
                        </p>
                        <h3 >Warum verarbeiten wir personenbezogene Daten?</h3>
                        <p>Die Zwecke der Datenverarbeitung sind:</p>
                        <ol>
                            <li >Professionelles Hosting der Website und Absicherung des Betriebs</li>
                            <li >zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
                            <li >Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</li>
                        </ol>
                        <h3 >Welche Daten werden verarbeitet?</h3>
                        <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
                        <ul >
                            <li >die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
                            <li >Browser und Browserversion (z. B. Chrome 87)</li>
                            <li >das verwendete Betriebssystem (z. B. Windows 10)</li>
                            <li >die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.beispielquellsite.de/vondabinichgekommen/" target="_blank" rel="follow noopener">https://www.beispielquellsite.de/vondabinichgekommen/</a>)</li>
                            <li >den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
                            <li >Datum und Uhrzeit</li>
                            <li >in Dateien, den sogenannten Webserver-Logfiles</li>
                        </ul>
                        <h3 >Wie lange werden Daten gespeichert?</h3>
                        <p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
                        <p>
                            <strong >Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>
                        <h3 >Rechtsgrundlage</h3>
                        <p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
                        <p>Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>
                        <h2 id="world4you-datenschutzerklaerung" >World4You Datenschutzerklärung</h2>
                        <p>Es ist gut möglich, dass Sie von dem Webhosting-Anbieter World4You schon einmal gehört haben. Besonders in Österreich freut sich der Webhoster großer Beliebtheit. Dienstanbieter ist das österreichische Unternehmen World4You Internet Services GmbH, Hafenstraße 35, 4020 Linz, Österreich.</p>
                        <h3 >Was ist World4You?</h3>
                        <p>Schon seit 1998 ist das Unternehmen aus der oberösterreichischen Landeshauptstadt im Bereich Webhosting aktiv. World4You betreibt mehrere eigene Rechenzentren in Österreich und setzt auf eine hauseigene Technik. Dadurch wird ein ausfallsicherer Betrieb und eine schnelle Serververbindung gewährleistet. Wie Sie vielleicht auch schon in unserer Einleitung zu Webhosting gelesen haben, werden auch Daten von Ihnen an die Server von World4You übertragen und dort verarbeitet. In erster Linie geht es dabei um technische Daten wie Browserversion oder Betriebssystem, aber zusätzlich werden mit Ihrer IP-Adresse auch personenbezogene Daten verarbeitet.</p>
                        <h3 >Warum verwenden wir World4You?</h3>
                        <p>Bei einer Website schätzen wir, vermutlich ähnlich wie Sie, Zuverlässigkeit, Schnelligkeit und Sicherheit. Selbst wenn Sie unsere Website mitten in der Nacht aufrufen oder wir schon viele Besucher haben, muss sie einwandfrei funktionieren. Wenn Sie Unterseiten anklicken, darf es keine halbe Ewigkeit dauern, bis die Seite vollständig geladen ist. Und falls es doch mal zu Problemen kommt, sollte es ein gutes Backup-System geben, das unsere Inhalte sichert und alle Daten schützt. Damit das alles zu unserer Zufriedenheit klappt, brauchen wir natürlich einen zuverlässigen Webhoster. Mit World4You glauben wir hier einen Partner gefunden zu haben, der unsere Ansprüche erfüllt. World4You hat eigene Rechenzentren und damit eine fixe Bandbreite, was eine Website schnell abrufbar macht. Zudem schätzen wir auch den persönlichen Support des Unternehmens.</p>
                        <p>Diesen Support können Sie selbstverständlich auch nutzen, falls Sie zum Thema Datenschutz bei World4You spezielle Fragen haben. Auch empfehlenswert ist die Datenschutzerklärung der Website, die Sie unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.world4you.com/de/unternehmen/datenschutzerklaerung.html?tid=112667801"  target="_blank" rel="follow noopener">https://www.world4you.com/de/unternehmen/datenschutzerklaerung.html</a> finden. Die FAQs unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.world4you.com/faq/de/dsgvo.html" target="_blank" rel="follow noopener">https://www.world4you.com/faq/de/dsgvo.html</a> haben noch eine eigene DSGVO-Rubrik, unter der Sie auch noch viele nützliche Informationen finden.</p>
                        <h2 id="web-analytics-einleitung" >Web Analytics Einleitung</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Web Analytics Datenschutzerklärung Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Besucher der Website<br />
                                        &#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
                                        &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden Sie beim jeweils eingesetzten Web Analytics Tool.<br />
                                        &#x1f4c5; Speicherdauer: abhängig vom eingesetzten Web-Analytics-Tool<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 >Was ist Web Analytics?</h3>
                        <p>Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens der Website-Besucher, kurz Web Analytics oder Web-Analyse genannt. Dabei werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter (auch Trackingtool genannt) speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen über das Nutzerverhalten auf unserer Website erstellt und uns als Websitebetreiber zur Verfügung gestellt. Zusätzlich bieten die meisten Tools verschiedene Testmöglichkeiten an. So können wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern am besten ankommen. Dafür zeigen wir Ihnen für einen begrenzten Zeitabschnitt zwei verschiedene Angebote. Nach dem Test (sogenannter A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt unsere Websitebesucher interessanter finden. Für solche Testverfahren, wie auch für andere Analytics-Verfahren, können auch Userprofile erstellt werden und die Daten in Cookies gespeichert werden.</p>
                        <h3 >Warum betreiben wir Web Analytics?</h3>
                        <p>Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen für unsere Branche das beste Webangebot auf dem Markt liefern. Um dieses Ziel zu erreichen, wollen wir einerseits das beste und interessanteste Angebot bieten und andererseits darauf achten, dass Sie sich auf unserer Website rundum wohlfühlen. Mit Hilfe von Webanalyse-Tools können wir das Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann entsprechend unser Webangebot für Sie und uns verbessern. So können wir beispielsweise erkennen wie alt unsere Besucher durchschnittlich sind, woher sie kommen, wann unsere Website am meisten besucht wird oder welche Inhalte oder Produkte besonders beliebt sind. All diese Informationen helfen uns die Website zu optimieren und somit bestens an Ihre Bedürfnisse, Interessen und Wünsche anzupassen.</p>
                        <h3 >Welche Daten werden verarbeitet?</h3>
                        <p>Welche Daten genau gespeichert werden, hängt natürlich von den verwendeten Analyse-Tools ab. Doch in der Regel wird zum Beispiel gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche Buttons oder Links Sie klicken, wann Sie eine Seite aufrufen, welchen Browser sie verwenden, mit welchem Gerät (PC, Tablet, Smartphone usw.) Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn Sie damit einverstanden waren, dass auch Standortdaten erhoben werden dürfen, können auch diese durch den Webanalyse-Tool-Anbieter verarbeitet werden.</p>
                        <p>Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen personenbezogene Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert (also in unkenntlicher und gekürzter Form) gespeichert. Für den Zweck der Tests, der Webanalyse und der Weboptimierung werden grundsätzlich keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder Ihre E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie erhoben werden, pseudonymisiert gespeichert. So können Sie als Person nicht identifiziert werden.</p>
                        <p>Das folgende Beispiel zeigt schematisch die Funktionsweise von Google Analytics als Beispiel für client-basiertes Webtracking mit Java-Script-Code.</p>
                        <p>
                            <img role="img" src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg" alt="Schematischer Datenfluss bei Google Analytics" width="100%" />
                        </p>
                        <p>Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom Anbieter ab. Manche Cookies speichern Daten nur für ein paar Minuten bzw. bis Sie die Website wieder verlassen, andere Cookies können Daten über mehrere Jahre speichern.</p>
                        <h3 >
                            <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer der Datenverarbeitung</span>
                        </h3>
                        <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
                        <h3 >Widerspruchsrecht</h3>
                        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Beispiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
                        <h3 >Rechtsgrundlage</h3>
                        <p>Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong > Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
                        <p>Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Web-Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist <strong >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit sie eine Einwilligung erteilt haben.</p>
                        <p>Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir Ihnen auch das Lesen unserer allgemeinen Datenschutzerklärung zu Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
                        <p>Informationen zu speziellen Web-Analytics-Tools, erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
                        <h2 id="google-analytics-datenschutzerklaerung" >Google Analytics Datenschutzerklärung</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Google Analytics Datenschutzerklärung Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Besucher der Website<br />
                                        &#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
                                        &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten und Klickverhalten enthalten. Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                                        &#x1f4c5; Speicherdauer: individuell einstellbar, standardmäßig speichert Google Analytics 4 Daten für 14 Monate<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 >Was ist Google Analytics?</h3>
                        <p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics in der Version Google Analytics 4 (GA4) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Durch die Kombination aus verschiedenen Technologien wie Cookies, Geräte-IDs und Anmeldeinformationen, können Sie als User aber über verschiedene Geräte hinweg identifiziert werden. Dadurch können Ihre Handlungen auch plattformübergreifend analysiert werden.</p>
                        <p>Wenn Sie beispielsweise einen Link anklicken, wird dieses Ereignis in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere Website und unseren Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking-Tool ein und informieren Sie vor allem darüber, welche Daten verarbeitet werden und wie Sie das verhindern können.</p>
                        <p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Basis dieser Messungen und Analysen ist eine pseudonyme Nutzeridentifikationsnummer. Diese Nummer beinhaltet keine personenbezogenen Daten wie Name oder Adresse, sondern dient dazu, Ereignisse einem Endgerät zuzuordnen. GA4 nutzt ein ereignisbasiertes Modell, das detaillierte Informationen zu Userinteraktionen wie etwa Seitenaufrufe, Klicks, Scrollen, Conversion-Ereignisse erfasst. Zudem wurden in GA4 auch verschiedene maschinelle Lernfunktionen eingebaut, um das Nutzerverhalten und gewissen Trends besser zu verstehen. GA4 setzt mit Hilfe maschineller Lernfunktionen auf Modellierungen. Das heißt auf Grundlage der erhobenen Daten können auch fehlende Daten hochgerechnet werden, um damit die Analyse zu optimieren und auch um Prognosen geben zu können.</p>
                        <p>Damit Google Analytics grundsätzlich funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Ereignisse auf, die Sie auf unserer Website ausführen. Mit dem ereignisbasierten Datenmodell von GA4 können wir als Websitebetreiber spezifische Ereignisse definieren und verfolgen, um Analysen von Userinteraktionen zu erhalten. Somit können neben allgemeinen Informationen wie Klicks oder Seitenaufrufe auch spezielle Ereignisse, die für unser Geschäft wichtig sind, verfolgt werden. Solche speziellen Ereignisse können zum Beispiel das Absenden eines Kontaktformulars oder der Kauf eines Produkts sein.</p>
                        <p>Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
                        <p>Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:</p>
                        <ul >
                            <li >Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich für unser Service interessiert.</li>
                            <li >Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren und verbessern.</li>
                            <li >Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie wir mehr Menschen für unseren Service begeistern können.</li>
                            <li >Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.</li>
                            <li >Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.</li>
                            <li >Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</li>
                        </ul>
                        <p>Neben den oben genannten Analyseberichten bietet Google Analytics 4 unter anderem auch folgende Funktionen an:</p>
                        <ul >
                            <li >Ereignisbasiertes Datenmodell: Dieses Modell erfasst ganz spezifische Ereignisse, die auf unserer Website stattfinden können. Zum Beispiel das Abspielen eines Videos, der Kauf eines Produkts oder das Anmelden zu unserem Newsletter.</li>
                            <li >Erweiterte Analysefunktionen: Mit diesen Funktionen können wir Ihr Verhalten auf unserer Website oder gewisse allgemeine Trends noch besser verstehen. So können wir etwa Usergruppen segmentieren, Vergleichsanalysen von Zielgruppen machen oder Ihren Weg bzw. Pfad auf unserer Website nachvollziehen.</li>
                            <li >Vorhersagemodellierung: Auf Grundlage erhobener Daten können durch maschinelles Lernen fehlende Daten hochgerechnet werden, die zukünftige Ereignisse und Trends vorhersagen. Das kann uns helfen, bessere Marketingstrategien zu entwickeln.</li>
                            <li >Cross-Plattform-Analyse: Die Erfassung und Analyse von Daten sind sowohl von Websites als auch von Apps möglich. Das bietet uns die Möglichkeit, das Userverhalten plattformübergreifend zu analysieren, sofern Sie natürlich der Datenverarbeitung eingewilligt haben.</li>
                        </ul>
                        <h3 >Warum verwenden wir Google Analytics auf unserer Website?</h3>
                        <p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen den bestmöglichen Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
                        <p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website. Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und kostengünstiger durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.</p>
                        <h3 >Welche Daten werden von Google Analytics gespeichert?</h3>
                        <p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User und Ihnen wird eine User-ID zugeordnet. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es erst möglich pseudonyme Userprofile auszuwerten.</p>
                        <p>Um mit Google Analytics unsere Website analysieren zu können, muss eine Property-ID in den Tracking-Code eingefügt werden. Die Daten werden dann in der entsprechenden Property gespeichert. Für jede neu angelegte Property ist die Google Analytics 4-Property standardmäßig. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</p>
                        <p>Durch Kennzeichnungen wie Cookies, App-Instanz-IDs, User-IDs oder etwa benutzerdefinierte Ereignisparameter werden Ihre Interaktionen, sofern Sie eingewilligt haben, plattformübergreifend gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
                        <p>Laut Google werden in Google Analytics 4 keine IP-Adressen protokolliert oder gespeichert. Google nutzt die IP-Adressdaten allerdings für die Ableitung von Standortdaten und löscht sie unmittelbar danach. Alle IP-Adressen, die von Usern in der EU erhoben werden, werden also gelöscht, bevor die Daten in einem Rechenzentrum oder auf einem Server gespeichert werden.</p>
                        <p>Da bei Google Analytics 4 der Fokus auf ereignisbasierten Daten liegt, verwendet das Tool im Vergleich zu früheren Versionen (wie Google Universal Analytics) deutlich weniger Cookies. Dennoch gibt es einige spezifische Cookies, die von GA4 verwendet werden. Dazu zählen zum Beispiel:</p>
                        <p>
                            <strong >Name:</strong> _ga<br />
                            <strong >Wert: </strong>2.1326744211.152112667801-5<br />
                            <strong >Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br />
                            <strong >Ablaufdatum:</strong> nach 2 Jahren</p>
                        <p>
                            <strong >Name:</strong> _gid<br />
                            <strong >Wert: </strong>2.1687193234.152112667801-1<br />
                            <strong >Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br />
                            <strong >Ablaufdatum:</strong> nach 24 Stunden</p>
                        <p>
                            <strong >Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br />
                            <strong >Wert:</strong> 1<br />
                            <strong >Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_ &lt;property-id&gt;.<br />
                            <strong >Ablaufdatum: </strong>nach 1 Minute</p>
                        <p>
                            <strong >Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert. Ziel von GA4 ist es auch, den Datenschutz zu verbessern. Daher bietet das Tool einige Möglichkeiten zur Kontrolle der Datenerfassung. So können wir beispielsweise die Speicherdauer selbst festlegen und auch die Datenerfassung steuern.</p>
                        <p>Hier zeigen wir Ihnen einen Überblick über die wichtigsten Arten von Daten, die mit Google Analytics erhoben werden:</p>
                        <p>
                            <strong >Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.</p>
                        <p>
                            <strong >Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.</p>
                        <p>
                            <strong >Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
                        <p>
                            <strong >Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.</p>
                        <p>
                            <strong >Standort:</strong> IP-Adressen werden in Google Analytics nicht protokolliert oder gespeichert. Allerdings werden kurz vor der Löschung der IP-Adresse Ableitungen für Standortdaten genutzt.</p>
                        <p>
                            <strong >Technische Informationen:</strong> Zu den technischen Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.</p>
                        <p>
                            <strong >Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
                        <p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z. B., wenn Sie ein Video über unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</p>
                        <h3 >Wie lange und wo werden die Daten gespeichert?</h3>
                        <p>Google hat ihre Server auf der ganzen Welt verteilt. Hier können Sie genau nachlesen, wo sich die Google-Rechenzentren befinden: <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.google.com/about/datacenters/locations/?hl=de">https://www.google.com/about/datacenters/locations/?hl=de</a>
                        </p>
                        <p>Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
                        <p>Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties ab. Die Speicherdauer wird stets für jede einzelne Property eigens festgelegt. Google Analytics bietet uns zur Kontrolle der Speicherdauer vier Optionen an:</p>
                        <ul >
                            <li >2 Monate: das ist die kürzeste Speicherdauer.</li>
                            <li >14 Monate: standardmäßig bleiben die Daten bei GA4 für 14 Monate gespeichert.</li>
                            <li >26 Monate: man kann die Daten auch 26 Monate lang speichern.</li>
                            <li >Daten werden erst gelöscht, wenn wir sie manuell löschen</li>
                        </ul>
                        <p>Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zurückgesetzt, wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.</p>
                        <p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.</p>
                        <h3 >Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
                        <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (analytics.js, gtag.js) verhindern Sie, dass Google Analytics 4 Ihre Daten verwendet. Das Browser-Add-on können Sie unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.</p>
                        <p>Falls Sie grundsätzlich Cookies deaktivieren, löschen oder verwalten wollen, finden Sie unter dem Abschnitt „Cookies“ die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
                        <h3 >Rechtsgrundlage</h3>
                        <p>Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong > Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
                        <p>Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Google Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist <strong >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Analytics gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
                        <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="follow noopener"> https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
                        <p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
                        </p>
                        <p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
                        <p>Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://marketingplatform.google.com/about/analytics/terms/de/" target="_blank" rel="follow noopener">https://marketingplatform.google.com/about/analytics/terms/de/</a> und <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="follow noopener">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
                        <p>Wenn Sie mehr über die Datenverarbeitung erfahren wollen, nutzen Sie die Google-Datenschutzerklärung auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://policies.google.com/privacy?hl=de&amp;tid=112667801" target="_blank" rel="follow noopener">https://policies.google.com/privacy?hl=de</a>.</p>
                        <h2 id="google-analytics-berichte-zu-demografischen-merkmalen-und-interessen" >Google Analytics Berichte zu demografischen Merkmalen und Interessen</h2>
                        <p>Wir haben in Google Analytics die Funktionen für Werbeberichte eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können wir uns &#8211; ohne diese Daten einzelnen Personen zuordnen zu können &#8211; ein besseres Bild von unseren Nutzern machen. Mehr über die Werbefunktionen erfahren Sie auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad">https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad</a>.</p>
                        <p>Sie können die Nutzung der Aktivitäten und Informationen Ihres Google Kontos unter “Einstellungen für Werbung” auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> per Checkbox beenden.</p>
                        <h2 id="google-analytics-im-einwilligungsmodus" >Google Analytics im Einwilligungsmodus</h2>
                        <p>Abhängig von Ihrer Einwilligung werden im sogenannten Einwilligungsmodus (bzw. „Consent Mode“) personenbezogene Daten von Ihnen durch Google Analytics verarbeitet. Sie können wählen, ob Sie Google-Analytics-Cookies zustimmen oder nicht. Damit wählen Sie auch, welche Daten Google Analytics von Ihnen verarbeitet darf. Diese erhobenen Daten werden hauptsächlich dafür verwendet, Messungen über das Userverhalten auf der Website durchzuführen, zielgerichtete Werbung auszuspielen und uns Web-Analyseberichte zu liefern. In der Regel willigen Sie der Datenverarbeitung durch Google über ein Cookie-Consent-Tool ein. Wenn Sie der Datenverarbeitung nicht einwilligen, werden nur aggregierte Daten erfasst und verarbeitet. Das bedeutet, Daten können einzelnen Usern nicht zugeordnet werden und es entsteht somit kein Userprofil von Ihnen. Sie können auch nur der statistischen Messung zustimmen. Dabei werden keine personenbezogenen Daten verarbeitet und folglich nicht für Werbungen oder Werbemesserfolge verwendet.</p>
                        <h2 id="social-media-einleitung" >Social Media Einleitung</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Social Media Datenschutzerklärung Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Besucher der Website<br />
                                        &#x1f91d; Zweck: Darstellung und Optimierung unserer Serviceleistung, Kontakt zu Besuchern, Interessenten u.a., Werbung<br />
                                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa Telefonnummern, E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
                                        Mehr Details dazu finden Sie beim jeweils eingesetzten Social-Media-Tool.<br />
                                        &#x1f4c5; Speicherdauer: abhängig von den verwendeten Social-Media-Plattformen<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 >Was ist Social Media?</h3>
                        <p>Zusätzlich zu unserer Website sind wir auch in diversen Social-Media-Plattformen aktiv. Dabei können Daten von Usern verarbeitet werden, damit wir gezielt User, die sich für uns interessieren, über die sozialen Netzwerke ansprechen können. Darüber hinaus können auch Elemente einer Social-Media-Plattform direkt in unsere Website eingebettet sein. Das ist etwa der Fall, wenn Sie einen sogenannten Social-Button auf unserer Website anklicken und direkt zu unserem Social-Media-Auftritt weitergeleitet werden. Als sogenannte Sozialen Medien oder Social Media werden Websites und Apps bezeichnet, über die angemeldete Mitglieder Inhalte produzieren, Inhalte offen oder in bestimmten Gruppen austauschen und sich mit anderen Mitgliedern vernetzen können.</p>
                        <h3 >Warum nutzen wir Social Media?</h3>
                        <p>Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online kommunizieren und in Kontakt treten. Mit unseren Social-Media-Auftritten können wir unsere Produkte und Dienstleistungen Interessenten näherbringen. Die auf unserer Website eingebundenen Social-Media-Elemente helfen Ihnen, schnell und ohne Komplikationen zu unseren Social-Media-Inhalten wechseln können.</p>
                        <p>Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals gespeichert und verarbeitet werden, haben in erster Linie den Zweck, Webanalysen durchführen zu können. Ziel dieser Analysen ist es, genauere und personenbezogene Marketing- und Werbestrategien entwickeln zu können. Abhängig von Ihrem Verhalten auf einer Social-Media-Plattform, können mit Hilfe der ausgewerteten Daten, passende Rückschlüsse auf Ihre Interessen getroffen werden und sogenannte Userprofile erstellt werden. So ist es den Plattformen auch möglich, Ihnen maßgeschneiderte Werbeanzeigen zu präsentieren. Meistens werden für diesen Zweck Cookies in Ihrem Browser gesetzt, die Daten zu Ihrem Nutzungsverhalten speichern.</p>
                        <p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europäische Gerichtshof hat jedoch entschieden, dass in bestimmten Fällen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist dann weiter unten bei der betroffenen Plattform wiedergegeben.</p>
                        <p>Bitte beachten Sie, dass bei der Nutzung der Social-Media-Plattformen oder unserer eingebauten Elemente auch Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden können, da viele Social-Media-Kanäle, beispielsweise Facebook oder Twitter, amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
                        <h3 >Welche Daten werden verarbeitet?</h3>
                        <p>Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen Anbieter der Social-Media-Plattform ab. Aber für gewöhnlich handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in ein Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons Sie klicken, wen Sie liken oder wem folgen, wann Sie welche Seiten besucht haben, Informationen zu Ihrem Gerät und Ihre IP-Adresse. Die meisten dieser Daten werden in Cookies gespeichert. Speziell wenn Sie selbst ein Profil bei dem besuchten Social-Media-Kanal haben und angemeldet sind, können Daten mit Ihrem Profil verknüpft werden.</p>
                        <p>Alle Daten, die über eine Social-Media-Plattform erhoben werden, werden auch auf den Servern der Anbieter gespeichert. Somit haben auch nur die Anbieter Zugang zu den Daten und können Ihnen die passenden Auskünfte geben bzw. Änderungen vornehmen.</p>
                        <p>Wenn Sie genau wissen wollen, welche Daten bei den Social-Media-Anbietern gespeichert und verarbeitet werden und wie sie der Datenverarbeitung widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des Unternehmens sorgfältig durchlesen. Auch wenn Sie zur Datenspeicherung und Datenverarbeitung Fragen haben oder entsprechende Rechte geltend machen wollen, empfehlen wir Ihnen, sich direkt an den Anbieter wenden.</p>
                        <h3 >
                            <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer der Datenverarbeitung</span>
                        </h3>
                        <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichert die Social-Media-Plattform Facebook Daten, bis sie für den eigenen Zweck nicht mehr benötigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen werden, werden aber schon innerhalb von zwei Tagen gelöscht. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
                        <h3 >Widerspruchsrecht</h3>
                        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern wie eingebettete Social-Media-Elemente zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
                        <p>Da bei Social-Media-Tools Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
                        <h3 >Rechtsgrundlage</h3>
                        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong >(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong >(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
                        <p>Informationen zu speziellen Social-Media-Plattformen erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
                        <h2 id="facebook-datenschutzerklaerung" >Facebook Datenschutzerklärung</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Facebook Datenschutzerklärung Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Besucher der Website<br />
                                        &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
                                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kundendaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
                                        Mehr Details dazu finden Sie weiter unten in der Datenschutzerklärung.<br />
                                        &#x1f4c5; Speicherdauer: bis die Daten für Facebooks Zwecke nicht mehr nützlich sind<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 >Was sind Facebook-Tools?</h3>
                        <p>Wir verwenden auf unserer Website ausgewählte Tools von Facebook. Facebook ist ein Social Media Network des Unternehmens Meta Platforms Inc. bzw. für den europäischen Raum des Unternehmens Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Mithilfe dieser Tools können wir Ihnen und Menschen, die sich für unsere Produkte und Dienstleistungen interessieren, das bestmögliche Angebot bieten.</p>
                        <p>Wenn über unsere eingebetteten Facebook-Elemente oder über unsere Facebook-Seite (Fanpage) Daten von Ihnen erhoben und weitergeleitet werden, sind sowohl wir als auch Facebook Irland Ltd. dafür verantwortlich. Für die weitere Verarbeitung dieser Daten trägt Facebook allein die Verantwortung. Unsere gemeinsamen Verpflichtungen wurden auch in einer öffentlich zugänglichen Vereinbarung unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/legal/controller_addendum">https://www.facebook.com/legal/controller_addendum</a> verankert. Darin ist etwa festgehalten, dass wir Sie klar über den Einsatz der Facebook-Tools auf unserer Seite informieren müssen. Weiters sind wir auch dafür verantwortlich, dass die Tools datenschutzrechtlich sicher in unsere Website eingebunden sind. Facebook ist hingegen beispielsweise für die Datensicherheit der Facebook-Produkte verantwortlich. Bei etwaigen Fragen zur Datenerhebung und Datenverarbeitung durch Facebook können Sie sich direkt an das Unternehmen wenden. Wenn Sie die Frage an uns richten, sind wir dazu verpflichtet diese an Facebook weiterleiten.</p>
                        <p>Im Folgenden geben wir einen Überblick über die verschiedenen Facebook Tools, welche Daten an Facebook gesendet werden und wie Sie diese Daten löschen können.</p>
                        <p>Neben vielen anderen Produkten bietet Facebook auch die sogenannten &#8220;Facebook Business Tools&#8221; an. Das ist die offizielle Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns dafür entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden sich unter anderem:</p>
                        <ul >
                            <li >Facebook-Pixel</li>
                            <li >soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)</li>
                            <li >Facebook Login</li>
                            <li >Account Kit</li>
                            <li >APIs (Programmierschnittstelle)</li>
                            <li >SDKs (Sammlung von Programmierwerkzeugen)</li>
                            <li >Plattform-Integrationen</li>
                            <li >Plugins</li>
                            <li >Codes</li>
                            <li >Spezifikationen</li>
                            <li >Dokumentationen</li>
                            <li >Technologien und Dienstleistungen</li>
                        </ul>
                        <p>Durch diese Tools erweitert Facebook Dienstleistungen und hat die Möglichkeit, Informationen über User-Aktivitäten außerhalb von Facebook zu erhalten.</p>
                        <h3 >Warum verwenden wir Facebook-Tools auf unserer Website?</h3>
                        <p>Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen, die sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den Usern passende Werbung gezeigt werden kann, benötigt Facebook allerdings Informationen über die Wünsche und Bedürfnisse der Menschen. So werden dem Unternehmen Informationen über das Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verfügung gestellt. Dadurch sammelt Facebook bessere User-Daten und kann interessierten Menschen die passende Werbung über unsere Produkte bzw. Dienstleistungen anzeigen. Die Tools ermöglichen somit maßgeschneiderte Werbekampagnen auf Facebook.</p>
                        <p>Daten über Ihr Verhalten auf unserer Webseite nennt Facebook „Event-Daten“. Diese werden auch für Messungs- und Analysedienste verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“ über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen wir durch Analysen einen besseren Einblick, wie Sie unsere Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren wir mit einigen dieser Tools Ihre Nutzererfahrung auf unserer Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte auf unserer Seite direkt auf Facebook teilen.</p>
                        <h3 >Welche Daten werden von Facebook-Tools gespeichert?</h3>
                        <p>Durch die Nutzung einzelner Facebook-Tools können personenbezogene Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer und IP-Adresse versandt werden.</p>
                        <p>Facebook verwendet diese Informationen, um die Daten mit den Daten, die es selbst von Ihnen hat (sofern Sie Facebook-Mitglied sind) abzugleichen. Bevor Kundendaten an Facebook übermittelt werden, erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig großer Datensatz in eine Zeichenkette transformiert wird. Dies dient auch der Verschlüsselung von Daten.</p>
                        <p>Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie besuchen oder welche Produkte Sie bei uns kaufen. Facebook teilt die erhaltenen Informationen nicht mit Drittanbietern (wie beispielsweise Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung oder ist rechtlich dazu verpflichtet. „Event-Daten“ können auch mit Kontaktdaten verbunden werden. Dadurch kann Facebook bessere personalisierte Werbung anbieten. Nach dem bereits erwähnten Abgleichungsprozess löscht Facebook die Kontaktdaten wieder.</p>
                        <p>Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook die Event-Daten nur, wenn diese mit anderen Daten (die auf andere Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese Event-Daten nützt Facebook auch für Sicherheits-, Schutz-, Entwicklungs- und Forschungszwecke. Viele dieser Daten werden über Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien, die zum Speichern von Daten bzw. Informationen in Browsern verwendet werden. Je nach verwendeten Tools und abhängig davon, ob Sie Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine Informationen über die Verwendung von Facebook-Cookies erfahren Sie auch auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/policies/cookies?tid=112667801">https://www.facebook.com/policies/cookies</a>.</p>
                        <h3 >Wie lange und wo werden die Daten gespeichert?</h3>
                        <p>Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat auf der ganzen Welt Server verteilt, wo seine Daten gespeichert werden. Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten abgeglichen wurden, innerhalb von 48 Stunden gelöscht.</p>
                        <h3 >Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
                        <p>Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.</p>
                        <p>Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr Facebook-Konto vollständig löschen. Und so funktioniert das Löschen Ihres Facebook-Kontos:</p>
                        <p>1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
                        <p>2) Anschließend klicken Sie in der linken Spalte auf „Deine Facebook-Informationen“.</p>
                        <p>3) Nun klicken Sie &#8220;Deaktivierung und Löschung&#8221;.</p>
                        <p>4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter und Konto löschen“</p>
                        <p>5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann auf „Konto löschen“</p>
                        <p>Die Speicherung der Daten, die Facebook über unsere Seite erhält, erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
                        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
                        <h3 >Rechtsgrundlage</h3>
                        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Facebook-Tools verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong >(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong >(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien von Facebook anzusehen.</p>
                        <p>Facebook verarbeitet Daten von Ihnen u.a. auch in den USA. Facebook bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="follow noopener">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
                        <p>Zudem verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Facebook, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
                        </p>
                        <p>Die Facebook Datenverarbeitungsbedingung, welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/legal/terms/dataprocessing">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
                        <p>Wir hoffen, wir haben Ihnen die wichtigsten Informationen über die Nutzung und Datenverarbeitung durch die Facebook-Tools nähergebracht. Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten verwendet, empfehlen wir Ihnen die Datenrichtlinien auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/privacy/policy/?tid=112667801">https://www.facebook.com/privacy/policy/</a>.</p>
                        <h2 id="facebook-login-datenschutzerklaerung" >Facebook Login Datenschutzerklärung</h2>
                        <p>Wir haben auf unserer Seite das praktische Facebook Login integriert. So können Sie sich bei uns ganz einfach mit Ihrem Facebook-Konto einloggen, ohne ein weiteres Benutzerkonto anlegen zu müssen. Wenn Sie sich entscheiden, Ihre Registrierung über das Facebook Login zu machen, werden Sie auf das Social Media Network Facebook weitergeleitet. Dort erfolgt die Anmeldung über Ihre Facebook Nutzerdaten. Durch dieses Login-Verfahren werden Daten über Sie bzw. Ihr Userverhalten gespeichert und an Facebook übermittelt.</p>
                        <p>Um die Daten zu speichern, benutzt Facebook verschiedene Cookies. Im Folgenden zeigen wir Ihnen die wichtigsten Cookies, die in Ihrem Browser gesetzt werden bzw. schon bestehen, wenn Sie sich über das Facebook Login auf unserer Seite anmelden:</p>
                        <p>
                            <strong >Name:</strong> fr<br />
                            <strong >Wert:</strong> 0jieyh4c2GnlufEJ9..Bde09j&#8230;1.0.Bde09j<br />
                            <strong >Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit das soziale Plugin auf unserer Webseite bestmöglich funktioniert.<br />
                            <strong >Ablaufdatum:</strong> nach 3 Monaten</p>
                        <p>
                            <strong >Name:</strong> datr<br />
                            <strong >Wert:</strong> 4Jh7XUA2112667801SEmPsSfzCOO4JFFl<br />
                            <strong >Verwendungszweck:</strong> Facebook setzt das &#8220;datr&#8221;-Cookie, wenn ein Webbrowser auf facebook.com zugreift, und das Cookie hilft, Anmeldeaktivitäten zu identifizieren und die Benutzer zu schützen.<br />
                            <strong >Ablaufdatum:</strong> nach 2 Jahren</p>
                        <p>
                            <strong >Name:</strong> _js_datr<br />
                            <strong >Wert:</strong> deleted<br />
                            <strong >Verwendungszweck:</strong> Dieses Session-Cookie setzt Facebook zu Trackingzwecken, auch wenn Sie kein Facebook-Konto haben oder ausgeloggt sind.<br />
                            <strong >Ablaufdatum:</strong> nach Sitzungsende</p>
                        <p>
                            <strong >Anmerkung:</strong> Die angeführten Cookies sind nur eine kleine Auswahl der Cookies, die Facebook zur Verfügung stehen. Weitere Cookies sind beispielsweise _ fbp, sb oder wd. Eine vollständige Aufzählung ist nicht möglich, da Facebook über eine Vielzahl an Cookies verfügt und diese variabel einsetzt.</p>
                        <p>Der Facebook Login bietet Ihnen einerseits einen schnellen und einfachen Registrierungsprozess, andererseits haben wir so die Möglichkeit Daten mit Facebook zu teilen. Dadurch können wir unser Angebot und unsere Werbeaktionen besser an Ihre Interessen und Bedürfnisse anpassen. Daten, die wir auf diese Weise von Facebook erhalten, sind öffentliche Daten wie</p>
                        <ul >
                            <li >Ihr Facebook-Name</li>
                            <li >Ihr Profilbild</li>
                            <li >eine hinterlegte E-Mail-Adresse</li>
                            <li >Freundeslisten</li>
                            <li >Buttons-Angaben (z.B. „Gefällt mir“-Button)</li>
                            <li >Geburtstagsdatum</li>
                            <li >Sprache</li>
                            <li >Wohnort</li>
                        </ul>
                        <p>Im Gegenzug stellen wir Facebook Informationen über Ihre Aktivitäten auf unserer Webseite bereit. Das sind unter anderem Informationen über Ihr verwendetes Endgerät, welche Unterseiten Sie bei uns besuchen oder welche Produkte Sie bei uns erworben haben.</p>
                        <p>Durch die Verwendung von Facebook Login willigen Sie der Datenverarbeitung ein. Sie können diese Vereinbarung jederzeit widerrufen. Wenn Sie mehr Informationen über die Datenverarbeitung durch Facebook erfahren wollen, empfehlen wir Ihnen die Facebook-Datenschutzerklärung unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/privacy/policy/?tid=112667801">https://www.facebook.com/privacy/policy/</a>.</p>
                        <p>Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/adpreferences/advertisers/?entry_product=ad_settings_screen">https://www.facebook.com/adpreferences/advertisers/?entry_product=ad_settings_screen</a> selbst verändern.</p>
                        <h2 id="facebook-soziale-plug-ins-datenschutzerklaerung" >Facebook Soziale Plug-ins Datenschutzerklärung</h2>
                        <p>Auf unserer Website sind sogenannte soziale Plug-ins des Unternehmens Meta Platforms Inc. eingebaut. Sie erkennen diese Buttons am klassischen Facebook-Logo, wie dem „Gefällt mir“-Button (die Hand mit erhobenem Daumen) oder an einer eindeutigen „Facebook Plug-in“-Kennzeichnung. Ein soziales Plug-in ist ein kleiner Teil von Facebook, der in unsere Seite integriert ist. Jedes Plug-in hat eine eigene Funktion. Die am meisten verwendeten Funktionen sind die bekannten &#8220;Gefällt mir&#8221;- und &#8220;Teilen&#8221;-Buttons.</p>
                        <p>Folgende soziale Plug-ins werden von Facebook angeboten:</p>
                        <ul >
                            <li >&#8220;Speichern&#8221;-Button</li>
                            <li >&#8220;Gefällt mir&#8221;-Button, Teilen, Senden und Zitat</li>
                            <li >Seiten-Plug-in</li>
                            <li >Kommentare</li>
                            <li >Messenger-Plug-in</li>
                            <li >Eingebettete Beiträge und Videoplayer</li>
                            <li >Gruppen-Plug-in</li>
                        </ul>
                        <p>Auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://developers.facebook.com/docs/plugins">https://developers.facebook.com/docs/plugins</a> erhalten Sie nähere Informationen, wie die einzelnen Plug-ins verwendet werden. Wir nützen die sozialen Plug-ins einerseits, um Ihnen ein besseres Usererlebnis auf unserer Seite zu bieten, andererseits weil Facebook dadurch unsere Werbeanzeigen optimieren kann.</p>
                        <p>Sofern Sie ein Facebook-Konto haben oder <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/">https://www.facebook.com/</a> schon mal besucht haben, hat Facebook bereits mindestens ein Cookie in Ihrem Browser gesetzt. In diesem Fall sendet Ihr Browser über dieses Cookie Informationen an Facebook, sobald Sie unsere Seite besuchen bzw. mit sozialen Plug-ins (z.B. dem „Gefällt mir“-Button) interagieren.</p>
                        <p>Die erhaltenen Informationen werden innerhalb von 90 Tagen wieder gelöscht bzw. anonymisiert. Laut Facebook gehören zu diesen Daten Ihre IP-Adresse, welche Webseite Sie besucht haben, das Datum, die Uhrzeit und weitere Informationen, die Ihren Browser betreffen.</p>
                        <p>Um zu verhindern, dass Facebook während Ihres Besuches auf unserer Webseite viele Daten sammelt und mit den Facebook-Daten verbindet, müssen Sie sich während des Webseitenbesuchs von Facebook abmelden (ausloggen).</p>
                        <p>Falls Sie bei Facebook nicht angemeldet sind oder kein Facebook-Konto besitzen, sendet Ihr Browser weniger Informationen an Facebook, weil Sie weniger Facebook-Cookies haben. Dennoch können Daten wie beispielsweise Ihre IP-Adresse oder welche Webseite Sie besuchen an Facebook übertragen werden. Wir möchten noch ausdrücklich darauf hinweisen, dass wir über die genauen Inhalte der Daten nicht exakt Bescheid wissen. Wir versuchen aber Sie nach unserem aktuellen Kenntnisstand so gut als möglich über die Datenverarbeitung aufzuklären. Wie Facebook die Daten nutzt, können Sie auch in den Datenrichtline des Unternehmens unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/about/privacy/update">https://www.facebook.com/about/privacy/update</a> nachlesen.</p>
                        <p>Folgende Cookies werden in Ihrem Browser mindestens gesetzt, wenn Sie eine Webseite mit sozialen Plug-ins von Facebook besuchen:</p>
                        <p>
                            <strong >Name:</strong> dpr<br />
                            <strong >Wert:</strong> keine Angabe<br />
                            <strong >Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit die sozialen Plug-ins auf unserer Webseite funktionieren.<br />
                            <strong >Ablaufdatum:</strong> nach Sitzungsende</p>
                        <p>
                            <strong >Name:</strong> fr<br />
                            <strong >Wert:</strong> 0jieyh4112667801c2GnlufEJ9..Bde09j&#8230;1.0.Bde09j<br />
                            <strong >Verwendungszweck:</strong> Auch das Cookie ist nötig, dass die Plug-ins einwandfrei funktionieren.<br />
                            <strong >Ablaufdatum::</strong> nach 3 Monaten</p>
                        <p>
                            <strong >Anmerkung:</strong> Diese Cookies wurden nach einem Test gesetzt, auch wenn Sie nicht Facebook-Mitglied sind.</p>
                        <p>Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/adpreferences/advertisers/">https://www.facebook.com/adpreferences/advertisers/</a> selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.youronlinechoices.com/de/praferenzmanagement/?tid=112667801" target="_blank" rel="follow noopener">https://www.youronlinechoices.com/de/praferenzmanagement/?tid=112667801</a> grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu aktivieren.</p>
                        <p>Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen, empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/privacy/policy/?tid=112667801" target="_blank" rel="follow noopener">https://www.facebook.com/privacy/policy/</a>.</p>
                        <h2 id="facebook-fanpage-datenschutzerklaerung" >Facebook Fanpage Datenschutzerklärung</h2>
                        <p>Wir haben für unsere Website auch eine Facebook Fanpage. Dienstanbieter ist das amerikanische Unternehmen Meta Platforms Inc. Für den europäischen Raum ist das Unternehmen Meta Platforms Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) verantwortlich.</p>
                        <p>Facebook verarbeitet Daten von Ihnen u.a. auch in den USA. Facebook bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="follow noopener"> https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
                        <p>Zudem verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Facebook, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
                        </p>
                        <p>Die Facebook Datenverarbeitungsbedingung, welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/legal/terms/dataprocessing">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
                        <p>Mehr über die Daten, die durch die Verwendung von Facebook verarbeitet werden, erfahren Sie in der Privacy Policy auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a>.</p>
                        <h2 id="instagram-datenschutzerklaerung" >Instagram Datenschutzerklärung</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Instagram Datenschutzerklärung Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Besucher der Website<br />
                                        &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
                                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
                                        Mehr Details dazu finden Sie weiter unten in der Datenschutzerklärung.<br />
                                        &#x1f4c5; Speicherdauer: bis Instagram die Daten für ihre Zwecke nicht mehr benötigt<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 >Was ist Instagram?</h3>
                        <p>Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media Plattform des Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein Tochterunternehmen von Meta Platforms Inc. und gehört zu den Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine Instagram-Funktion integriert haben, werden Daten an Instagram übermittelt, gespeichert und verarbeitet. Instagram verwendet dieselben Systeme und Technologien wie Facebook. Ihre Daten werden somit über alle Facebook-Firmen hinweg verarbeitet.</p>
                        <p>Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten es sich handelt und wie Sie die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu Meta Platforms Inc. gehört, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits allerdings auch von den Meta-Datenschutzrichtlinien selbst.</p>
                        <p>Instagram ist eines der bekanntesten Social Media Netzwerken weltweit. Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht aktiv sein wollen, können Sie auch nur anderen interessante Users folgen.</p>
                        <h3 >Warum verwenden wir Instagram auf unserer Website?</h3>
                        <p>Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging. Und natürlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite so wohl wie möglich fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer Inhalte selbstverständlich. Durch die eingebetteten Instagram-Funktionen können wir unseren Content mit hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist, können uns die erhobenen Daten auch für personalisierte Werbung auf Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen, die sich wirklich für unsere Produkte oder Dienstleistungen interessieren.</p>
                        <p>Instagram nützt die gesammelten Daten auch zu Messungs- und Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen, dass diese Berichte Sie nicht persönlich identifizieren.</p>
                        <h3 >Welche Daten werden von Instagram gespeichert?</h3>
                        <p>Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser automatisch mit den Servern von Instagram in Verbindung. Dabei werden Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar unabhängig, ob Sie ein Instagram-Konto haben oder nicht. Dazu zählen Informationen über unserer Webseite, über Ihren Computer, über getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto haben bzw. eingeloggt sind, speichert Instagram deutlich mehr Daten über Sie.</p>
                        <p>Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Diese Kundendaten werden erst an Instagram übermittelt werden, wenn Sie zuvor „gehasht“ wurden. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen hat, abgeglichen.</p>
                        <p>Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten an Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto haben, werden unterschiedlich viele Daten gespeichert.</p>
                        <p>Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein Instagram-Konto haben oder <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.instagram.com/?tid=112667801" target="_blank" rel="noopener noreferrer">www.instagram.com</a> besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram, sobald Sie mit einer Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.</p>
                        <p>Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus, dass Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt sind, werden natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.</p>
                        <p>Diese Cookies wurden bei unserem Test verwendet:</p>
                        <p>
                            <strong >Name: </strong>csrftoken<br />
                            <strong >Wert: </strong>&#8220;&#8221;<br />
                            <strong >Verwendungszweck: </strong>Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung bringen.<br />
                            <strong >Ablaufdatum:</strong> nach einem Jahr</p>
                        <p>
                            <strong >Name: </strong>mid<br />
                            <strong >Wert: </strong>&#8220;&#8221;<br />
                            <strong >Verwendungszweck: </strong>Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt eine eindeutige User-ID fest.<br />
                            <strong >Ablaufdatum:</strong> nach Ende der Sitzung</p>
                        <p>
                            <strong >Name:</strong> fbsr_112667801124024<br />
                            <strong >Wert: </strong>keine Angaben<br />
                            <strong >Verwendungszweck: </strong>Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App.<strong >
                                <br />
                            </strong>
                            <strong >Ablaufdatum:</strong> nach Ende der Sitzung</p>
                        <p>
                            <strong >Name:</strong> rur<br />
                            <strong >Wert: </strong>ATN<br />
                            <strong >Verwendungszweck: </strong>Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität auf Instagram gewährleistet.<br />
                            <strong >Ablaufdatum:</strong> nach Ende der Sitzung</p>
                        <p>
                            <strong >Name:</strong> urlgen<br />
                            <strong >Wert: </strong>&#8220;&#8221;194.96.75.33&#8221;: 1901:1iEtYv:Y833k2_UjKvXgYe112667801&#8221;<br />
                            <strong >Verwendungszweck: </strong>Dieses Cookie dient den Marketingzwecken von Instagram.<br />
                            <strong >Ablaufdatum:</strong> nach Ende der Sitzung</p>
                        <p>
                            <strong >Anmerkung:</strong> Wir können hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im individuellen Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer Verwendung von Instagram ab.</p>
                        <h3 >Wie lange und wo werden die Daten gespeichert?</h3>
                        <p>Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.</p>
                        <h3 >Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
                        <p>Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre Daten auf Instagram völlig löschen wollen, müssen Sie Ihr Instagram-Konto dauerhaft löschen.</p>
                        <p>Und so funktioniert die Löschung des Instagram-Kontos:</p>
                        <p>Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie nach unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf die Webseite des Unternehmens. Klicken Sie auf der Webseite auf „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.</p>
                        <p>Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie beispielsweise Ihre Fotos und Status-Updates. Informationen, die andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto und werden folglich nicht gelöscht.</p>
                        <p>Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster Linie über Cookies. Diese Cookies können Sie in Ihrem Browser verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser funktioniert die Verwaltung immer ein bisschen anders. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
                        <p>Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder nicht.</p>
                        <h3 >Rechtsgrundlage</h3>
                        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong >(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong >(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
                        <p>Instagram verarbeitet Daten von Ihnen u.a. auch in den USA. Instagram bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="follow noopener"> https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
                        <p>Zudem verwendet Instagram sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Instagram, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
                        </p>
                        <p>Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch Instagram näherzubringen. Auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://privacycenter.instagram.com/policy/" target="_blank" rel="follow noopener">https://privacycenter.instagram.com/policy/</a> können Sie sich noch näher mit den Datenrichtlinien von Instagram auseinandersetzen.</p>
                        <h2 id="webdesign-einleitung" >Webdesign Einleitung</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Webdesign Datenschutzerklärung Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Besucher der Website<br />
                                        &#x1f91d; Zweck: Verbesserung der Nutzererfahrung<br />
                                        &#x1f4d3; Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den verwendeten Diensten ab. Meist handelt es sich etwa um IP-Adresse, technische Daten, Spracheinstellungen,  Browserversion, Bildschirmauflösung und Name des Browsers. Mehr Details dazu finden Sie bei den jeweils eingesetzten Webdesign-Tools.<br />
                                        &#x1f4c5; Speicherdauer: abhängig von den eingesetzten Tools<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 >Was ist Webdesign?</h3>
                        <p>Wir verwenden auf unserer Website verschiedene Tools, die unserem Webdesign dienen. Bei Webdesign geht es nicht, wie oft angenommen, nur darum, dass unsere Website hübsch aussieht, sondern auch um Funktionalität und Leistung. Aber natürlich ist die passende Optik einer Website auch eines der großen Ziele professionellen Webdesigns. Webdesign ist ein Teilbereich des Mediendesigns und beschäftigt sich sowohl mit der visuellen als auch der strukturellen und funktionalen Gestaltung einer Website. Ziel ist es mit Hilfe von Webdesign Ihre Erfahrung auf unserer Website zu verbessern. Im Webdesign-Jargon spricht man in diesem Zusammenhang von User-Experience (UX) und Usability. Unter User Experience versteht man alle Eindrücke und Erlebnisse, die der Websitebesucher auf einer Website erfährt. Ein Unterpunkt der User Experience ist die Usability. Dabei geht es um die Nutzerfreundlichkeit einer Website. Wert gelegt wird hier vor allem darauf, dass Inhalte, Unterseiten oder Produkte klar strukturiert sind und Sie leicht und schnell finden, wonach Sie suchen. Um Ihnen die bestmögliche Erfahrung auf unserer Website zu bieten, verwenden wir auch sogenannte Webdesign-Tools von Drittanbietern. Unter die Kategorie „Webdesign“ fallen in dieser Datenschutzerklärung also alle Dienste, die unsere Website gestalterisch verbessern. Das können beispielsweise Schriftarten, diverse Plugins oder andere eingebundene Webdesign-Funktionen sein.</p>
                        <h3 >Warum verwenden wir Webdesign-Tools?</h3>
                        <p>Wie Sie Informationen auf einer Website aufnehmen, hängt sehr stark von der Struktur, der Funktionalität und der visuellen Wahrnehmung der Website ab. Daher wurde auch für uns ein gutes und professionelles Webdesign immer wichtiger. Wir arbeiten ständig an der Verbesserung unserer Website und sehen dies auch als erweiterte Dienstleistung für Sie als Websitebesucher. Weiters hat eine schöne und funktionierende Website auch wirtschaftliche Vorteile für uns. Schließlich werden Sie uns nur besuchen und unsere Angebote in Anspruch nehmen, wenn Sie sich rundum wohl fühlen.</p>
                        <h3 >Welche Daten werden durch Webdesign-Tools gespeichert?</h3>
                        <p>Wenn Sie unsere Website besuchen, können Webdesign-Elemente in unseren Seiten eingebunden sein, die auch Daten verarbeiten können. Um welche Daten es sich genau handelt, hängt natürlich stark von den verwendeten Tools ab. Weiter unter sehen Sie genau, welche Tools wir für unsere Website verwenden. Wir empfehlen Ihnen für nähere Informationen über die Datenverarbeitung auch die jeweilige Datenschutzerklärung der verwendeten Tools durchzulesen. Meistens erfahren Sie dort, welche Daten verarbeitet werden, ob Cookies eingesetzt werden und wie lange die Daten aufbewahrt werden. Durch Schriftarten wie etwa Google Fonts werden beispielsweise auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen.</p>
                        <h3 >Dauer der Datenverarbeitung</h3>
                        <p>Wie lange Daten verarbeitet werden, ist sehr individuell und hängt von den eingesetzten Webdesign-Elementen ab. Wenn Cookies beispielsweise zum Einsatz kommen, kann die Aufbewahrungsdauer nur eine Minute, aber auch ein paar Jahre dauern. Machen Sie sich diesbezüglich bitte schlau. Dazu empfehlen wir Ihnen einerseits unseren allgemeinen Textabschnitt über Cookies sowie die Datenschutzerklärungen der eingesetzten Tools. Dort erfahren Sie in der Regel, welche Cookies genau eingesetzt werden, und welche Informationen darin gespeichert werden. Google-Font-Dateien werden zum Beispiel ein Jahr gespeichert. Damit soll die Ladezeit einer Website verbessert werden. Grundsätzlich werden Daten immer nur so lange aufbewahrt, wie es für die Bereitstellung des Dienstes nötig ist. Bei gesetzlichen Vorschreibungen können Daten auch länger gespeichert werden.</p>
                        <h3 >Widerspruchsrecht</h3>
                        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Sie können auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Unter Webdesign-Elementen (meistens bei Schriftarten) gibt es allerdings auch Daten, die nicht ganz so einfach gelöscht werden können. Das ist dann der Fall, wenn Daten direkt bei einem Seitenaufruf automatisch erhoben und an einen Drittanbieter (wie z. B. Google) übermittelt werden. Wenden Sie sich dann bitte an den Support des entsprechenden Anbieters. Im Fall von Google erreichen Sie den Support unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://support.google.com/?hl=de">https://support.google.com/?hl=de</a>.</p>
                        <h3 >Rechtsgrundlage</h3>
                        <p>Wenn Sie eingewilligt haben, dass Webdesign-Tools eingesetzt werden dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Webdesign-Tools vorkommen kann, dar. Von unserer Seite besteht zudem ein berechtigtes Interesse, das Webdesign auf unserer Website zu verbessern. Schließlich können wir Ihnen nur dann ein schönes und professionelles Webangebot liefern. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen Webdesign-Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Das wollen wir hier auf jeden Fall nochmals betonen.</p>
                        <p>Informationen zu speziellen Webdesign-Tools erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
                        <h2 id="google-fonts-lokal-datenschutzerklaerung" >Google Fonts Lokal Datenschutzerklärung</h2>
                        <p>Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver &#8211; nicht auf den Servern von Google &#8211; eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und somit auch keine Datenübertragung oder Speicherung.</p>
                        <h3 >Was sind Google Fonts?</h3>
                        <p>Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis mit über 800 Schriftarten, die <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://de.wikipedia.org/wiki/Google_LLC?tid=112667801">Google</a> kostenlos bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede Informationsübertragung zu Google-Servern zu unterbinden, haben wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine Daten an Google Fonts weiter.</p>
                        <h2 id="online-kartendienste-einleitung" >Online-Kartendienste Einleitung</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Online-Kartendienste Datenschutzerklärung Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Besucher der Website<br />
                                        &#x1f91d; Zweck: Verbesserung der Nutzererfahrung<br />
                                        &#x1f4d3; Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den verwendeten Diensten ab. Meist handelt es sich um IP-Adresse, Standortdaten, Suchgegenstände und/oder technische Daten. Mehr Details dazu finden Sie bei den jeweils eingesetzten Tools.<br />
                                        &#x1f4c5; Speicherdauer: abhängig von den eingesetzten Tools<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 >Was sind Online-Kartendienste?</h3>
                        <p>Wir nutzen für unsere Website als erweitertes Service auch Onlinekarten-Dienste. Google Maps ist wohl jener Dienst, der Ihnen am meisten bekannt ist, aber es gibt auch noch andere Anbieter, die sich auf das Erstellen digitaler Landkarten spezialisiert haben. Solche Dienste ermöglichen es, Standorte, Routenpläne oder andere geografische Informationen direkt über unsere Website anzeigen zu lassen. Durch einen eingebundenen Kartendienst müssen Sie unsere Website nicht mehr verlassen, um zum Beispiel die Route zu einem Standort anzusehen. Damit die Onlinekarte in unserer Website auch funktioniert werden mittels HTML-Code Kartenausschnitte eingebunden. Die Dienste können dann Straßenkarten, die Erdoberfläche oder Luft- bzw. Satellitenbilder anzeigen. Wenn Sie das eingebaute Kartenangebot nutzen, werden auch Daten an das verwendete Tool übertragen und dort gespeichert. Unter diesen Daten können sich auch personenbezogene Daten befinden.</p>
                        <h3 >Warum verwenden wir Online-Kartendienste auf unserer Website?</h3>
                        <p>Ganz allgemein gesprochen ist es unser Anliegen, Ihnen auf unserer Website eine angenehme Zeit zu bieten. Und angenehm ist Ihre Zeit natürlich nur, wenn Sie sich auf unserer Website leicht zurechtfinden und alle Informationen, die Sie brauchen schnell und einfach finden. Daher haben wir uns gedacht, ein Online-Kartensystem könnte noch eine deutliche Optimierung unseres Service auf der Website sein. Ohne unsere Website zu verlassen, können Sie sich mit Hilfe des Kartensystems Routenbeschreibungen, Standorte oder auch Sehenswürdigkeiten problemlos ansehen. Superpraktisch ist natürlich auch, dass Sie so auf einen Blick sehen, wo wir unseren Firmensitz haben, damit Sie schnell und sicher zu uns finden. Sie sehen, es gibt einfach viele Vorteile und wir betrachten Online-Kartendienste auf unserer Website ganz klar als Teil unseres Kundenservice.</p>
                        <h3 >Welche Daten werden von Online-Kartendiensten gespeichert?</h3>
                        <p>Wenn Sie eine Seite auf unserer Website öffnen, die eine Online-Kartenfunktion eingebaut hat, können personenbezogene Daten an den jeweiligen Dienst übermittelt und dort gespeichert werden. Meistens handelt es sich dabei um Ihre IP-Adresse, durch die auch Ihr ungefährer Standpunkt ermittelt werden kann. Neben der IP-Adresse werden auch Daten wie eingegebene Suchbegriffe sowie Längen- und Breitenkoordinaten gespeichert. Wenn Sie etwa eine Adresse für eine Routenplanung eingeben, werden auch diese Daten gespeichert. Die Daten werden nicht bei uns, sondern auf den Servern der eingebundenen Tools gespeichert. Sie können sich das ungefähr so vorstellen: Sie befinden sich zwar auf unserer Website, jedoch wenn Sie mit einem Kartendienst interagieren, passiert diese Interaktion eigentlich auf deren Website. Damit der Dienst einwandfrei funktioniert, wird in der Regel auch mindestens ein Cookie in Ihrem Browser gesetzt. Google Maps nutzt beispielsweise auch Cookies, um ein Userverhalten aufzuzeichnen und somit den eigenen Dienst zu optimieren und personalisierte Werbung schalten zu können. Mehr über Cookies erfahren Sie in unserem Abschnitt „Cookies“.</p>
                        <h3 >Wie lange und wo werden die Daten gespeichert?</h3>
                        <p>Jeder Online-Kartendienst verarbeitet unterschiedliche Userdaten. Sofern uns weitere Informationen vorliegen, informieren wir Sie über die Dauer der Datenverarbeitung weiter unten in den entsprechenden Abschnitten zu den einzelnen Tools. Grundsätzlich werden personenbezogene Daten stets nur so lange aufbewahrt, wie es für die Dienstbereitstellung nötig ist. Google Maps beispielsweise speichert gewisse Daten für einen festgelegte Zeitraum, andere Daten müssen Sie wiederum selbst löschen. Bei Mapbox wird etwa die IP-Adresse für 30 Tage aufbewahrt und anschließend gelöscht. Sie sehen, jedes Tool speichert Daten unterschiedlich lange. Daher empfehlen wir Ihnen, die Datenschutzerklärungen der eingesetzten Tools genau anzusehen.</p>
                        <p>Die Anbieter verwenden auch Cookies, um Daten zu Ihrem Userverhalten mit dem Kartendienst zu speichern. Mehr allgemeine Informationen zu Cookies finden Sie in unserem Abschnitt „Cookies“, aber auch in den Datenschutztexten der einzelnen Anbieter erfahren Sie, welche Cookies zum Einsatz kommen können. Meistens handelt es sich dabei allerdings nur um eine beispielhafte Liste und ist nicht vollständig.</p>
                        <h3 >Widerspruchsrecht</h3>
                        <p>Sie haben immer die Möglichkeit und auch das Recht auf Ihre personenbezogenen Daten zuzugreifen und auch gegen die Nutzung und Verarbeitung Einspruch zu erheben. Sie können auch jederzeit Ihre Einwilligung, die Sie uns erteilt haben, widerrufen. In der Regel funktioniert das am einfachsten über das Cookie-Consent-Tool. Es gibt aber auch noch weitere Opt-Out-Tools, die Sie nutzen können. Mögliche Cookies, die von den verwendeten Anbietern gesetzt werden, können Sie mit wenigen Mausklicks auch selbst verwalten, löschen oder deaktivieren. Es kann dann allergings vorkommen, dass einige Funktionen des Dienstes nicht mehr wie gewohnt funktionieren. Wie Sie in Ihrem Browser die Cookies verwalten, hängt auch von Ihrem benutzten Browser ab. Im Abschnitt „Cookies“ finden Sie auch Links zu den Anleitungen der wichtigsten Browser.</p>
                        <h3 >Rechtsgrundlage</h3>
                        <p>Wenn Sie eingewilligt haben, dass ein Online-Kartendienst eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch einen Online-Kartendienst vorkommen kann, dar.</p>
                        <p>Wir haben zudem auch ein berechtigtes Interesse, einen Online-Kartendienst zu verwenden, um unser Service auf unserer Website zu optimieren. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen einen Online-Kartendienst allerdings immer nur dann ein, wenn Sie eine Einwilligung erteilt haben. Das wollen wir an dieser Stelle unbedingt nochmals festgehalten haben.</p>
                        <p>Informationen zu speziellen Online-Kartendiensten erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
                        <h2 id="google-maps-datenschutzerklaerung" >Google Maps Datenschutzerklärung</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong >Google Maps Datenschutzerklärung Zusammenfassung</strong>
                                        <br />
                                        &#x1f465; Betroffene: Besucher der Website<br />
                                        &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
                                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa eingegebene Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten.<br />
                                        Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                                        &#x1f4c5; Speicherdauer: abhängig von den gespeicherten Daten<br />
                                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 >Was ist Google Maps?</h3>
                        <p>Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google Maps können wir Ihnen Standorte besser zeigen und damit unser Service an Ihre Bedürfnisse anpassen. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert. Hier wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden können.</p>
                        <p>Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC, ein Tablet oder eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts mittels HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. Satellitenbild. Dank der Street View Bilder und den hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.</p>
                        <h3 >Warum verwenden wir Google Maps auf unserer Website?</h3>
                        <p>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer Webseite zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen Standorten liefern. Sie sehen auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres Kundenservice.</p>
                        <h3 >Welche Daten werden von Google Maps gespeichert?</h3>
                        <p>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite eingebunden haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.</p>
                        <p>Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:</p>
                        <p>
                            <strong >Name:</strong> NID<br />
                            <strong >Wert:</strong> 188=h26c1Ktha7fCQTx8rXgLyATyITJ112667801-5<br />
                            <strong >Verwendungszweck:</strong> NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre persönlichen Einstellungen für Werbezwecke zu sammeln.<br />
                            <strong >Ablaufdatum:</strong> nach 6 Monaten</p>
                        <p>
                            <strong >Anmerkung:</strong> Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen nie auszuschließen. Um das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden war.</p>
                        <h3 >Wie lange und wo werden die Daten gespeichert?</h3>
                        <p>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.google.com/about/datacenters/locations/?hl=de" target="_blank" rel="follow noopener">https://www.google.com/about/datacenters/locations/?hl=de</a>
                        </p>
                        <p>Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server lahm legt, bleiben die Daten ziemlich sicher trotzdem geschützt.</p>
                        <p>Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel Werbedaten) in Serverprotokollen, indem es einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten löscht.</p>
                        <h3 >Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
                        <p>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur Standortbestimmung und Web-/App-Aktivität &#8211; abhängig von Ihrer Entscheidung &#8211; entweder 3 oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- oder ausschalten.</p>
                        <p>In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies immer etwas anders. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
                        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
                        <h3 >Rechtsgrundlage</h3>
                        <p>Wenn Sie eingewilligt haben, dass Google Maps eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong > Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google Maps vorkommen kann, dar.</p>
                        <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Maps zu verwenden, um unser Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Maps gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
                        <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie auf <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="follow noopener"> https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
                        <p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
                        </p>
                        <p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
                        <p>Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des Unternehmens unter <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
                        <h2 id="erklaerung-verwendeter-begriffe" >Erklärung verwendeter Begriffe</h2>
                        <p>Wir sind stets bemüht unsere Datenschutzerklärung so klar und verständlich wie möglich zu verfassen. Besonders bei technischen und rechtlichen Themen ist das allerdings nicht immer ganz einfach. Es macht oft Sinn juristische Begriffe (wie z. B. personenbezogene Daten) oder bestimmte technische Ausdrücke (wie z. B. Cookies, IP-Adresse) zu verwenden. Wir möchte diese aber nicht ohne Erklärung verwenden. Nachfolgend finden Sie nun eine alphabetische Liste von wichtigen verwendeten Begriffen, auf die wir in der bisherigen Datenschutzerklärung vielleicht noch nicht ausreichend eingegangen sind. Falls diese Begriffe der DSGVO entnommen wurden und es sich um Begriffsbestimmungen handelt, werden wir hier auch die DSGVO-Texte anführen und gegebenenfalls noch eigene Erläuterungen hinzufügen.</p>
                        <h2 id="auftragsverarbeiter" >Auftragsverarbeiter</h2>
                        <p>
                            <strong >Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
                        </p>
                        <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
                        <blockquote>
                            <p>
                                <em>
                                    <strong >„Auftragsverarbeiter“</strong> eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet;</em>
                            </p>
                        </blockquote>
                        <p>
                            <strong >Erläuterung:</strong> Wir sind als Unternehmen und Websiteinhaber für alle Daten, die wir von Ihnen verarbeiten verantwortlich. Neben den Verantwortlichen kann es auch sogenannte Auftragsverarbeiter geben. Dazu zählt jedes Unternehmen bzw. jede Person, die in unserem Auftrag personenbezogene Daten verarbeitet. Auftragsverarbeiter können folglich, neben Dienstleistern wie Steuerberater, etwa auch Hosting- oder Cloudanbieter, Bezahlungs- oder Newsletter-Anbieter oder große Unternehmen wie beispielsweise Google oder Microsoft sein.</p>
                        <h2 id="einwilligung" >Einwilligung</h2>
                        <p>
                            <strong >Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
                        </p>
                        <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
                        <blockquote>
                            <p>
                                <em>
                                    <strong >„Einwilligung“</strong> der betroffenen Person jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist;</em>
                            </p>
                        </blockquote>
                        <p>
                            <strong >Erläuterung: </strong>In der Regel erfolgt bei Websites eine solche Einwilligung über ein Cookie-Consent-Tool. Sie kennen das bestimmt. Immer wenn Sie erstmals eine Website besuchen, werden Sie meist über einen Banner gefragt, ob Sie der Datenverarbeitung zustimmen bzw. einwilligen. Meist können Sie auch individuelle Einstellungen treffen und so selbst entscheiden, welche Datenverarbeitung Sie erlauben und welche nicht. Wenn Sie nicht einwilligen, dürfen auch keine personenbezogene Daten von Ihnen verarbeitet werden. Grundsätzlich kann eine Einwilligung natürlich auch schriftlich, also nicht über ein Tool, erfolgen.</p>
                        <h2 id="personenbezogene-daten" >Personenbezogene Daten</h2>
                        <p>
                            <strong >Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
                        </p>
                        <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
                        <blockquote>
                            <p>
                                <strong >
                                    <em>„personenbezogene Daten“</em>
                                </strong>
                                <em> alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann;</em>
                            </p>
                        </blockquote>
                        <p>
                            <strong >Erläuterung:</strong> Personenbezogene Daten sind also all jene Daten, die Sie als Person identifizieren können. Das sind in der Regel Daten wie etwa:</p>
                        <ul >
                            <li >Name</li>
                            <li >Adresse</li>
                            <li >E-Mail-Adresse</li>
                            <li >Post-Anschrift</li>
                            <li >Telefonnummer</li>
                            <li >Geburtsdatum</li>
                            <li >Kennnummern wie Sozialversicherungsnummer, Steueridentifikationsnummer, Personalausweisnummer oder Matrikelnummer</li>
                            <li >Bankdaten wie Kontonummer, Kreditinformationen, Kontostände uvm.</li>
                        </ul>
                        <p>Laut Europäischem Gerichtshof (EuGH) zählt auch Ihre <strong >IP-Adresse zu den personenbezogenen Daten</strong>. IT-Experten können anhand Ihrer IP-Adresse zumindest den ungefähren Standort Ihres Geräts und in weiterer Folge Sie als Anschlussinhabers feststellen. Daher benötigt auch das Speichern einer IP-Adresse eine Rechtsgrundlage im Sinne der DSGVO. Es gibt auch noch sogenannte <strong >„besondere Kategorien“</strong> der personenbezogenen Daten, die auch besonders schützenswert sind. Dazu zählen:</p>
                        <ul >
                            <li >rassische und ethnische Herkunft</li>
                            <li >politische Meinungen</li>
                            <li >religiöse bzw. weltanschauliche Überzeugungen</li>
                            <li >die Gewerkschaftszugehörigkeit</li>
                            <li >genetische Daten wie beispielsweise Daten, die aus Blut- oder Speichelproben entnommen werden</li>
                            <li >biometrische Daten (das sind Informationen zu psychischen, körperlichen oder verhaltenstypischen Merkmalen, die eine Person identifizieren können).<br />
                                Gesundheitsdaten</li>
                            <li >Daten zur sexuellen Orientierung oder zum Sexualleben</li>
                        </ul>
                        <h2 id="profiling" >Profiling</h2>
                        <p>
                            <strong >Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
                        </p>
                        <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
                        <blockquote>
                            <p>
                                <em>
                                    <strong >„Profiling“</strong> jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen;</em>
                            </p>
                        </blockquote>
                        <p>
                            <strong >Erläuterung:</strong> Beim Profiling werden verschiedene Informationen über eine Person zusammengetragen, um daraus mehr über diese Person zu erfahren. Im Webbereich wird Profiling häufig für Werbezwecke oder auch für Bonitätsprüfungen angewandt. Web- bzw. Werbeanalyseprogramme sammeln zum Beispiel Daten über Ihre Verhalten und Ihre Interessen auf einer Website. Daraus ergibt sich ein spezielles Userprofil, mit dessen Hilfe Werbung gezielt an eine Zielgruppe ausgespielt werden kann.</p>
                        <p>&nbsp;</p>
                        <h2 id="verantwortlicher" >Verantwortlicher</h2>
                        <p>
                            <strong >Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
                        </p>
                        <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
                        <blockquote>
                            <p>
                                <em>
                                    <strong >„Verantwortlicher“</strong> die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet; sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden;</em>
                            </p>
                        </blockquote>
                        <p>
                            <strong >Erläuterung:</strong> In unserem Fall sind wir für die Verarbeitung Ihrer personenbezogenen Daten verantwortlich und folglich der &#8220;Verantwortliche&#8221;. Wenn wir erhobene Daten zur Verarbeitung an andere Dienstleister weitergeben, sind diese &#8220;Auftragsverarbeiter&#8221;. Dafür muss ein &#8220;Auftragsverarbeitungsvertrag (AVV)&#8221; unterzeichnet werden.</p>
                        <p>&nbsp;</p>
                        <h2 id="verarbeitung" >Verarbeitung</h2>
                        <p>
                            <strong >Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
                        </p>
                        <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
                        <blockquote>
                            <p>
                                <strong >
                                    <em>„Verarbeitung“</em>
                                </strong>
                                <em> jeden mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung;</em>
                            </p>
                        </blockquote>
                        <p>
                            <strong >Anmerkung: </strong>Wenn wir in unserer Datenschutzerklärung von Verarbeitung sprechen, meinen wir damit jegliche Art von Datenverarbeitung. Dazu zählt, wie oben in der originalen DSGVO-Erklärung erwähnt, nicht nur das Erheben sondern auch das Speichern und Verarbeiten von Daten.</p>
                        <h2 id="schlusswort" >Schlusswort</h2>
                        <p>Herzlichen Glückwunsch! Wenn Sie diese Zeilen lesen, haben Sie sich wirklich durch unsere gesamte Datenschutzerklärung „gekämpft“ oder zumindest bis hier hin gescrollt. Wie Sie am Umfang unserer Datenschutzerklärung sehen, nehmen wir den Schutz Ihrer persönlichen Daten, alles andere als auf die leichte Schulter.<br />
                            Uns ist es wichtig, Sie nach bestem Wissen und Gewissen über die Verarbeitung personenbezogener Daten zu informieren. Dabei wollen wir Ihnen aber nicht nur mitteilen, welche Daten verarbeitet werden, sondern auch die Beweggründe für die Verwendung diverser Softwareprogramme näherbringen. In der Regel klingen Datenschutzerklärung sehr technisch und juristisch. Da die meisten von Ihnen aber keine Webentwickler oder Juristen sind, wollten wir auch sprachlich einen anderen Weg gehen und den Sachverhalt in einfacher und klarer Sprache erklären. Immer ist dies natürlich aufgrund der Thematik nicht möglich. Daher werden die wichtigsten Begriffe am Ende der Datenschutzerklärung näher erläutert.<br />
                            Bei Fragen zum Thema Datenschutz auf unserer Website zögern Sie bitte nicht, uns oder die verantwortliche Stelle zu kontaktieren. Wir wünschen Ihnen noch eine schöne Zeit und hoffen, Sie auf unserer Website bald wieder begrüßen zu dürfen.</p>
                        <p>Alle Texte sind urheberrechtlich geschützt.</p>
                        <p>Quelle: Erstellt mit dem <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.adsimple.at/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Österreich">Datenschutz Generator</a> von AdSimple</p>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default Datenschutz;