import WienSvg from './media/WienSvg.png';
import { connection } from "./constants/global.js"
import { useState } from "react";
import axios from "axios";

const Kontakt = () => {

    const [anfrageLoading, setAnfrageLoading] = useState<boolean>(false);
    const [firstName, setFirstName] = useState("");
    const [hiddenText, setHidden] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("0");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [contactSelection, setContactSelection] = useState("email");
    const [anfrageGeschickt, setAnfrageGeschickt] = useState(false);
    const [dsgvoZustimmung, setDsgvoZustimmung] = useState(false);
    const [captchaAnswer, setCaptchaAnswer] = useState("");
    const [formLoadedTime] = useState(Date.now());
    const [captchaChallenge] = useState(() => {
        const num1 = Math.floor(Math.random() * 10) + 1; // Random number between 1 and 10
        const num2 = Math.floor(Math.random() * 10) + 1; // Random number between 1 and 10
        const answer = num1 + num2; // Calculate the sum
        return { question: `Was ist ${num1} + ${num2}?`, answer: answer.toString() };
    });

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const submissionTime = Date.now();
        if (submissionTime - formLoadedTime < 5000) { // 10 Sekunden als Beispiel
          console.log("Formular zu schnell ausgefüllt, wahrscheinlich Spam.");
          return;
        }
      
        if (captchaAnswer === captchaChallenge.answer) {
            setAnfrageLoading(true);
            setAnfrageGeschickt(true);
            const formData = {
                firstName,
                lastName,
                phone,
                email,
                message,
            };
            try {
                //Honeypot Method to prevent bots
                if (hiddenText != '') {
                    throw new Error
                }
                // Make API request
                const response = await axios.post(connection + '/MacAndPhoneDoc/SendMailFromContactForm',
                    formData,
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                );
                // Handle response
                setAnfrageLoading(false);
            } catch (error) {
                console.log(error);
                setAnfrageLoading(false);
            }
        } else {
            // Handle incorrect CAPTCHA answer
            alert("Captcha Falsch!");
            setAnfrageLoading(false);
        }

    };

    return (
        <div className="newBG min-h-screen flex flex-col md:flex-row items-center justify-center text-gray-600" >
            <div className="flex flex-col xl:flex-row items-center justify-around w-[80vw] 2xl:w-[70vw]">
                <div className="flex flex-col my-10  md:mt-0 h-fit bg-slate-50 align-middle items-center justify-center flex-wrap w-[90vw] md:w-fit   p-8 gap-10 rounded-xl shadow-lg">
                    <div className="w-fit"><h1 className="font-bold text-3xl lg:text-4xl w-full ">Kontaktformular</h1></div>
                    {!anfrageLoading ? (anfrageGeschickt ? <>
                        <div className='bg-gray-100 p-5'>
                            <div className="bg-white p-6  md:mx-auto w-fit">
                                <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto my-6">
                                    <path fill="currentColor"
                                        d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                                    </path>
                                </svg>
                                <div className="text-center">
                                    <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Anfrage gesendet!</h3>
                                    <p className="text-gray-600 my-2">Vielen Dank, dass sie sich für uns entschieden haben.</p>
                                    <p> Wir wünschen Ihnen einen schönen Tag! </p>
                                    <div className="py-10 text-center">
                                        <a href="./" className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3">
                                            Zur Startseite
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> :
                        <div className="w-fit">

                            <form onSubmit={handleSubmit}>
                                <div className="grid gap-6 mb-6 md:grid-cols-2">
                                    <div>
                                        <label htmlFor="first_name" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Vorname (Pflichtfeld)</label>
                                        <input
                                            type="text"
                                            id="first_name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Max"
                                            required
                                        />
                                    </div>
                                    <div style={{ display: 'none' }}>
                                        <label htmlFor="hidden_honeypot" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Hidden</label>
                                        <input
                                            type="text"
                                            id="hidden_honeypot"
                                            value={hiddenText}
                                            onChange={(e) => setHidden(e.target.value)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder=""
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="last_name" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Nachname (Pflichtfeld)</label>
                                        <input
                                            type="text"
                                            id="last_name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Muster"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="contactSelection" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Wie möchten Sie Kontaktiert werden?  (Pflichtfeld)</label>
                                        <select
                                            id="contactSelection"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                            value={contactSelection}
                                            onChange={(e) => (setContactSelection(e.target.value), e.target.value === "email" && setPhone("0"))}
                                        >
                                            <option value="email">Email</option>
                                            <option value="phone">Telefon</option>
                                        </select>
                                    </div>
                                    {contactSelection === "phone" && (

                                        <div>
                                            <label htmlFor="phone" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Telefonnummer (Pflichtfeld)</label>
                                            <input
                                                type="tel"
                                                id="phone"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="0000 12 34 567"
                                                required
                                            />
                                        </div>
                                    )}

                                </div>
                                <div className="mb-6">
                                    <label htmlFor="email" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Email Addresse (Pflichtfeld)</label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="max.muster@muster.at"
                                        required
                                    />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="message" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Nachricht</label>
                                    <textarea
                                        id="Nachricht"
                                        rows={10}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        maxLength={500}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    ></textarea>
                                </div>
                                <div className="mb-6">
                                    <input
                                        type="checkbox"
                                        id="dsgvoZustimmung"
                                        value={dsgvoZustimmung.toString()}
                                        onChange={(e) => setDsgvoZustimmung(!dsgvoZustimmung)}
                                        className="mr-2"
                                        required
                                    />
                                    <a href="/Datenschutz" className='text-blue-600 underline underline-offset-2'>Hiermit stimme ich den Datenschutzerklärungen und den AGB zu.</a>
                                </div>
                                <div className="mb-6">
                                    <label>{captchaChallenge.question}</label>
                                    <input
                                        type="text"
                                        value={captchaAnswer}
                                        onChange={e => setCaptchaAnswer(e.target.value)}
                                        required
                                    />
                                </div>
                                {
                                    dsgvoZustimmung && <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                                }

                                <button disabled type="submit" className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${dsgvoZustimmung && "hidden"}`}>Submit</button>
                            </form>
                        </div>
                    ) : (<div className="w-full"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle fill="#2663A1" stroke="#2663A1" stroke-width="15" r="15" cx="40" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="#2663A1" stroke="#2663A1" stroke-width="15" r="15" cx="100" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="#2663A1" stroke="#2663A1" stroke-width="15" r="15" cx="160" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg></div>)}
                </div>

                <div className="flex flex-col md:flex-row my-10  md:mt-0 h-fit bg-slate-50 align-middle items-center flex-wrap w-[90vw] md:w-fit rounded-xl shadow-lg mb-auto">
                    <div className="w-fit font-semibold p-8 md:pt-9 md:p-16 ">
                        <h2 className="text-4xl mb-2">Unser Geschäft</h2>
                        <hr className="h-[2px] bg-gray-200 border-0 dark:bg-gray-700" />
                        <span className="text-md font-normal flex flex-col lg:flex-row mt-3">
                            MacAndPhoneDoc<br />
                            Amerlingstraße 19<br />
                            1060 Wien
                            <div className=" w-36 ml-16">
                                <a href="https://www.google.com/maps/place/Mac+and+Phone+Doc/@48.1977608,16.3504534,15z/data=!4m2!3m1!1s0x0:0x6af33b67a0081359?sa=X&ved=2ahUKEwj_5IO3wvf_AhV8gv0HHYbVC3gQ_BJ6BAg_EAA&ved=2ahUKEwj_5IO3wvf_AhV8gv0HHYbVC3gQ_BJ6BAhLEAg" target='_blank' rel='noreferrer' >
                                    <img src={WienSvg} alt="Wien" style={{ filter: "drop-shadow(rgba(190,190,190,0.5) 2px 2px 3px) drop-shadow(white -1px -1px 0px)", }} />
                                </a>
                            </div>
                        </span>
                        <h2 className="text-xl mt-3">Kontakt</h2>
                        <div className=" mb-3 flex flex-col">
                            <span className="text-md font-normal ">
                                +43 1 4103511
                            </span>
                            <span className="text-md font-normal mb-3">
                                office@macandphonedoc.at
                            </span>
                        </div>

                        <h2 className="text-xl ">Öffnungszeiten</h2>
                        <div className=" mb-3">
                            <span className="text-md font-normal mb-3">
                                Mo. – Fr.: 10:00 – 18:00 Uhr<br />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Kontakt;
