import React, { useState, useEffect } from 'react';

interface CookiePreferences {
    essential: boolean;
    analytics: boolean;
}
declare global {
    interface Window {
        dataLayer: any[];
    }
}
const CookieBanner: React.FC = () => {
    const [showBanner, setShowBanner] = useState(true);
    const [preferences, setPreferences] = useState<CookiePreferences>({
        essential: true,
        analytics: false,
    });
    const [showDetails, setShowDetails] = useState(false);



    useEffect(() => {
        const storedPreferences = getCookie('cookie-preferences');
        if (storedPreferences) {
            setPreferences(JSON.parse(storedPreferences));
            setShowBanner(false);
        }
    }, []);

    const setCookie = (name: string, value: string, days: number) => {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    };

    const getCookie = (name: string) => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop()?.split(";").shift();
    };

    const handleAccept = () => {
        setShowBanner(false);
        // Save preferences to local storage or cookies here
        setCookie('cookie-preferences', JSON.stringify(preferences), 365);
        if (preferences.analytics) {
            // Initialize dataLayer for Google Analytics
            window.dataLayer = window.dataLayer || [];

            // Load Google Analytics script
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=YOUR-GA-MEASUREMENT-ID`;
            document.head.appendChild(script);

            (script.onload = () => {
                const gtag: (...args: any[]) => void = function () {
                    window.dataLayer.push(arguments);
                };
                gtag('js', new Date());
                gtag('config', 'YOUR-GA-MEASUREMENT-ID');
            })();
        }
    };

    const handleReject = () => {
        setShowBanner(false);
        setCookie('cookie-preferences', JSON.stringify({ essential: true, analytics: false }), 365);
        // Deaktiviere alle Cookies außer den notwendigen
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setPreferences({ ...preferences, [name]: checked });
    };




    return showBanner ? (
        <div style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#f4f4f4', zIndex: 99999 }}>
            <div className="w-1/2 mx-auto p-4">
                <h2 className="text-lg font-semibold mb-2">Cookie-Einstellungen</h2>
                <button
                    onClick={() => setShowDetails(!showDetails)}
                    className="focus:outline-none text-blue-700 hover:underline mb-4"
                >
                    {showDetails ? 'Details ausblenden' : 'Details anzeigen'}
                </button>
                {showDetails && (
                    <div className="mb-4">
                        <h4 className="text-sm font-semibold mb-2">Verwendete Cookies:</h4>
                        <ul className="text-sm list-disc list-inside">
                            <li>Notwendige Cookies: Für grundlegende Funktionen der Website.</li>
                            <li>Analyse-Cookies: Für das Tracking und die Analyse des Benutzerverhaltens.</li>
                            <li>
                                Google Analytics Cookies:
                                <ul className="text-xs list-disc list-inside ml-5">
                                    <li>_ga: Identifiziert Benutzer. (Ablauf: 2 Jahre)</li>
                                    <li>_gid: Identifiziert Benutzer. (Ablauf: 24 Stunden)</li>
                                    <li>_gat: Drosselt Anforderungsrate. (Ablauf: 1 Minute)</li>
                                    <li>_gac_: Enthält kampagnenbezogene Informationen. (Ablauf variiert)</li>
                                    <li>AMP_TOKEN: Enthält ein Token für den AMP-Client-ID-Dienst. (Ablauf variiert)</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                )}
                <div className="flex items-center mb-2">
                    <input
                        type="checkbox"
                        name="essential"
                        checked={preferences.essential}
                        disabled
                        className="mr-2"
                    />
                    <label>Notwendige Cookies</label>
                </div>
                <div className="flex items-center mb-4">
                    <input
                        type="checkbox"
                        name="analytics"
                        checked={preferences.analytics}
                        onChange={handleChange}
                        className="mr-2"
                    />
                    <label>Analyse-Cookies (z.B. Google Search Console)</label>
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={handleAccept}
                        className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2"
                    >
                        Akzeptieren
                    </button>
                    <button
                        onClick={handleReject}
                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5"
                    >
                        Ablehnen
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default CookieBanner;