
const ReparaturBedingungen = () => {
    return (
        <div className='bg-primary p-10'>
            <div className='w-full mx-auto bg-white p-3 md:p-10 rounded-3xl'>
                <div className='w-full  lg:px-0 px-4 flex flex-col content-center flex-wrap min-h-screen gap-10 text-gray-500'>
                    <div className='w-full flex flex-col content-center flex-wrap items-center'>
                        <h1 className='text-xl xs:text-2xl md:text-5xl font-bold w-fit'>Reparaturbedingungen für Mac and Phone Doc</h1>

                        <div className="mt-6 ">
                            <div className="mb-7 text-justify ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>1. Annahme und Abholung von Reparaturen:</h2>
                                <p>Bei Annahme einer Reparatur erhält der Kunde eine schriftliche Bestätigung mit den zu reparierenden Geräten und Zubehärteilen. Unsere Haftung beschränkt sich auf die im Auftrag verzeichneten Geräte und schließt SIM-Karten, Speicherkarten sowie den Zustand von Akkus und Batterien aus. Wir haften nur in Fällen grober Fahrlässigkeit für Schäden an reparierten Geräten, maximal bis zum Zeitwert des jeweiligen Geräts. Defekte Teile, die im Rahmen der Reparatur ausgetauscht werden, gehen automatisch und kostenfrei in unseren Besitz über, sofern nicht anders bei der Reparaturannahme vereinbart.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>2. Kostenvoranschlag:</h2>
                                <p>Kostenvoranschläge werden gegen eine Gebühr von 20 Euro angeboten, die bei Auftragserteilung angerechnet wird.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>3. Reparaturkosten:</h2>
                                <p>Die Preise für Reparaturen sind auf unserer Homepage einsehbar.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>4. Garantie:</h2>
                                <p>Wir gewähren eine 6-monatige Garantie auf alle von uns durchgeführten Arbeiten und eingebauten Ersatzteile. Diese Garantie deckt alle Mängel ab, die nicht durch unsachgemäße Verwendung oder Fremdeinwirkung verursacht wurden.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>5. Zusätzliche Kosten:</h2>
                                <p>Es fallen keine weiteren Kosten für bekannte Probleme an.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>6. Nicht abgeholte Geräte:</h2>
                                <p>Geräte, die nach Abschluss der Reparatur nicht innerhalb von 4 Wochen abgeholt werden, unterliegen einer Lagergebühr von 10 Euro pro zusätzlicher Woche. Nach 8 Wochen ohne Abholung behalten wir uns vor, das Gerät anderweitig zu verwenden oder zu entsorgen.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>7. Zahlungsbedingungen:</h2>
                                <p>Die Zahlung erfolgt bei Abholung sofort und bar. Bei Versand besteht die Mäglichkeit der sofortigen Zahlung per Vorkasse oder Nachnahme. Wir setzen auf flexible Zahlungsoptionen, um eine bequeme Abwicklung für unsere Kunden zu gewährleisten.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>8. Datenschutz:</h2>
                                <p>Mac and Phone Doc respektiert die Privatsphäre der Kunden und verpflichtet sich, alle persänlichen Daten auf den Geräten vertraulich zu behandeln.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>9. Haftungsausschluss für Datenverlust:</h2>
                                <p>Mac and Phone Doc haftet nicht für Datenverlust während oder nach der Reparatur. Kunden werden gebeten, vorab eine Sicherung ihrer Daten durchzuführen.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>10. Garantie bei Wasserschäden:</h2>
                                <p>Wir bitten um Verständnis, dass im Allgemeinen bei Wasserschäden keine Garantie, Gewährleistung oder sonstige Forderungen geleistet werden kännen. Eine unaufhaltsame Korrosion kann durch unser Einwirken nicht vollständig behoben werden. Der Auftraggeber nimmt dies zur Kenntnis.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>11. Kündigung oder Änderung der Bedingungen:</h2>
                                <p>Mac and Phone Doc behält sich das Recht vor, diese Reparaturbedingungen zu ändern oder zu kündigen. Kunden werden über Änderungen informiert.</p>
                            </div>
                            <div className="mb-7 ">
                                <h2 className='font-bold text-lg sm:text-xl lg:text-2xl   '>12. Kundenservice:</h2>
                                <p>Bei Fragen oder Problemen stehen wir Ihnen gerne zur Verfügung. Kontaktieren Sie uns unter <a href="mailto:office@macandphonedoc.at">office@macandphonedoc.at</a> oder telefonisch unter 01 410 35 11.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default ReparaturBedingungen;