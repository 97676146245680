import { Routes, Route } from 'react-router-dom';
import Startseite from './Startseite';
import IPhoneReps  from './RepInfos';
import Kontakt  from './Kontakt';
import UeberUns from './UeberUns';
import Impressum from './Impressum';
import NeuerTermin from './NeuerTermin';
import DerReparaturbonus from './DerReparaturbonus';
import Transfer from './DatenTransfer';
import ReparaturBedingungen from './Reparaturbedingungen';
import FAQ from './FAQ';
import Datenschutz from './Datenschutz'

import './index.css'
const Main = () => {
return (         
    <Routes>
        <Route path='/' element={<Startseite/>} />
        <Route path='/ReparaturInfos' element={<IPhoneReps/>} />
        <Route path='/Kontakt' element={<Kontakt/>} />
        <Route path='/UeberUns' element={<UeberUns/>} />
        <Route path='/Impressum' element={<Impressum/>} />
        <Route path='/NeuerTermin' element={<NeuerTermin/>} />
        <Route path='/DerReparaturbonus' element={<DerReparaturbonus/>} />
        <Route path='/DatenTransfer' element={<Transfer/>} />
        <Route path='/Reparaturbedingungen' element={<ReparaturBedingungen/>} />
        <Route path='/FAQ' element={<FAQ/>} />
        <Route path='/Datenschutz' element={<Datenschutz/>} />
        
    </Routes>
);
}
export default Main;