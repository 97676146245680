import Banner from './media/Banner.webp';

function DerReparaturbonus() {
    return (
        <div className='flex flex-col'>
            <div className='w-full bg-primary'>
                <img src={Banner} alt="Banner" className='md:h-80 mx-auto aspect-auto' />
            </div>
            <div className='newBG min-h-screen '>
                <div className="w-11/12 lg:w-3/4 xl:w-1/2 mx-auto my-10 bg-slate-50 p-10 rounded-2xl shadow-xl text-gray-600">
                    <div className="w-fit mx-auto">
                        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-semibold">Österreichischer Reparaturbonus</h1>
                    </div>
                    <div className=" mt-10">
                        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">Allgemeine Informationen</h2>
                        <p className="text-md">
                            Der Reparaturbonus ist eine spezielle Förderungsaktion, die von der österreichischen Bundesregierung ins Leben gerufen wurde. Ziel dieser Initiative ist es, nachhaltigen Konsum zu fördern und die Menge an Elektroschrott zu reduzieren. Der Reparaturbonus wird vom Bundesministerium für Klimaschutz, Umwelt, Energie, Mobilität, Innovation und Technologie finanziert und ist ein wichtiger Teil des Österreichischen Aufbau- und Resilienzplans. Die Aktion wird durch die Europäische Union unter dem Programm NextGenerationEU unterstützt.
                        </p>
                    </div>
                    <div className="mt-4">
                        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">Förderberechtigte Personen</h2>
                        <p className="text-md">
                            Die Förderung richtet sich ausschließlich an Privatpersonen, die einen Wohnsitz in Österreich haben. Das bedeutet, dass Unternehmen und Organisationen nicht förderberechtigt sind. Pro elektronisches oder elektrisches Gerät kann nur ein Bon beantragt werden. Dieser Bon kann für die Reparatur oder für den Kostenvoranschlag eines Geräts genutzt werden.
                        </p>
                    </div>
                    <div className="mt-4">
                        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">Förderfähige und nicht-förderfähige Geräte</h2>
                        <p className="text-md">
                            Die Förderung betrifft vor allem die Reparatur von Elektro- und Elektronikgeräten, die üblicherweise in privaten Haushalten vorkommen. Das beinhaltet Geräte wie Küchenmaschinen, Waschmaschinen, Smartphones und viele andere. Es gibt allerdings auch Ausnahmen: Fahrzeuge und Geräte, die nicht erneuerbare Energiequellen nutzen, sind von der Förderung ausgeschlossen.
                        </p>
                    </div>
                    <div className="mt-4">
                        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">Wie hoch ist die Förderung?</h2>
                        <p className="text-md">
                            Die Höhe des Reparaturbonus ist variabel und richtet sich nach den anfallenden Kosten der Reparatur oder des Kostenvoranschlags. Im Allgemeinen deckt der Bonus bis zu 50% der Bruttokosten ab. Dabei sind bis zu 200 Euro für die Reparatur und bis zu 30 Euro für die Einholung eines Kostenvoranschlags möglich.
                        </p>
                    </div>
                    <div className="mt-4">
                        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">Antragsverfahren und Auszahlung</h2>
                        <p className="text-md">
                            Der Antragsprozess ist bewusst einfach gehalten und erfolgt online über die offizielle Website <a href="https://www.reparaturbonus.at" className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' target='blank'>www.reparaturbonus.at</a>. Nach der Beantragung wird der Bon per E-Mail verschickt und kann entweder ausgedruckt oder digital beim Partnerbetrieb vorgezeigt werden. Die Auszahlung der Förderung erfolgt direkt auf das Bankkonto des Antragstellers, nachdem der Partnerbetrieb die Rechnung bei der Kommunalkredit Public Consulting eingereicht hat.
                        </p>
                    </div>
                    <div className="mt-4">
                        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">Weitere Informationen und Kontakte</h2>
                        <p className="text-md">
                            Für weitere Fragen und Informationen steht die offizielle Website zur Verfügung. Dort finden Sie auch ein Kontaktformular für individuelle Anfragen. Darüber hinaus gibt es eine Liste der Partnerbetriebe, bei denen der Reparaturbonus eingelöst werden kann.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DerReparaturbonus;
