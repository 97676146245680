import React from 'react';
import './vanilla-pattern-lock.css';
import PatternLock from 'vanilla-pattern-lock'
type LockPatternProps = {
    onPatternChange: (pattern: string) => void;
  }
  const LockPattern: React.FC<LockPatternProps> = ({ onPatternChange }) => {
    React.useEffect(() => {
        const lock = new PatternLock({ vibrate: true });
        const container = document.getElementById("LockContainer");
        if (container instanceof HTMLElement) {
          lock.render(container)
            .on("complete", (pattern: string) => {
              onPatternChange(pattern); // call the onPatternChange prop function to update the parent state
            });
        }
      }, [onPatternChange]);

    return (
        <div style={{width: "250px", height: "250px" }}>
            <div id="LockContainer"></div>
        </div>
    );
};
export default LockPattern;
