import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);
// const body = ReactDOM.createRoot(
//   document.getElementsByTagName('body')[0] as HTMLElement
// );
// body.render(
//   <React.StrictMode >
//     <Navbar />
//     <Startseite />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
