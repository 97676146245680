import axios from "axios";
import { id } from "date-fns/locale";
import { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { connection } from "./constants/global";
import React from "react";
import { set } from "date-fns";
import { CircularProgress } from "@mui/material";

interface AppointmentInfo {
    name: string;
    date: Date;
    time: string;
    oldDate: Date;
    oldTime: string;
    description: string;
}

interface TerminInfo {
    id: string;
}


const NeuerTermin = () => {
    const [name] = useState("Max Mustermann");
    const [searchParams] = useSearchParams();
    const [accepted, setAccepted] = useState(false);
    const [declined, setDeclined] = useState(false);
    const [appointmentInfo, setAppointmentInfo] = useState<AppointmentInfo>({
        name: "",
        date: new Date(),
        oldDate: new Date(),
        oldTime: "",
        time: "",
        description: "",
    });
    const [infoLoading, setLoading] = useState(true);
    const [terminInfo, setTerminInfo] = useState<TerminInfo>({
        id: "abc",
    });

    const handleAccept = () => {
        let id = searchParams.get("id") as string;
        fetch(connection + '/MacAndPhoneDoc/AppointmentAccepted?id='+id);
        setAccepted(true);
    };

    const handleDecline = () => {
        let id = searchParams.get("id") as string;
        fetch(connection + '/MacAndPhoneDoc/AppointmentDeclined?id='+id);
        setDeclined(true);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setAppointmentInfo(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const apiCall = async () => {
        let id = searchParams.get("id") as string;
        id = id.replace(/ /g, '+');
        console.log(id);
        setTerminInfo({ id: id });
        let formData: any = {};
        const url = `${connection}/MacAndPhoneDoc/TerminAnzeigen`;
        formData = { id: id };
        const response = axios.post(url, formData, {
            headers: { 'Content-Type': 'application/json' },
        }).then((response) => {
            if(response.data.name === null)
            {
                window.location.href = "/";
                return;
            }
            let time = response.data.date.split("T")[1];
            let oldTime = response.data.oldDate.split("T")[1];
            setAppointmentInfo({
                date: new Date(response.data.date.split("T")[0]),
                name: response.data.name,
                time: time,
                oldDate: new Date(response.data.oldDate.split("T")[0]),
                oldTime: oldTime,
                description: response.data.description,
            });
            setLoading(false);
        });
    }

    React.useEffect(() => {
        apiCall();
    }, []);

    React.useEffect(() => {

        console.log(appointmentInfo);
    }
        , [appointmentInfo]);

    return (
        <>
        {infoLoading && <div className="h-screen flex items-center justify-center"><CircularProgress /></div>}
            {accepted && <p>Appointment accepted!</p>}
            {declined && <p>Appointment declined.</p>}
            {!accepted && !declined && !infoLoading && (
                <div className="h-screen flex items-center justify-center">
                    <div className="h-fit w-full md:w-1/2 flex items-center justify-center flex-col p-10 rounded-3xl drop-shadow-2xl  bg-secondary">
                        <h1 className="text-5xl font-bold drop-shadow-sm">Hallo {appointmentInfo.name}</h1>
                        <span>Leider können wir den Termin am {appointmentInfo.oldDate.toLocaleDateString()} um {appointmentInfo.oldTime} nicht einhalten und schlagen daher folgenden Termin vor:</span>
                        <form className="grid gap-6 mb-6 md:grid-cols-2 w-full drop-shadow-sm mt-3">

                            <div>
                                <label htmlFor="first_name" className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white ">Datum</label>
                                <span id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  >{appointmentInfo.date.toLocaleDateString()}</span>
                            </div>
                            <div>
                                <label htmlFor="last_name" className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white">Uhrzeit</label>
                                <span id="last_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  >{appointmentInfo.time}</span>
                            </div>
                            <div>
                                <label htmlFor="schadensbeschreibung" className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white">Beschreibung</label>
                                <span id="schadensbeschreibung" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" >{appointmentInfo.description}</span>
                            </div>
                            <div className="flex flex-col gap-2 col-span-2 items-center drop-shadow-xl">
                                <button onClick={handleAccept} className="bg-lime-600 p-5 w-full md:w-1/2 text-2xl font-semibold rounded-2xl text-white drop-shadow-3xl ">Akzeptieren</button>
                                <button onClick={handleDecline} className="bg-red-600 p-3 w-full md:w-1/2 text-2xl font-semibold rounded-2xl text-white">Ablehnen</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}
export default NeuerTermin;



