
const Impressum = () => {
    return (
        <div className="h-screen bg-primary flex items-center justify-center text-black">
            <div className="w-[50rem] h-fit bg-secondary p-10 rounded-lg shadow-2xl">
                <h1 className="text-4xl font-bold mb-4">Impressum</h1>
                <div className=" flex justify-around flex-col">
                    <p><b>Name / Firma:</b> Hasan Buga</p>
                    <p><b>Anschrift:</b> Amerlingstraße 19, 1060 Wien, Österreich</p>
                    <p><b>Inhaber:</b> Herr Hasan Buga</p>
                    <p><b>Telefonnummer:</b> +43 699 152 67 416</p>
                    <p><b>Email:</b> office@macandphonedoc.at</p>
                    <p><b>UID Nummer:</b> ATU74618416</p>
                    <p><b>Gerichtsstand:</b> Handelsgericht Wien</p>
                    <a href={"https://firmen.wko.at/hasan-buga/wien/?firmaid=4e333f68-47bf-4bc7-9058-11f271a979c2&name=hasan+buga&standort=wien+%28bundesland%29"} className="text-teal-600"><b>WKO – Wirtschaftskammer Österreich</b></a>
                    <p><b>Bitte beachten Sie auch unsere <a href="/#" className="cursor-pointer text-teal-600">Datenschutzerklärung</a>.</b></p>
                </div>
            </div>
        </div>
    );
}
export default Impressum;