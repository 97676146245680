
import React, { useState, useRef, useEffect } from 'react';
import Banner from './media/Banner.jpg';
import Zusenden from './media/EinsendenNeu.png';
import VorOrt from './media/VorOrtNeu.png';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
} from "./components/ui/select"
import './styles/App.css'
import './styles/RepInfos.css'
import { useSearchParams } from 'react-router-dom';
import iPhone from "./media/iPhone11.png"
import magnifying from "./media/magnifying-glass.svg"
import notfall from "./media/Notfall_Hotline.svg"
import garantie from "./media/6_Monate_Garantie.png"
import { connection } from "./constants/global.js"
import checkBoxSvg from "./media/checkbox.svg"
import { Modal } from 'flowbite'
import type { ModalOptions, ModalInterface } from 'flowbite'
import { Accordion } from "flowbite";
import type { AccordionOptions, AccordionItem, AccordionInterface } from "flowbite";
import LockPattern from './komponenten/LockPattern';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import axios from 'axios';
import plane from './media/EmailBild.png';
import { set } from 'date-fns';

registerLocale('de', de);
interface modellGroup {
    markenName: string;
    modellName: string;
    unterkategorieName: string;
}

interface ReparaturInfo {
    preis: number;
    aktionsPreis: number;
    reparaturName: string;
    iconBase64: string;
    dauer: string;
    repBeschreibung: string;
}

interface TimeSlot {
    id: number;
    slotDate: string;
}
enum kategorieZuordnung {
    iPhone = 1,
    iPad = 2,
    Smartphone = 3,
    Mac = 4,
    iWatch = 5

}

enum step {
    Modell = 1,
    Reparatur = 2,
    Ort = 3,
    Abschluss = 4,
    Mac = 5
}

const RepInfos = () => {
    const now = new Date(); // current date and time
    const today = now.getHours() >= 17 ? new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1) : new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const [startDate, setStartDate] = useState<Date | null>(now.getHours() >= 17 ? new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1) : new Date(now.getFullYear(), now.getMonth(), now.getDate())); // initialize to current date and time
    const isToday = startDate && startDate.toDateString() === now.toDateString();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0); // 18:00 on current day
    const nextYear = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()); // date one year from today
    const openingTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0); // 10:00 on current day
    const closingTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0); // 18:00 on current day

    const patternRef = useRef<HTMLDivElement>(null); // specify type as HTMLDivElement
    const prevDateRef = useRef<Date | null>(null);

    const [searchParams] = useSearchParams();
    const [sources, setSources] = React.useState<modellGroup[]>([]);
    const [repSources, setRepSources] = React.useState<modellGroup[]>([]);
    const kategorie = searchParams.get("kategorie");

    const [sourcesLoading, setSourcesLoading] = useState<boolean>(true);

    const [stepCounter, setStep] = useState<number>(1);

    const [marke, setMarke] = React.useState([]);
    const [markeSelection, setMarkeSelection] = React.useState('');
    const [modell, setModell] = useState<string>("");
    const [serie, setSerie] = useState<string>("");

    const [markeSelectionChange, setMarkeSelectionChange] = React.useState('');
    const [modellChange, setModellChange] = useState<string>("");
    const [serieChange, setSerieChange] = useState<string>("");


    const [hasTimeSelected, setHasTimeSelected] = useState<boolean>(false);
    const [selectedDay, setSelectedDay] = useState<any>(now.getDate());
    const [nonAvailableDates, setNonAvailableDates] = useState<Date[]>([]);

    const [code, setCode] = useState("");
    const [showPattern, setShowPattern] = React.useState(true);

    const [firstSelectedNachnahme, setFirstSelectedNachnahme] = useState(false);

    const [macSeriennummerInputValue, setmacSeriennummerInputValue] = useState('');

    const [anfrageGeschickt, setAnfrageGeschickt] = useState(false);
    const [anfrageLoading, setAnfrageLoading] = useState(false);

    const [selectedReparaturen, setSelectedReparaturen] = useState<ReparaturInfo[]>([]);
    const [summe, setSumme] = useState<number>(0);

    const [ort, setOrt] = useState<string>("");

    const [backClicked, setBackClicked] = useState(false);

    const [macAusgefüllt, setmacAusgefüllt] = useState(false);
    const [ShowAnfrageSenden, setShowAnfrageSenden] = useState(false);
    const [MacFertigAusgefüllt, setMacFertigAusgefüllt] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    const vornameRef1 = useRef<HTMLInputElement>(null);
    const nachnameRef1 = useRef<HTMLInputElement>(null);
    const emailRef1 = useRef<HTMLInputElement>(null);
    const telefonnummerRef1 = useRef<HTMLInputElement>(null);
    const straßeRef1 = useRef<HTMLInputElement>(null);
    const hausnummerRef1 = useRef<HTMLInputElement>(null);
    const plzRef1 = useRef<HTMLInputElement>(null);
    const ortRef1 = useRef<HTMLInputElement>(null);
    const passwortRef1 = useRef<HTMLInputElement>(null);
    const imeiRef1 = useRef<HTMLInputElement>(null);

    const vornameRef2 = useRef<HTMLInputElement>(null);
    const nachnameRef2 = useRef<HTMLInputElement>(null);
    const emailRef2 = useRef<HTMLInputElement>(null);
    const telefonnummerRef2 = useRef<HTMLInputElement>(null);
    const straßeRef2 = useRef<HTMLInputElement>(null);
    const hausnummerRef2 = useRef<HTMLInputElement>(null);
    const plzRef2 = useRef<HTMLInputElement>(null);
    const ortRef2 = useRef<HTMLInputElement>(null);
    const passwortRef2 = useRef<HTMLInputElement>(null);
    const serienNummerMacRef2 = useRef<HTMLInputElement>(null);

    const submitButtonRef1 = useRef<any>(null);
    const submitButtonRef21 = useRef<any>(null);
    const submitButtonRef22 = useRef<any>(null);
    const formRef = useRef<any>(null);
    const formRef2 = useRef<any>(null);


    const [selectedMac, setSelectedMac] = useState<string | null>("");

    function handleZahlungChange(auswahl: any) {
        if (auswahl === "Nachnahme") {
            setSumme((summe) => summe + 7);
            setFirstSelectedNachnahme(true);
            setSelectedReparaturen((prevSelectedReparaturen) => [...prevSelectedReparaturen, {
                preis: 7,
                aktionsPreis: 0,
                reparaturName: "Nachnahme Gebühren",
                iconBase64: "string",
                dauer: "",
                repBeschreibung: "string"
            }]);

        } else {
            if (firstSelectedNachnahme) {
                setSumme((summe) => summe - 7);
                setSelectedReparaturen((prevSelectedReparaturen) =>
                    prevSelectedReparaturen.filter((item) => item.reparaturName !== "Nachnahme Gebühren")
                );
            }
        }
    }

    const handleMacButtonClick = (name: string) => {
        setSelectedMac(name);
    };

    const getMacButtonClass = (name: string) => {
        return `bg-slate-50 h-32 w-full md:h-32 md:w-32 shadow-sm shadow-black rounded-md flex justify-center items-center ${selectedMac === name ? 'border-green-500 border-4' : 'border'}`;
    };

    // *********************ACCORDION***************************
    const accordionItems: AccordionItem[] = searchParams.get("kategorie") !== kategorieZuordnung.Mac.toString() ? [
        {
            id: 'accordion-example-heading-1',
            triggerEl: document.getElementById('adresseBackButton')!,
            targetEl: document.getElementById('accordion-example-body-1')!,
            active: true
        },
        {
            id: 'accordion-example-heading-2',
            triggerEl: document.getElementById('infoNextButtonEinsenden')!,
            targetEl: document.getElementById('accordion-example-body-2')!,
            active: false
        },
        {
            id: 'accordion-example-heading-1',
            triggerEl: document.getElementById('terminToInformationBackButton')!,
            targetEl: document.getElementById('accordion-example-body-1')!,
            active: false
        },
        {
            id: 'accordion-example-heading-3',
            triggerEl: document.getElementById('infoNextButton')!,
            targetEl: document.getElementById('accordion-example-body-3')!,
            active: false
        },
        {
            id: 'accordion-example-heading-4',
            triggerEl: document.getElementById('terminNextButton')!,
            targetEl: document.getElementById('accordion-example-body-4')!,
            active: false
        },
        {
            id: 'accordion-example-heading-4',
            triggerEl: document.getElementById('adresseNextButton')!,
            targetEl: document.getElementById('accordion-example-body-4')!,
            active: false
        }
        ,
        {
            id: 'accordion-example-heading-2',
            triggerEl: document.getElementById('informationenToAdresse')!,
            targetEl: document.getElementById('accordion-example-body-2')!,
            active: false
        }
        ,
        {
            id: 'accordion-example-heading-3',
            triggerEl: document.getElementById('informationenToTermin')!,
            targetEl: document.getElementById('accordion-example-body-3')!,
            active: false
        }
    ] : [];
    const accordionItems2: AccordionItem[] = searchParams.get("kategorie") === kategorieZuordnung.Mac.toString() ? [
        {
            id: 'accordion-example-heading-10',
            triggerEl: document.getElementById('infoBack0')!,
            targetEl: document.getElementById('accordion-example-body-10')!,
            active: true
        },
        {
            id: 'accordion-example-heading-20',
            triggerEl: document.getElementById('geraetInfoNext0')!,
            targetEl: document.getElementById('accordion-example-body-20')!,
            active: false
        },
        {
            id: 'accordion-example-heading-30',
            triggerEl: document.getElementById('infoToAdresse0')!,
            targetEl: document.getElementById('accordion-example-body-30')!,
            active: false
        },
        {
            id: 'accordion-example-heading-40',
            triggerEl: document.getElementById('infoToTermin0')!,
            targetEl: document.getElementById('accordion-example-body-40')!,
            active: false
        },
        {
            id: 'accordion-example-heading-20',
            triggerEl: document.getElementById('adresseBack0')!,
            targetEl: document.getElementById('accordion-example-body-30')!,
            active: false
        }
        ,
        {
            id: 'accordion-example-heading-30',
            triggerEl: document.getElementById('terminBack0')!,
            targetEl: document.getElementById('accordion-example-body-30')!,
            active: false
        }
    ] : [];

    // options with default values
    const options: AccordionOptions = {
        activeClasses: 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
        inactiveClasses: 'text-gray-500 dark:text-gray-400',
        onOpen: (item: any) => {


        },
        onClose: (item: any) => {


        },
        onToggle: (item: any) => {


        },
    };

    function modalSearchClicked(event: any): void {

        setMarkeSelection(markeSelectionChange);
        setSerie(serieChange);
        setModell(modellChange);
        handleModellChange(modellChange);
        if (kategorie !== kategorieZuordnung.iPhone.toString() && kategorie !== kategorieZuordnung.iPad.toString() && kategorie !== kategorieZuordnung.iWatch.toString()) {
            setMarkeSelectionChange("");
            setSerieChange("");
        }
        setModellChange("");
        //resett all selected repairs and the sum
        setSelectedReparaturen([]);
        setSumme(0);
        setOpenModal(false);

    }
    useEffect(() => {
        if (kategorie !== kategorieZuordnung.iPhone.toString() && kategorie !== kategorieZuordnung.iPad.toString() && kategorie !== kategorieZuordnung.iWatch.toString()) {
            setMarkeSelectionChange("");
            setSerieChange("");
        }
        setModellChange("");
        //resett all selected repairs and the sum
        setSelectedReparaturen([]);
        setSumme(0);
    }, [openModal]);
    // window.onload = () => {
    //     setTimeout(() => {
    //         const $modalElement: HTMLElement | null = document.getElementById("modalEl");

    //         const modalOptions: ModalOptions = {
    //             placement: 'center',
    //             backdrop: 'dynamic',
    //             backdropClasses: 'bg-gray-900 bg-opacity-80 backdrop-blur-xl dark:bg-opacity-80 fixed inset-0 z-40',
    //             onHide: () => {
    //             },
    //             onShow: () => {

    //             },
    //             onToggle: () => {
    //             }

    //         }

    //         const modal: ModalInterface = new Modal($modalElement, modalOptions);
    //         const $buttonElement: HTMLElement = document.getElementById('ModalButtonToggle')!;
    //         const $searchButton: HTMLElement = document.getElementById('modalSearch')!;


    //         $buttonElement.addEventListener('click', () => modal.show());
    //         $searchButton.addEventListener('click', () => modal.hide());








    //         /*
    //         * accordionItems: array of accordion item objects
    //         * options: optional
    //         */

    //     }, 1500);
    // }



    const handlePatternChange = (pattern: string) => {
        setCode(pattern);
    };
    const handlePatternClick = () => {
        setShowPattern(!showPattern);
    };

    const fetchAvailableTimeSlots = async () => {
        const response = await fetch(connection + "/MacAndPhoneDoc/GetAvailableTimeSlots");
        const timeSlots = await response.json();

        const filteredSlots = timeSlots.filter((slot: TimeSlot) => {
            const slotDate = new Date(slot.slotDate);
            if (startDate) {
                return (
                    slotDate.getDate() === startDate.getDate() &&
                    slotDate.getMonth() === startDate.getMonth() &&
                    slotDate.getFullYear() === startDate.getFullYear()
                );
            } else {
                return false;
            }
        });

        const excludedTimes = filteredSlots.map((slot: TimeSlot) => {
            const slotDate = new Date(slot.slotDate);
            const startHours = slotDate.getHours();
            const startMinutes = slotDate.getMinutes();
            return new Date(slotDate.getFullYear(), slotDate.getMonth(), slotDate.getDate(), startHours, startMinutes);

        });
        setNonAvailableDates(excludedTimes);
    };


    function compareIphoneModels(a: modellGroup, b: modellGroup): number {
        if (typeof a.modellName !== 'string' || typeof b.modellName !== 'string') {
            throw new Error('modellName of both a and b should be of type string');
        }

        // Handle special names like 'XR', 'XS', 'Plus', 'Max'
        const specialNames: { [key: string]: number } = {
            'SE': 0,

            'Mini': 1,
            'Plus': 2,
            'Max': 3,
            'Pro': 4,
            'Pro Max': 5,
            'XR': 6,
            'XS': 7,
            'FE': 8,
            'e': 9
        };

        const getWeight = (name: string) => {

            let num = parseInt(name.replace(/[^0-9]/g, ''), 10);
            const special = name.replace(/[0-9]/g, '').trim().split(' ')[2];
            if (num === 2020) {
                num = 5;
            }
            return (num * 10) + (specialNames[special] || 0);
        };

        return getWeight(a.modellName) - getWeight(b.modellName);
    }


    React.useEffect(() => {


        setSourcesLoading(true);


        api();
        function handleClickOutside(event: any) {
            if (patternRef.current && !patternRef.current.contains(event.target)) {
                setShowPattern(false);
            }
        }



        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // cleanup the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
        };



        // eslint-disable-next-line
    }, [])

    const api = async () => {


        const resultData = await fetch(connection + "/MacAndPhoneDoc/GetModellByGroup?categorieID=" + kategorie, { method: "GET" });
        const herstellerData = await fetch(connection + "/MacAndPhoneDoc/GetMarkeByCategory?categorieID=" + kategorie, { method: "GET" });

        const herstellerJsonData = await (await herstellerData).json();
        const resultJsonData = await resultData.json();

        setMarke(herstellerJsonData);

        const sortedSources = [...resultJsonData].sort(compareIphoneModels);
        setSources(sortedSources);

        if (kategorie === kategorieZuordnung.iPhone.toString() || kategorie === kategorieZuordnung.iPad.toString() || kategorie === kategorieZuordnung.iWatch.toString()) {
            setMarkeSelection("Apple");
            setMarkeSelectionChange("Apple");
            if (kategorie === kategorieZuordnung.iPhone.toString()) {
                setSerie("iPhone");
                setSerieChange("iPhone");
            }
            if (kategorie === kategorieZuordnung.iPad.toString()) {
                setSerie("iPad");
                setSerieChange("iPad");
            }
            if (kategorie === kategorieZuordnung.iWatch.toString()) {
                setSerie("iWatch");
                setSerieChange("iWatch");
            }
        }
        if (kategorie === kategorieZuordnung.Mac.toString()) {
            setStep(5);
        }
        if (searchParams.get("modellName") !== null) {


            if (kategorie !== kategorieZuordnung.iPhone.toString() && kategorie !== kategorieZuordnung.iPad.toString() && kategorie !== kategorieZuordnung.iWatch.toString()) {
                setMarkeSelection(searchParams.get("markenName")!);
                setSerie(searchParams.get("unterkategorieName")!);
                if (kategorie === kategorieZuordnung.iPhone.toString()) {
                    setMarkeSelectionChange("Apple");
                    setSerieChange("iPhone");

                }
                if (kategorie === kategorieZuordnung.iPad.toString()) {
                    setMarkeSelectionChange("Apple");

                    setSerieChange("iPad");
                }
                if (kategorie === kategorieZuordnung.iWatch.toString()) {
                    setMarkeSelectionChange("Apple");

                    setSerieChange("iWatch");
                }
            }
            setModell(searchParams.get("modellName")!);
            handleModellChange(searchParams.get("modellName")!);
            setStep(2);



        }
        setSourcesLoading(false);
    }
    const checkValidity = () => {
        const allInputs = formRef.current.querySelectorAll('input');
        const allInputsValid = Array.from(allInputs).every((input: any) => input.validity.valid);
        submitButtonRef1.current.disabled = !allInputsValid;
    };
    const checkValidity2 = () => {
        const allInputs = formRef2.current.querySelectorAll('input');
        const allInputsValid = Array.from(allInputs).every((input: any) => input.validity.valid);


        if (!allInputsValid) {
            setMacFertigAusgefüllt(false);
            submitButtonRef21.current.disabled = true;
            submitButtonRef22.current.disabled = true;
        } else {
            setMacFertigAusgefüllt(true);
            submitButtonRef22.current.disabled = false;
            submitButtonRef21.current.disabled = false;
        }
    };





    React.useEffect(() => {
        prevDateRef.current = startDate;
        if (startDate !== null) {
            fetchAvailableTimeSlots();
        }
    }, [startDate]);

    React.useEffect(() => {
    }, [nonAvailableDates]);

    useEffect(() => {
        switch (stepCounter) {
            case 1:
                setSelectedReparaturen([]);
                setSumme(0);
                if (kategorie !== kategorieZuordnung.iPhone.toString() && kategorie !== kategorieZuordnung.iPad.toString() && kategorie !== kategorieZuordnung.iWatch.toString()) {
                    setMarkeSelection("");
                    setSerie("");
                }
                setModell("");
                setBackClicked(false);
                break;
            case 3:
                const allInputs1 = kategorie === kategorieZuordnung.Mac.toString() ? formRef2.current.querySelectorAll('input') : formRef.current.querySelectorAll('input');
                allInputs1.forEach((input: any) => {
                    input.value = "";
                });
                break;
            case 4:
                checkValidity();
                const allInputs = formRef.current.querySelectorAll('input');
                allInputs.forEach((input: any) => {
                    input.addEventListener('input', checkValidity);
                });
                setStartDate(null);
                setHasTimeSelected(false);
                const accordionItemsToUse = kategorie === kategorieZuordnung.Mac.toString() ? accordionItems2 : accordionItems;
                const accordion: AccordionInterface = new Accordion(accordionItemsToUse, options);
                accordion.open(kategorie === kategorieZuordnung.Mac.toString() ? 'accordion-example-heading-10' : 'accordion-example-heading-1');
                break;
            case 5:
                checkValidity2();
                const allInputs2 = formRef2.current.querySelectorAll('input');
                allInputs2.forEach((input: any) => {
                    input.addEventListener('input', checkValidity2);
                });
                setStartDate(null);
                setHasTimeSelected(false);
                const accordionItemsToUse2 = kategorie === kategorieZuordnung.Mac.toString() ? accordionItems2 : accordionItems;
                const accordion2: AccordionInterface = new Accordion(accordionItemsToUse2, options);
                accordion2.open(kategorie === kategorieZuordnung.Mac.toString() ? 'accordion-example-heading-10' : 'accordion-example-heading-1');
                break;
            default:
                break;
        }
    }, [backClicked, stepCounter]);
    function handleNext() {
        if (stepCounter === step.Ort && kategorie === kategorieZuordnung.Mac.toString()) {
            setStep((currentStep) => currentStep + 2);
        } else {
            setStep((currentStep) => currentStep + 1);
        }



    }

    function handleBack() {


        if (kategorie === kategorieZuordnung.Mac.toString() && stepCounter === step.Ort) {
            setStep((currentStep) => currentStep + 1);
            // navigate("/#root");
        }
        if (kategorie === kategorieZuordnung.Mac.toString() && stepCounter === step.Mac) {
            window.location.href = "/";
        } else {
            setStep((currentStep) => currentStep - 1);

        }
        setBackClicked(true);
    }

    const [imei, setImei] = useState('');
    const handleImeiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value.length <= 15) {
            setImei(value.replace(/\D/g, '')); // Replace any non-digit characters
        }
    };

    const handleModellChange = async (modellName: any) => {
        var i = 0;
        switch (kategorie) {
            case "1":
                i = 1;
                break;
            case "2":
                i = 2;
                break;
            case "3":
                i = 1;
                break;
            case "4":
                i = 3;
                break;
            case "5":
                i = 4;
                break;
        }
        const resultData = await fetch(connection + "/MacAndPhoneDoc/GetReparaturByModellName?modellName=" + modellName + "&geraetArtID=" + i, { method: "GET" });

        const resultJsonData = await resultData.json();
        setRepSources(resultJsonData);

    };

    const setModellChanges = (value: any) => {
        setModellChange(value);
    }

    const handleReparaturClick = (reparatur: ReparaturInfo) => {
        const isSelected = selectedReparaturen.some((item) => item.reparaturName === reparatur.reparaturName);
        if (isSelected) {
            if (reparatur.aktionsPreis === 0) {
                setSumme(summe - reparatur.preis);
            } else {
                setSumme(summe - reparatur.aktionsPreis);
            }

            setSelectedReparaturen((prevSelectedReparaturen) =>
                prevSelectedReparaturen.filter((item) => item.reparaturName !== reparatur.reparaturName)
            );
        } else {
            if (reparatur.aktionsPreis === 0) {
                setSumme(summe + reparatur.preis);
            } else {
                setSumme(summe + reparatur.aktionsPreis);
            }
            setSelectedReparaturen((prevSelectedReparaturen) => [...prevSelectedReparaturen, reparatur]);
        }
    };

    const handleRemoveClick = (reparatur: ReparaturInfo) => {
        const isSelected = selectedReparaturen.some((item) => item.reparaturName === reparatur.reparaturName);
        if (isSelected) {
            if (reparatur.aktionsPreis === 0) {
                setSumme(summe - reparatur.preis);
            } else {
                setSumme(summe - reparatur.aktionsPreis);
            }
            setSelectedReparaturen((prevSelectedReparaturen) =>
                prevSelectedReparaturen.filter((item) => item.reparaturName !== reparatur.reparaturName)
            );
        }
    };

    function einsenden(event: any): void {
        setOrt("Einsenden");
        if (kategorie === kategorieZuordnung.Mac.toString()) {
            setStep((currentStep) => currentStep + 2);
        } else {
            setStep((currentStep) => currentStep + 1);
        }
    }

    function vorOrt(event: any): void {
        setOrt("Vor Ort");
        if (kategorie === kategorieZuordnung.Mac.toString()) {
            setStep((currentStep) => currentStep + 2);
        } else {
            setStep((currentStep) => currentStep + 1);
        }
    }

    function toISOStringLocal(date: any) {
        var tzOffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, -1);
        return localISOTime;
    }

    function setCodeValue(value: React.SyntheticEvent<HTMLInputElement, Event>): void {
        setCode(value.currentTarget.value);
    }
    function isCyclic(obj: any) {
        var seenObjects: any = [];

        function detect(obj: any) {
            if (obj && typeof obj === 'object') {
                if (seenObjects.indexOf(obj) !== -1) {
                    return true;
                }
                seenObjects.push(obj);
                for (var key in obj) {
                    if (obj.hasOwnProperty(key) && detect(obj[key])) {
                        return true;
                    }
                }
            }
            return false;
        }

        return detect(obj);
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setAnfrageGeschickt(true);
        setAnfrageLoading(true);

        let formData: any = {};
        if (searchParams.get("kategorie") === "4") {
            let NameZusammen = event.currentTarget['Vorname2'].value + " " + event.currentTarget['Nachname2'].value;
            const rep: ReparaturInfo[] = [
                {
                    preis: 0,
                    aktionsPreis: 0,
                    reparaturName: event.currentTarget['MacProblem'].value,
                    iconBase64: "string",
                    dauer: "",
                    repBeschreibung: "string"
                }
            ];
            formData = {
                from: event.currentTarget['Email2'] ? event.currentTarget['Email2'].value : "",
                id: 0,
                to: event.currentTarget['Email2'] ? event.currentTarget['Email2'].value : "",
                subject: "Anfrage von " + NameZusammen,
                body: event.currentTarget['Schadenbeschreibung2'] ? event.currentTarget['Schadenbeschreibung2'].value : "",
                name: NameZusammen,
                email: event.currentTarget['Email2'] ? event.currentTarget['Email2'].value : "",
                tel: event.currentTarget['Telefonnummer2'] ? event.currentTarget['Telefonnummer2'].value : "",
                markeSelection: "Apple",
                modell: "",
                serie: "",
                termin: toISOStringLocal(startDate),
                schadenbeschreibung: event.currentTarget['Schadenbeschreibung2'] ? event.currentTarget['Schadenbeschreibung2'].value : "",
                selected: rep,

                macAuswahl: selectedMac,

                straße: event.currentTarget['Straße2'] ? event.currentTarget['Straße2'].value : "",
                hausnummer: event.currentTarget['Hausnummer2'] ? event.currentTarget['Hausnummer2'].value : "",
                plz: event.currentTarget['PLZ2'] ? event.currentTarget['PLZ2'].value : "",
                ort: event.currentTarget['Ort2'] ? event.currentTarget['Ort2'].value : "",
                passwort: "",
                zahlungsart: event.currentTarget['Zahlungsart2'] ? event.currentTarget['Zahlungsart2'].value : "",
                imei: macSeriennummerInputValue,
            };
        } else {
            let NameZusammen = event.currentTarget['Vorname1'].value + " " + event.currentTarget['Nachname1'].value;
            formData = {
                from: event.currentTarget['Email1'] ? event.currentTarget['Email1'].value : "",
                id: 0,
                to: event.currentTarget['Email1'] ? event.currentTarget['Email1'].value : "",
                subject: "Anfrage von " + NameZusammen,
                body: event.currentTarget['Schadenbeschreibung1'] ? event.currentTarget['Schadenbeschreibung1'].value : "",
                name: NameZusammen,
                email: event.currentTarget['Email1'] ? event.currentTarget['Email1'].value : "",
                tel: event.currentTarget['Telefonnummer1'] ? event.currentTarget['Telefonnummer1'].value : "",
                markeSelection: markeSelection,
                modell: modell,
                serie: serie,
                termin: event.currentTarget['Straße1'] ? "" : toISOStringLocal(startDate),
                schadenbeschreibung: event.currentTarget['Schadenbeschreibung1'] ? event.currentTarget['Schadenbeschreibung1'].value : "",
                selected: selectedReparaturen, // No need to stringify it                                           
                macAuswahl: "",
                straße: event.currentTarget['Straße1'] ? event.currentTarget['Straße1'].value : "",
                hausnummer: event.currentTarget['Hausnummer1'] ? event.currentTarget['Hausnummer1'].value : "",
                plz: event.currentTarget['PLZ1'] ? event.currentTarget['PLZ1'].value : "",
                ort: event.currentTarget['Ort1'] ? event.currentTarget['Ort1'].value : "",
                passwort: event.currentTarget['Passwort1'] ? event.currentTarget['Passwort1'].value : "",
                zahlungsart: event.currentTarget['Zahlungsart1'] ? event.currentTarget['Zahlungsart1'].value : "",
                imei: event.currentTarget['IMEI1'] ? event.currentTarget['IMEI1'].value : "",
            };

        }
        // Create an object to hold form data
        if (isCyclic(formData)) {
            console.error("formData contains a circular reference");
            return; // Don't proceed with the Axios call
        }
        try {
            // Make API request
            const response = await axios.post(connection + '/MacAndPhoneDoc/SubmitFormAnfrage?versandArt=' + ort, formData, {
                headers: { 'Content-Type': 'application/json' },
            });

            // Handle response
            setAnfrageLoading(false);
        } catch (error) {
        }
    };



    React.useEffect(() => {
        const filteredItems = sources
            .filter((element: modellGroup) => element.markenName === markeSelection)
            .reduce((uniqueItems: string[], element: modellGroup) => {

                if (!uniqueItems.includes(element.unterkategorieName)) {
                    uniqueItems.push(element.unterkategorieName);
                    return uniqueItems;
                }
                return uniqueItems;
            }, []);
        if (filteredItems.length === 1 && filteredItems[0] === "leer") {
            setSerie("leer");
        }
    }, [markeSelection]);

    React.useEffect(() => {
        const filteredItems = sources
            .filter((element: modellGroup) => element.markenName === markeSelectionChange)
            .reduce((uniqueItems: string[], element: modellGroup) => {

                if (!uniqueItems.includes(element.unterkategorieName)) {
                    uniqueItems.push(element.unterkategorieName);
                    return uniqueItems;
                }
                return uniqueItems;
            }, []);
        if (filteredItems.length === 1 && filteredItems[0] === "leer") {
            setSerieChange("leer");
        }
    }, [markeSelectionChange]);

    return (
        <>
            <script type="module" src="./node_modules/flowbite/dist/flowbite.js"></script>

            <div className=''>
                <div className='w-full bg-primary pt-12'>
                    <img src={Banner} alt="Banner" className=' md:h-80 mx-auto aspect-auto' />
                </div>
                {anfrageGeschickt ?

                    <>
                        {anfrageLoading ? <>
                            <div id="send-animation-container" className=' overflow-hidden w-fit mx-auto'>
                                <div className='flex flex-row'>
                                    <div className="paper-plane" style={{ backgroundImage: `url(${plane})` }}></div>
                                    <div className="paper-plane2" style={{ backgroundImage: `url(${plane})` }}></div>
                                </div>
                                <div id="send-animation-text">Sending...</div>
                            </div>


                        </> : <>
                            <div className='bg-gray-100 p-5'>
                                <div className="bg-white p-6  md:mx-auto w-fit">
                                    <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto my-6">
                                        <path fill="currentColor"
                                            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                                        </path>
                                    </svg>
                                    <div className="text-center">
                                        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Anfrage gesendet!</h3>
                                        <p className="text-gray-600 my-2">Vielen Dank, dass sie sich für uns entschieden haben.</p>
                                        <p> Wir wünschen Ihnen einen schönen Tag! </p>
                                        <div className="py-10 text-center">
                                            <a href="./" className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3">
                                                Zur Startseite
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </>

                    :

                    <>
                        <div className={`mx-auto bg-white md:p-10 rounded-3xl rounded-t-lg w-full py-4 ${searchParams.get("kategorie") === kategorieZuordnung.Mac.toString() ? "" : "hidden"}`}>
                            <h2 className='Displaytausch-Demo-Text font-bold lg:text-3xl mb-3'> Willkommen beim MacBook Reparaturdienst!</h2><br />
                            <p>Ihr MacBook hat sich verabschiedet? Keine Sorge, wir sind hier, um es wieder auf die Beine zu bringen! Unser MacBook Reparaturdienst steht für schnelle, zuverlässige und qualitativ hochwertige Reparaturen.</p>

                            <p>Unsere erfahrenen Techniker sind darauf spezialisiert, eine Vielzahl von MacBook-Problemen zu beheben, sei es ein kaputter Bildschirm, eine langsame Performance oder ein unerklärliches Verhalten. Wir setzen auf erstklassige Ersatzteile und modernste Technologie, um sicherzustellen, dass Ihr MacBook in kürzester Zeit wieder wie neu läuft.</p>

                            <h3>Unsere Leistungen im Überblick:</h3>
                            <h4>Bildschirmreparatur und -austausch:</h4>
                            <p>Ihr MacBook-Bildschirm ist das Fenster zu Ihrer digitalen Welt. Unsere Experten kümmern sich um Risse, Pixelfehler oder andere Bildschirmprobleme. Wir verwenden ausschließlich hochwertige Ersatzteile, um sicherzustellen, dass Ihr Display gestochen scharf und farbgetreu bleibt.</p>
                            <h4>Festplatten- und SSD-Upgrades:</h4>
                            <p>Verbessern Sie die Speicherkapazität und Geschwindigkeit Ihres MacBooks mit unseren Festplatten- und SSD-Upgrades. Wir beraten Sie gerne bei der Auswahl der richtigen Speicherlösung, übertragen Ihre Daten sicher und sorgen dafür, dass Ihr MacBook wieder mit Höchstleistung läuft.</p>
                            <h4>Akkutausch:</h4>
                            <p>Ein schwacher Akku kann die Produktivität erheblich beeinträchtigen. Wir ersetzen Ihren alten Akku durch einen neuen, leistungsstarken Akku, damit Sie wieder stundenlang ohne Steckdose arbeiten können. Genießen Sie die Freiheit eines zuverlässigen Akkus!</p>
                            <h4>Softwareprobleme und Systemoptimierung:</h4>
                            <p>Manchmal sind es nicht nur Hardwareprobleme. Wir diagnostizieren und beheben Softwareprobleme, optimieren Ihr Betriebssystem und stellen sicher, dass Ihr MacBook wieder reibungslos läuft. Schnelle Lösungen für ein flüssiges digitales Erlebnis!</p>
                            <h4>Wasserschaden:</h4>
                            <p>Ein unglücklicher Wasserschaden kann Ihr MacBook gefährden. Unsere Experten sind darauf spezialisiert, Wasserschäden zu diagnostizieren und zu beheben. Wir setzen moderne Techniken ein, um sicherzustellen, dass Ihr MacBook nach einem Wasserschaden wieder voll funktionsfähig ist. Vertrauen Sie uns, um Ihr wertvolles Gerät zu retten!</p>
                            <h3>Warum sollten Sie sich für uns entscheiden?</h3>
                            <ol>
                                <li><strong>Schneller Service:</strong> Wir wissen, wie wichtig Ihr MacBook für Sie ist. Deshalb bemühen wir uns, Reparaturen so schnell wie möglich durchzuführen, ohne dabei Kompromisse bei der Qualität einzugehen.</li>

                                <li><strong>Transparente Preise:</strong> Bei uns gibt es keine versteckten Kosten. Sie erhalten vor der Reparatur eine klare Aufschlüsselung der Kosten, sodass Sie genau wissen, was auf Sie zukommt.</li>

                                <li><strong>Garantierte Zufriedenheit:</strong> Ihre Zufriedenheit steht an erster Stelle. Wir bieten eine Garantie auf alle durchgeführten Reparaturen, damit Sie mit einem ruhigen Gewissen Ihr wiederhergestelltes MacBook genießen können.</li>
                            </ol>

                            <p>Vertrauen Sie Ihrem MacBook nur den Besten an! Kontaktieren Sie uns noch heute, um eine Diagnose und ein unverbindliches Angebot zu erhalten. Wir sind hier, um Ihr MacBook wieder zum Leben zu erwecken!</p>



                        </div>


                        <div className='flex flex-col mt-3 px-3'>

                            {stepCounter !== 1 && <button onClick={handleBack} className=" bg-red-800 rounded-lg text-white p-2">Zurück</button>}
                            <ol className="flex items-center flex-wrap md:flex-nowrap w-11/12 mx-auto justify-center text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                                {kategorie !== kategorieZuordnung.Mac.toString() &&
                                    <>

                                        <li key={"WelchesModell"} className="flex md:w-full items-center text-blue-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                            <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                                <svg aria-hidden="true" className="w-4 h-4 mr-2 sm:w-5 sm:h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                                <span className='text-xs'>Welches Modell?</span>
                                            </span>
                                        </li>
                                        <li key={"WelcheReparaturen?"} className={stepCounter > 1 ? "flex md:w-full items-center text-blue-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700" : "flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"}>
                                            <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                                {stepCounter > 1 ? <svg aria-hidden="true" className="w-4 h-4 mr-2 sm:w-5 sm:h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg> : <span className="mr-2">2</span>}
                                                <span className='text-xs'>Welche Reparaturen?</span>
                                            </span>
                                        </li>
                                    </>

                                }
                                <li key={"ZusendenoderimLaden?"} className={stepCounter > 2 ? "flex md:w-full items-center text-blue-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700" : "flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"}>
                                    <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                        {stepCounter > 2 ? <svg aria-hidden="true" className="w-4 h-4 mr-2 sm:w-5 sm:h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg> : <span className="mr-2">3</span>}

                                        <span className='text-xs'>Zusenden oder im Laden?</span>
                                    </span>
                                </li>
                                {kategorie !== kategorieZuordnung.Mac.toString() ?


                                    <li key={"Preis&Kontakt"} className={stepCounter > 3 ? "flex items-center text-blue-600 dark:text-blue-500" : "flex items-center"}>
                                        {stepCounter > 3 ? <svg aria-hidden="true" className="w-4 h-4 mr-2 sm:w-5 sm:h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg> : <span className="mr-2">4</span>}

                                        <span className='text-xs'>Preis & Kontakt</span>
                                    </li>
                                    :
                                    <li key={"Preis&Kontakt"} className={stepCounter > 4 ? "flex items-center text-blue-600 dark:text-blue-500" : "flex items-center"}>
                                        {stepCounter > 4 ? <svg aria-hidden="true" className="w-4 h-4 mr-2 sm:w-5 sm:h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg> : <span className="mr-2"></span>}

                                        <span className='text-xs'>Kontakt</span>
                                    </li>
                                }
                            </ol>
                        </div>
                        <div className={`rounded-t-lg w-full text-center ${searchParams.get("kategorie") !== kategorieZuordnung.Mac.toString() ? "" : "hidden"} `}>Kleine Reparaturen ( Austausch von Ersatzteilen ohne Lötvorgänge ) führen wir selbst in unserer Werkstatt durch.<br />
                            Größere Reparaturen ( mit Lötvorgängen ) geben wir an einen unserer Kooperationspartner weiter.<br />
                            <strong>Nach der Reparatur ist das Gerät nicht mehr wasserdicht</strong></div>
                        <div className={`rounded-t-lg w-full text-center ${searchParams.get("kategorie") !== kategorieZuordnung.Mac.toString() && searchParams.get("kategorie") !== kategorieZuordnung.Smartphone.toString() ? "" : "hidden"} `}>
                            Wir verwenden keine Originaldisplays von Apple, sondern hochwertige Ersatzdisplays in Erstausrüsterqualität</div>

                        <div className={` ${stepCounter === step.Modell ? "flex flex-col gap-6 md:gap-0 mt-5 md:flex-row items-center min-h-[40vh] " : "transition_up animate-fade-out"} `}>

                            <div role="status" className={`${!sourcesLoading ? "hidden" : "mx-auto"}`}>
                                <svg aria-hidden="true" className=" w-28 h-28 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className={`${!sourcesLoading ? "bg-primary rounded-xl shadow-md  w-fit min-w-[40%] h-fit p-10 mx-auto " : "hidden"}`}>
                                <div className='grid grid-flow-row  col-template-3 gap-5 max-h-[25vh]'>
                                    {kategorie !== kategorieZuordnung.iPhone.toString() && kategorie !== kategorieZuordnung.iPad.toString() && kategorie !== kategorieZuordnung.iWatch.toString() && <>
                                        <div className={`w-full flex flex-wrap items-center`}>
                                            <div className='mx-auto w-fit'>

                                                <Select key={markeSelection} onValueChange={(value: any) => { setMarkeSelection(value); setModell(""); setSerie(""); }} defaultValue=''>
                                                    <SelectTrigger className="w-[180px] text-white font-semibold">
                                                        {markeSelection === "" ? "Wähle deine Marke" : markeSelection}
                                                    </SelectTrigger>
                                                    <SelectContent defaultValue={markeSelection} >
                                                        <SelectGroup className='max-h-72 overflow-auto'>

                                                            {marke.map((element: any, index) => {
                                                                return (

                                                                    <SelectItem value={element.item2} key={element.item1 + index}>{element.item2}</SelectItem>
                                                                );
                                                            })}
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                        </div>

                                        <div className={`flex flex-wrap items-center w-full ${serie === "leer" && "display-none"} ${markeSelection === "" && " hidden"}`}>

                                            <div className='mx-auto w-fit'>
                                                <Select key={markeSelection} onValueChange={(value: any) => { setSerie(value); setModell(""); }} defaultValue=''>
                                                    <SelectTrigger className="w-[180px]  text-white font-semibold">
                                                        {serie === "" ? "Wähle die Serie" : serie}
                                                    </SelectTrigger>
                                                    <SelectContent defaultValue='' >

                                                        <SelectGroup className='max-h-72 overflow-auto'>
                                                            {
                                                                sources
                                                                    .filter((element: modellGroup) => element.markenName === markeSelection)
                                                                    .reduce((uniqueItems: string[], element: modellGroup) => {
                                                                        if (!uniqueItems.includes(element.unterkategorieName)) {
                                                                            uniqueItems.push(element.unterkategorieName);
                                                                            return uniqueItems;
                                                                        }
                                                                        return uniqueItems;
                                                                    }, [])
                                                                    .map((unterkategorieName: string, i: number) => (
                                                                        <SelectItem value={unterkategorieName} key={unterkategorieName + i}>{unterkategorieName}</SelectItem>
                                                                    ))
                                                            }
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </div>

                                        </div>
                                    </>
                                    }

                                    <div className={`flex flex-wrap items-center w-full ${serie === "" && " hidden"} `}>

                                        <div className='mx-auto w-fit '>
                                            <Select key={modell} onValueChange={(value: any) => { setModell(value); handleModellChange(value); }} defaultValue=''>
                                                <SelectTrigger className="w-[180px]  text-white font-semibold">
                                                    {modell === "" ? "Wähle dein Modell" : modell}
                                                </SelectTrigger>
                                                <SelectContent defaultValue='' >
                                                    <SelectGroup className=' border-black-1 max-h-72 overflow-auto'>
                                                        {sources
                                                            .filter((element: modellGroup) => element.unterkategorieName === serie && element.markenName === markeSelection)
                                                            .map((element: modellGroup, i: number) => (
                                                                <SelectItem value={element.modellName} key={element.markenName + i}>{element.modellName}</SelectItem>
                                                            ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </div>

                                    </div>
                                    <div className="w-full">
                                        <button onClick={() => handleNext()} className={`w-fit mx-auto flex h-full ${modell === "" && "pointer-events-none opacity-25"}`}>
                                            <div className={` w-14 h-14 flex align-middle p-4 items-center bg-amber-500 opacity-75 rounded-full ${modell === "" && "opacity-25"}`}>

                                                <img src={magnifying} alt='magnifying' className=' w-8 mx-auto' />

                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={`bg-white min-h-[80vh]   w-full md-w-1/3 ${stepCounter === step.Reparatur ? "animate-fade-in" : "hidden"}`}>
                            <div className='grid grid-flow-row lg:grid-flow-col col-template  min-h-[50vh] w-full  mx-auto pt-10 '>
                                <div className='w-1/2 mx-auto md:w-full col-span-1'>
                                    <div className='w-full md:w-1/2 mx-auto flex flex-col mb-5'>
                                        <div className='relative '>

                                            <p className='text-3xl font-bold text-center  mx-auto w-fit p-0 md:m-0 md:mx-auto text-black'>Reparaturen für<br /> {modell !== "" ? modell : "Ihre Auswahl"}</p>
                                            <button className="block mx-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none cursor-pointer focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" style={{ zIndex: 999 }} type='button' onClick={() => setOpenModal(true)}>Anderes Gerät wählen</button>


                                            <img src={iPhone} alt='iPhone Foto' />
                                            <img className='absolute bottom-0 w-3/4' src={garantie} alt='Notfall foto' />
                                        </div>
                                        <a href='tel:014103511' className=' cursor-pointer'><img src={notfall} alt='Garantie Bild' /></a>
                                    </div>
                                </div>

                                <div className='grid grid-cols-1 xs:grid-cols-2  p-4 md:grid-cols-3 w-full z-20 justify-center h-fit gap-5'>
                                    {repSources.map((element: any, index) => (
                                        <button key={element.reparaturName + index} onClick={() => handleReparaturClick(element)}>
                                            <div
                                                className={`bg-secondary flex items-center flex-col justify-center rounded-xl hover:scale-105 transition-all h-fit transform duration-500 cursor-pointer ${selectedReparaturen.some((item) => item.reparaturName === element.reparaturName)
                                                    ? 'selected-item'
                                                    : ''
                                                    }`}
                                            >
                                                <img src={element.iconBase64} className='w-1/3' alt='Reparatur Icon' />
                                                <p className='text-xl font-bold'>{element.reparaturName}</p>
                                                {element.aktionsPreis !== 0 ? (
                                                    <span className='flex gap-3'>
                                                        <p className='text-xl font-bold aktionspreisLine'>{element.preis}€</p>
                                                        <p className='text-xl font-bold'>{element.aktionsPreis}€</p>
                                                    </span>
                                                ) : (
                                                    <p className='text-xl font-bold'>{element.preis}€</p>
                                                )}
                                                <p className='text-sm'>Dauer: {element.dauer}</p>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                                <div className='md:min-h-[10vh] w-full sticky bottom-0 md:relative mt-5 px-5 md:px-10 mx-auto pt-0  bg-white z-30'>
                                    <div className='sticky bottom-0 md:top-14'>
                                        {selectedReparaturen.length > 0 &&

                                            <div className='p-5 md:p-10 border-4 rounded-lg border-primary'>
                                                <p className='text-2xl font-bold pb-4 pl-3'>Ihre Auswahl</p>

                                                <div className='max-h-[15vh] md:max-h-[30vh] overflow-y-auto px-3 '>
                                                    <div className='flex flex-row border-b-2 mb-2 sticky top-0 bg-white  border-primary justify-between text-lg font-semibold'>
                                                        <div className='flex flex-row '>
                                                            <p> Reparatur</p>
                                                        </div>
                                                        <p> Preis</p>
                                                    </div>
                                                    {
                                                        selectedReparaturen.map((element: any, index) => (
                                                            <React.Fragment key={element + index}>
                                                                <div className='flex py-2 border-b-2 flex-row justify-between text-base' key={element + index}>
                                                                    <div className='flex flex-row' key={element + index + "1"}>
                                                                        <button onClick={() => handleRemoveClick(element)} className='flex flex-row' key={element + index + "2"}>
                                                                            <img src={checkBoxSvg} className='w-[1.5rem]' alt="checkbox SVG" key={element + index + "3"} />
                                                                            <p key={element + index + "4"}> {element.reparaturName}</p>
                                                                        </button>
                                                                    </div>
                                                                    <p key={element + index + "5"}> {element.aktionsPreis === 0 ? element.preis : element.aktionsPreis}€</p>
                                                                </div>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </div>
                                                <div className=" flex justify-between font-semibold text-xl pt-3 px-3 relative h-10"><div className='absolute bottom-0'>Summe:</div><div className=' text-3xl absolute right-3 bottom-0'>{summe}€</div></div>
                                                <button className='bg-primary text-white w-full h-12 mt-5' onClick={handleNext}>Weiter</button>
                                            </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className={`w-fit mx-auto my-10 flex flex-col md:flex-row min-h-[55vh] gap-10 ${stepCounter === step.Ort ? "animate-fade-in" : "hidden"}`}>

                            <div className={`max-w-sm bg-white border relative border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}>

                                <img className="rounded-t-lg w-full" src={Zusenden} alt="" />

                                <div className="p-5">

                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Reparatur Einsenden</h5>

                                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Bequem und schnell: Schicken Sie uns Ihr defektes Handy per Post und wir kümmern uns um die Reparatur. Füllen Sie einfach das Formular am Ende aus und wir senden Ihnen alle weiteren Informationen zu</p>
                                    <button onClick={einsenden} className="inline-flex md:absolute bottom-5 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Einsenden
                                        <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div className="max-w-sm bg-white border relative border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                <img className="rounded-t-lg w-full" src={VorOrt} alt="" />

                                <div className="p-5">

                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Vor Ort reparieren lassen</h5>

                                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Besuchen Sie uns in unserem Shop und lassen Sie Ihr defektes Handy vor Ort reparieren. Unsere erfahrenen Techniker stehen Ihnen gerne zur Verfügung und beraten Sie individuell. Vereinbaren Sie einfach einen Termin oder kommen Sie spontan vorbei.</p>
                                    <button onClick={vorOrt} className="inline-flex md:absolute bottom-5 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Vor Ort
                                        <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {searchParams.get("kategorie") !== kategorieZuordnung.Mac.toString() &&
                            <div className={`md:min-h-[80vh] pt-5 flex w-full items-stretch ${stepCounter === step.Abschluss ? "animate-fade-in" : "hidden"}`}>
                                <div className='flex flex-col  md:min-h-[50vh] md:flex-row w-full mx-auto '>
                                    <div className='  bg-primary items-center align-middle flex w-full  md:w-1/2'>
                                        <div className='flex w-full  md:w-3/4 mx-auto gap-2 flex-wrap justify-center '>
                                            <div className='w-full '>
                                                <p className="text-3xl font-bold text-white m-4 sm:m-0 sm:mb-3  text-center">Kontaktformular</p>
                                                <form ref={formRef} className='' onSubmit={handleSubmit}>
                                                    <div className='w-11/12 mx-auto'>
                                                        <h2 id="accordion-example-heading-1">
                                                            <span className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 bg-gray-100" >
                                                                <span className='mx-auto'>Deine Informationen</span>
                                                            </span>

                                                        </h2>
                                                        <div id="accordion-example-body-1" className="hidden" aria-labelledby="accordion-example-heading-1">
                                                            <div className="p-5 border border-b-0 bg-secondary border-gray-200 dark:border-gray-700 dark:bg-gray-900 grid grid-cols-2 gap-x-5 gap-y-3">
                                                                <input ref={vornameRef1} name='Vorname1' type='text' required placeholder='Vorname' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <input ref={nachnameRef1} name='Nachname1' type='text' required placeholder='Nachname' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <input ref={emailRef1} name='Email1' type='email' pattern="^[^@\s]+@([^@\s]+\.)+[^@\s]+$" required placeholder="you@example.com" className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <input ref={telefonnummerRef1} name='Telefonnummer' type='tel' required placeholder='Telefonnummer' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <span id="infoNextButtonEinsenden" className={`bg-primary text-white font-semibold cursor-pointer p-2 text-center rounded-md ${ort !== "Einsenden" && "hidden"}`} style={{ gridColumn: "span 2" }}>Weiter</span>
                                                                <span id="infoNextButton" className={`bg-primary text-white font-semibold cursor-pointer p-2 text-center rounded-md ${ort === "Einsenden" && "hidden"}`} style={{ gridColumn: "span 2" }}>Weiter</span>
                                                            </div>
                                                        </div>

                                                        <h2 id="accordion-example-heading-2" className={`${ort !== "Einsenden" && "hidden"}`}>
                                                            <span className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 bg-gray-100" aria-expanded="false" aria-controls="accordion-example-body-2">
                                                                <span className='mx-auto'>Adresse</span>
                                                            </span>
                                                        </h2>
                                                        <div id="accordion-example-body-2" aria-labelledby="accordion-example-heading-2">
                                                            <div className={`p-5 border border-b-0 border-gray-200 bg-secondary dark:border-gray-700 grid grid-cols-2 gap-x-5 gap-y-3 ${ort !== "Einsenden" && "hidden"}`}>
                                                                {ort === "Einsenden" && <input ref={straßeRef1} required name="Straße1" type='text' placeholder='Straße' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' style={{ gridColumn: "span 2" }} />}
                                                                {ort === "Einsenden" && <input ref={hausnummerRef1} required name="Hausnummer1" type='text' placeholder='Hausnummer' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />}
                                                                {ort === "Einsenden" && <input ref={plzRef1} required name="PLZ1" type='text' placeholder='PLZ' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />}
                                                                {ort === "Einsenden" && <input ref={ortRef1} required name="Ort1" type='text' placeholder='Ort' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />}
                                                                <span id="adresseBackButton" className='cursor-pointer p-2 text-center rounded-md bg-red-800 text-white font-semibold' >Zurück</span>
                                                                <span id="adresseNextButton" className='bg-primary text-white cursor-pointer p-2 text-center rounded-md font-semibold' style={{ gridColumn: "span 2" }}>Weiter</span>

                                                            </div>
                                                        </div>

                                                        <h2 id="accordion-example-heading-3" className={`${ort === "Einsenden" && "hidden"}`}>
                                                            <span className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 bg-gray-100" aria-expanded="false" aria-controls="accordion-example-body-3">
                                                                <span className='mx-auto'>Termin</span>
                                                            </span>
                                                        </h2>
                                                        <div id="accordion-example-body-3" className={`${ort === "Einsenden" && "hidden"}`} aria-labelledby="accordion-example-heading-3">
                                                            <div className={`p-5 border border-b-0 bg-secondary border-gray-200 dark:border-gray-700 dark:bg-gray-900 grid grid-cols-2 gap-x-5 gap-y-3${ort === "Einsenden" && "hidden"}`}>
                                                                <div style={{ gridColumn: "span 2" }} className='mx-auto mb-5'>
                                                                    <DatePicker
                                                                        timeFormat="HH:mm"
                                                                        dateFormat="dd.MM.yyyy HH:mm"
                                                                        selected={startDate}
                                                                        onChange={(date: Date) => {
                                                                            const dateChanged = date.getDate() !== selectedDay;
                                                                            if (dateChanged) {
                                                                                date.setHours(10);
                                                                                date.setMinutes(0);
                                                                                setSelectedDay(date.getDate());
                                                                                setHasTimeSelected(false);
                                                                            } else if (date.getHours() !== 0 || date.getMinutes() !== 0) {
                                                                                setHasTimeSelected(true);
                                                                            }
                                                                            setStartDate(date);
                                                                        }}
                                                                        minDate={today}
                                                                        maxDate={nextYear}
                                                                        minTime={isToday ? (now.getHours() >= 17 ? closingTime : (now.getHours() < 10 ? openingTime : now)) : openingTime}
                                                                        maxTime={isToday && now.getHours() < 17 ? closingTime : endOfDay}
                                                                        filterDate={(date: Date) => {
                                                                            const dayOfWeek = date.getDay();
                                                                            return dayOfWeek >= 1 && dayOfWeek <= 5; // only allow Monday to Friday
                                                                        }}
                                                                        timeIntervals={30}
                                                                        showTimeSelect
                                                                        placeholderText="Datum und Uhrzeit"
                                                                        locale="de"
                                                                        inline
                                                                        excludeTimes={nonAvailableDates}
                                                                    />
                                                                    <span id="terminToInformationBackButton" className='cursor-pointer p-2 text-center rounded-md bg-red-800 text-white font-semibold' >Zurück</span>
                                                                </div>
                                                                <span id="terminNextButton" className={`bg-primary text-white cursor-pointer p-2 text-center rounded-md font-semibold ${!hasTimeSelected && "hidden"}`} style={{ gridColumn: "span 2" }}
                                                                >Weiter</span>

                                                            </div>
                                                        </div>

                                                        <h2 id="accordion-example-heading-4">
                                                            <span className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 bg-gray-100" aria-expanded="false" aria-controls="accordion-example-body-4">
                                                                <span className='mx-auto'>{ort === "Einsenden" ? "Gerät Informationen und Zahlungsart" : "Informationen"}</span>
                                                            </span>
                                                        </h2>
                                                        <div id="accordion-example-body-4" className="hidden" aria-labelledby="accordion-example-heading-4">
                                                            <div className="p-5 border border-t-0 bg-secondary border-gray-200 dark:border-gray-700 grid grid-cols-2 gap-x-5 gap-y-3">
                                                                <div className=' relative ' style={{ gridColumn: "span 2" }}>
                                                                    {ort === "Einsenden" && <input required ref={passwortRef1} name="Passwort1" type='text' placeholder='Passwort' className={`${ort !== "Einsenden" && "hidden"} w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500`} onInput={(value) => setCodeValue(value)} value={code} />}
                                                                    <p onClick={handlePatternClick} className={`${ort !== "Einsenden" && "hidden"} cursor-pointer text-blue-700 underline`}>Muster?</p>
                                                                    {showPattern && (
                                                                        <div ref={patternRef} className={`PatternLock bg-white rounded-md w-fit shadow-md  ${showPattern ? "block" : "hidden"}`}>
                                                                            <LockPattern onPatternChange={handlePatternChange} />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <textarea name="Schadenbeschreibung1" placeholder='Schadensbeschreibung' style={{ gridColumn: (ort !== "Einsenden" ? "span 2" : "") }} rows={3} className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500' />
                                                                <div className={`${ort !== "Einsenden" && "hidden"} flex flex-col gap-2 `} >
                                                                    <select name="Zahlungsart" id="Zahlungsart" onChange={(e) => handleZahlungChange(e.target.value)} className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500' placeholder='Zahlungsart' defaultValue="">
                                                                        <option value="" disabled>Zahlungsart</option>
                                                                        <option value="Vorzahlung">Vorzahlung</option>
                                                                        <option value="Nachnahme">Nachnahme(+7€)</option>
                                                                    </select>
                                                                    {ort === "Einsenden" && <input
                                                                        ref={imeiRef1}
                                                                        name="IMEI1"
                                                                        type="text" // Changed to text to use maxLength
                                                                        value={imei}
                                                                        onChange={handleImeiChange}
                                                                        placeholder='IMEI Nummer'
                                                                        className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500'
                                                                    />}
                                                                </div>
                                                                <span id="informationenToAdresse" className={`${ort !== "Einsenden" && "hidden"} cursor-pointer p-2 text-center rounded-md bg-red-800 text-white font-semibold`} >Zurück</span>
                                                                <span id="informationenToTermin" className={`${ort === "Einsenden" && "hidden"} cursor-pointer p-2 text-center rounded-md bg-red-800 text-white font-semibold`} >Zurück</span>
                                                                <button id="AnfrageSchickenButton" ref={submitButtonRef1} type='submit' className='bg-primary text-white cursor-pointer p-2 text-center rounded-md font-semibold' style={{ gridColumn: "span 2" }}>Anfrage schicken</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex pb-5 w-full  md:w-1/2 bg-secondary'>
                                        <div className='flex w-full p-5 md:w-1/2 mx-auto items-center align-middle  gap-2 flex-wrap md:justify-center '>
                                            <div className='flex flex-col w-full '>
                                                <p className='text-3xl font-bold text-black mb-3 text-center'>Zusammenfassung {modell} Reparaturen</p>

                                                {
                                                    selectedReparaturen.map((element: any, index) => (
                                                        <React.Fragment key={element + index}>
                                                            <div className='flex flex-row justify-between text-lg' key={index + "Reparatur"}>
                                                                <div className='flex flex-row'>
                                                                    <img src={checkBoxSvg} className='w-[1.75rem]' alt="Checkbox SVG" />
                                                                    <p className='font-semibold'> {element.reparaturName}</p>
                                                                </div>
                                                                <p className='font-semibold'> {element.aktionsPreis === 0 ? element.preis : element.aktionsPreis}€</p>
                                                            </div>
                                                        </React.Fragment>
                                                    ))
                                                }
                                                <div className=" border-t-2 border-primary flex justify-between text-lg font-bold w-full">
                                                    <div>Summe:</div>
                                                    <div>{summe}€</div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                        {searchParams.get("kategorie") === kategorieZuordnung.Mac.toString() &&
                            <div className={`md:min-h-[80vh] pt-5 flex w-full items-stretch ${stepCounter === step.Mac ? "animate-fade-in" : "hidden"}`}>
                                <div className='flex flex-col  md:min-h-[50vh] md:flex-row w-full mx-auto '>
                                    <div className='  bg-primary items-center align-middle flex w-full'>
                                        <div className='flex w-full  md:w-3/4 mx-auto gap-2 flex-wrap justify-center '>
                                            <div className='w-full '>
                                                <p className="text-3xl font-bold text-white m-4 sm:m-0 sm:mb-3  text-center">Kontaktformular</p>
                                                <form ref={formRef2} className='' onSubmit={handleSubmit}>
                                                    <div className={`w-11/12  xl:w-1/2 mx-auto ${(macAusgefüllt === false && ShowAnfrageSenden === false) ? "animate-fade-in" : "hidden"}`}>
                                                        <h2 id="accordion-example-heading-10">
                                                            <span className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 bg-gray-100" aria-expanded="false" aria-controls="accordion-example-body-40">
                                                                <span className='mx-auto'>Gerät Informationen und Zahlungsart</span>
                                                            </span>
                                                        </h2>
                                                        <div id="accordion-example-body-10" className="hidden" aria-labelledby="accordion-example-heading-10">
                                                            <div className="bg-secondary flex flex-col mx-auto items-center gap-2 flex-wrap md:flex-row justify-around pt-4">
                                                                <span className='cursor-pointer w-11/12 md:w-fit' onClick={() => handleMacButtonClick('Macbook')} >
                                                                    <div className={getMacButtonClass('Macbook') + " flex flex-col"}>Macbook<img className='w-1/2 md:w-32' src={require('./media/MacPro.png')} alt="MacbookPro Foto"></img></div>
                                                                </span>
                                                                <span className='cursor-pointer w-11/12 md:w-fit' onClick={() => handleMacButtonClick('Macbook Air')}>
                                                                    <div className={getMacButtonClass('Macbook Air') + " flex flex-col"}>Macbook Air<img className='w-1/2 md:w-32' src={require('./media/MacAir.png')} alt="MacbookAirFoto"></img></div>
                                                                </span>
                                                                <span className='cursor-pointer w-11/12 md:w-fit' onClick={() => handleMacButtonClick('Macbook Pro')}>
                                                                    <div className={getMacButtonClass('Macbook Pro') + " flex flex-col"}>Macbook Pro<img className='w-1/2 md:w-32' src={require('./media/MacPro.png')} alt="MacbookPro Foto"></img></div>
                                                                </span>
                                                            </div>
                                                            <div className="p-5 border border-t-0 bg-secondary border-gray-200 dark:border-gray-700 grid grid-cols-2 gap-x-5 gap-y-3">
                                                                <div className=' relative ' style={{ gridColumn: "span 2" }}>
                                                                    <input required ref={passwortRef2} name="Passwort2" type='text' placeholder='Passwort' className={`hidden w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500`} onInput={(value) => setCodeValue(value)} value={"a"} />
                                                                </div>
                                                                <div className={` flex flex-col gap-2 `} >
                                                                    <div style={{ position: 'relative' }}>

                                                                        <label
                                                                            htmlFor="SerienNumemrMac"
                                                                        >
                                                                            ModellNummer
                                                                        </label>
                                                                        <input
                                                                            required
                                                                            ref={serienNummerMacRef2}
                                                                            value={macSeriennummerInputValue}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                if (value.length <= 4) {
                                                                                    setmacSeriennummerInputValue(value);
                                                                                }

                                                                            }}
                                                                            placeholder=''
                                                                            id="SerienNummerMac2"
                                                                            name="SerienNummer"
                                                                            type='number'
                                                                            className='w-full pl-5 border-2  border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500'
                                                                        />
                                                                        <span

                                                                            style={{
                                                                                position: 'absolute',
                                                                                left: '10px',
                                                                                top: '68%',
                                                                                transform: 'translateY(-50%)',
                                                                                pointerEvents: 'none',
                                                                                color: "Black"
                                                                            }}
                                                                        >
                                                                            A
                                                                        </span>
                                                                        {macSeriennummerInputValue === '' &&
                                                                            <>

                                                                                <span

                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        left: '22px',
                                                                                        top: '68%',
                                                                                        transform: 'translateY(-50%)',
                                                                                        pointerEvents: 'none',
                                                                                        color: "grey"
                                                                                    }}
                                                                                >
                                                                                    0000
                                                                                </span></>
                                                                        }

                                                                    </div>
                                                                    <div >


                                                                        <label htmlFor="MacProblem" className="block mt-2 text-sm font-medium text-gray-900 dark:text-white">Art des Problems</label>
                                                                        <select id="MacProblem" name="MacProblem" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                            <option selected>Wähle ein Problem aus</option>
                                                                            <option value="Akku">Akku</option>
                                                                            <option value="Bildschirm">Bildschirm</option>
                                                                            <option value="Lädt nicht">Lädt nicht</option>
                                                                            <option value="Lädt nicht">Wasserschaden</option>
                                                                            <option value="Lädt nicht">Lautsprecher</option>
                                                                            <option value="Lädt nicht">Software Problem</option>
                                                                            <option value="Schaltet ein aber reagiert nicht">Schaltet ein aber reagiert nicht</option>
                                                                            <option value="Anderes Problem">Anderes Problem</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <textarea name="Schadenbeschreibung2" placeholder='Schadensbeschreibung' rows={3} className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500' />

                                                                <span id="geraetInfoNext0" className={`bg-primary text-white font-semibold cursor-pointer p-2 text-center rounded-md`} style={{ gridColumn: "span 2" }}>Weiter</span>


                                                            </div>
                                                        </div>
                                                        <h2 id="accordion-example-heading-20">
                                                            <span className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 bg-gray-100" >
                                                                <span className='mx-auto'>Deine Informationen</span>
                                                            </span>

                                                        </h2>
                                                        <div id="accordion-example-body-20" aria-labelledby="accordion-example-heading-20">
                                                            <div className="p-5 border border-b-0 bg-secondary border-gray-200 dark:border-gray-700 dark:bg-gray-900 grid grid-cols-2 gap-x-5 gap-y-3">
                                                                <input ref={vornameRef2} name='Vorname2' type='text' required placeholder='Vorname' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <input ref={nachnameRef2} name='Nachname2' type='text' required placeholder='Nachname' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <input ref={emailRef2} name='Email2' type='email' pattern="^[^@\s]+@([^@\s]+\.)+[^@\s]+$" required placeholder="you@example.com" className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <input ref={telefonnummerRef2} name='Telefonnummer2' type='tel' required placeholder='Telefonnummer' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <span id="infoBack0" className='cursor-pointer p-2 text-center rounded-md bg-red-800 text-white font-semibold' >Zurück</span>
                                                                <span id="infoToAdresse0" className={`bg-primary text-white font-semibold cursor-pointer p-2 text-center rounded-md`} style={{ gridColumn: "span 2" }}>Weiter</span>

                                                            </div>
                                                        </div>

                                                        <h2 id="accordion-example-heading-30">
                                                            <span className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 bg-gray-100" aria-expanded="false" aria-controls="accordion-example-body-20">
                                                                <span className='mx-auto'>Adresse</span>
                                                            </span>
                                                        </h2>
                                                        <div id="accordion-example-body-30" aria-labelledby="accordion-example-heading-30">
                                                            <div className={`p-5 border border-b-0 border-gray-200 bg-secondary dark:border-gray-700 grid grid-cols-2 gap-x-5 gap-y-3 `}>
                                                                <input ref={straßeRef2} required name="Straße2" type='text' placeholder='Straße' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' style={{ gridColumn: "span 2" }} />
                                                                <input ref={hausnummerRef2} required name="Hausnummer2" type='text' placeholder='Hausnummer' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <input ref={plzRef2} required name="PLZ2" type='text' placeholder='PLZ' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <input ref={ortRef2} required name="Ort2" type='text' placeholder='Ort' className='w-full border-2 border-gray-300 p-2 rounded outline-none focus:border-blue-500 required:border-red-500 valid:border-green-500' />
                                                                <span id="adresseBack0" className='cursor-pointer p-2 text-center rounded-md bg-red-800 text-white font-semibold' >Zurück</span>
                                                                <span id="infoToTermin0" className={`bg-primary text-white font-semibold cursor-pointer p-2 text-center rounded-md`} style={{ gridColumn: "span 2" }} onClick={() => setmacAusgefüllt(true)}>Weiter</span>
                                                                <button id="AnfrageSchickenButton21" ref={submitButtonRef21} type='submit' className='bg-lime-600 text-white cursor-pointer p-2 text-center rounded-md font-semibold hidden' style={{ gridColumn: "span 2" }} >Anfrage schicken</button>

                                                            </div>
                                                        </div>




                                                    </div>
                                                    <div className={`w-fit mx-auto my-10 flex flex-col md:flex-row min-h-[55vh] gap-10 ${macAusgefüllt === true ? "animate-fade-in" : "hidden"}`}>
                                                        <span id="terminBack0" className="w-full bg-red-800 text-white font-semibold cursor-pointer p-2 text-center max-h-[2.5rem] rounded-md" onClick={() => setmacAusgefüllt(false)}>Zurück zu Informationen</span>
                                                        <div className={`max-w-sm bg-white border relative border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}>

                                                            <img className="rounded-t-lg w-full" src={Zusenden} alt="" />

                                                            <div className="p-5">

                                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Reparatur Einsenden</h5>

                                                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Bequem und schnell: Schicken Sie uns Ihr defektes Handy per Post und wir kümmern uns um die Reparatur. Füllen Sie einfach das Formular am Ende aus und wir senden Ihnen alle weiteren Informationen zu</p>
                                                                <span onClick={() => { setShowAnfrageSenden(true); setmacAusgefüllt(false); setOrt("Einsenden") }} className="cursor-pointer inline-flex md:absolute bottom-5 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                    Einsenden
                                                                    <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="max-w-sm bg-white border relative border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                                            <img className="rounded-t-lg w-full" src={VorOrt} alt="" />

                                                            <div className="p-5">

                                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Vor Ort reparieren lassen</h5>

                                                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Besuchen Sie uns in unserem Shop und lassen Sie Ihr defektes Handy vor Ort reparieren. Unsere erfahrenen Techniker stehen Ihnen gerne zur Verfügung und beraten Sie individuell. Vereinbaren Sie einfach einen Termin oder kommen Sie spontan vorbei.</p>
                                                                <span onClick={() => { setShowAnfrageSenden(true); setmacAusgefüllt(false); setOrt("Vor Ort") }} className="cursor-pointer inline-flex md:absolute bottom-5 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                    Vor Ort
                                                                    <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`w-11/12  xl:w-1/2 my-10 rounded-md mx-auto flex flex-col items-center justify-center min-h-[35vh] gap-10 bg-gray-100 border-gray-200 ${ShowAnfrageSenden === true ? "animate-fade-in" : "hidden"}`}>
                                                        <span className="w-fit mx-auto bg-red-800 text-white font-semibold cursor-pointer p-2 text-center max-h-[2.5rem] rounded-md" onClick={() => { setShowAnfrageSenden(false); setmacAusgefüllt(true); }}>Zurück zu Ort Auswahl</span>
                                                        <p className="text-lg md:text-2xl mx-auto">Sie haben {ort} ausgewählt.</p>
                                                        <div className={`${ort != "Vor Ort" && "hidden"}`}>
                                                            <h2 id="accordion-example-heading-40" >
                                                                <span className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 bg-gray-100" aria-expanded="false" aria-controls="accordion-example-body-30">
                                                                    <span className='mx-auto'>Termin</span>
                                                                </span>
                                                            </h2>
                                                            <div id="accordion-example-body-40" aria-labelledby="accordion-example-heading-40">
                                                                <div className={`p-5 border border-b-0 bg-secondary border-gray-200 dark:border-gray-700 dark:bg-gray-900 grid grid-cols-2 gap-x-5 gap-y-3`}>
                                                                    <div style={{ gridColumn: "span 2" }} className='mx-auto mb-5'>
                                                                        <DatePicker
                                                                            timeFormat="HH:mm"
                                                                            dateFormat="dd.MM.yyyy HH:mm"
                                                                            selected={startDate}
                                                                            onChange={
                                                                                (date: Date) => {
                                                                                    const dateChanged = date.getDate() !== selectedDay;
                                                                                    if (dateChanged) {
                                                                                        date.setHours(10);
                                                                                        date.setMinutes(0);
                                                                                        setSelectedDay(date.getDate());
                                                                                        setHasTimeSelected(false);
                                                                                    } else if (date.getHours() !== 0 || date.getMinutes() !== 0) {
                                                                                        setHasTimeSelected(true);
                                                                                    }
                                                                                    setStartDate(date);
                                                                                }}
                                                                            minDate={today}
                                                                            maxDate={nextYear}
                                                                            minTime={isToday ? (now.getHours() >= 17 ? closingTime : (now.getHours() < 10 ? openingTime : now)) : openingTime}
                                                                            maxTime={isToday && now.getHours() < 17 ? closingTime : endOfDay}
                                                                            filterDate={(date: Date) => {
                                                                                const dayOfWeek = date.getDay();
                                                                                return dayOfWeek >= 1 && dayOfWeek <= 5; // only allow Monday to Friday
                                                                            }}
                                                                            timeIntervals={30}
                                                                            showTimeSelect
                                                                            placeholderText="Datum und Uhrzeit"
                                                                            locale="de"
                                                                            inline
                                                                            excludeTimes={nonAvailableDates}
                                                                        />
                                                                    </div>
                                                                    <span  className='cursor-pointer p-2 text-center rounded-md bg-red-800 text-white font-semibold hidden' >Zurück</span>
                                                                    <span className={`bg-primary text-white cursor-pointer p-2 text-center rounded-md font-semibold hidden`} style={{ gridColumn: "span 2" }} >Weiter</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="AnfrageSchickenButton22" ref={submitButtonRef22} type='submit' className={`bg-primary text-white cursor-pointer p-3 text-center rounded-md font-semibold w-fit mx-auto ${!((hasTimeSelected || ort ==="Einsenden") && MacFertigAusgefüllt && selectedMac != "") && "hidden"}`} style={{ gridColumn: "span 2" }}>Anfrage schicken</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </>}


            </div>
            <div className={`bg-gray-900 bg-opacity-80 backdrop-blur-xl dark:bg-opacity-80 fixed inset-0 z-40 w-full h-full ${openModal ? "" : "hidden"}`} onClick={() => setOpenModal(false)}>

            </div>
            <div id="default-modal" aria-hidden="true" tabIndex={-1} className={`fixed  z-50 ${openModal ? "" : "hidden"} w-fit h-fit m-auto p-4 overflow-x-visible overflow-y-visible top-0 left-0 right-0 bottom-0 md:inset-0  max-h-full`}>
                <div className={`flex flex-col gap-6 md:gap-0 mt-5 md:flex-row items-center h-[30vh] `}>

                    <div className='bg-primary rounded-xl shadow-md grid grid-flow-row md:grid-flow-col col-template-3 w-fit min-w-[40%] h-fit p-10 mx-auto gap-5'>
                        {kategorie !== kategorieZuordnung.iPhone.toString() && kategorie !== kategorieZuordnung.iPad.toString() && kategorie !== kategorieZuordnung.iWatch.toString() && <>
                            <div className={`w-full flex flex-wrap items-center`}>
                                <div className='mx-auto w-fit'>
                                    <Select key={markeSelectionChange} onValueChange={(value: any) => { setMarkeSelectionChange(value); setSerieChange(""); setModellChange(""); }} defaultValue=''>
                                        <SelectTrigger className="w-[180px] text-white font-semibold">
                                            {markeSelectionChange === "" ? "Wähle deine Marke" : markeSelectionChange}
                                        </SelectTrigger>
                                        <SelectContent defaultValue='' >
                                            <SelectGroup className='max-h-72 overflow-auto'>

                                                {marke.map((element: any, index) => {
                                                    return (

                                                        <SelectItem value={element.item2} key={element.item1 + index}>{element.item2}</SelectItem>
                                                    );
                                                })}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>

                            <div className={`flex flex-wrap items-center w-full`}>

                                <div className={`mx-auto w-fit ${serieChange === "leer" && "display-none"}`} >
                                    <Select key={markeSelectionChange} onValueChange={(value: any) => { setSerieChange(value); setModellChange(""); }} defaultValue=''>
                                        <SelectTrigger className="w-[180px]  text-white font-semibold">
                                            {serieChange === "" ? "Wähle die Serie" : serieChange}
                                        </SelectTrigger>
                                        <SelectContent defaultValue='' >

                                            <SelectGroup className='max-h-72 overflow-auto'>
                                                {
                                                    sources
                                                        .filter((element: modellGroup) => element.markenName === markeSelectionChange)
                                                        .reduce((uniqueItems: string[], element: modellGroup) => {
                                                            if (!uniqueItems.includes(element.unterkategorieName)) {
                                                                uniqueItems.push(element.unterkategorieName);
                                                                return uniqueItems;
                                                            }
                                                            return uniqueItems;
                                                        }, [])
                                                        .map((unterkategorieName: string, i: number) => (
                                                            <SelectItem value={unterkategorieName} key={unterkategorieName + i}>{unterkategorieName}</SelectItem>
                                                        ))
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </div>

                            </div>
                        </>}

                        <div className={`flex flex-wrap items-center w-full`}>

                            <div className='mx-auto w-fit ' id="TESTMODAL">
                                <Select key={modellChange} onValueChange={(value: any) => setModellChanges(value)} defaultValue=''>
                                    <SelectTrigger className="w-[180px]  text-white font-semibold">
                                        {modellChange === "" ? "Wähle dein Modell" : modellChange}
                                    </SelectTrigger>
                                    <SelectContent defaultValue='' >
                                        <SelectGroup className=' border-black-1 max-h-72 overflow-auto'>
                                            {sources
                                                .filter((element: modellGroup) => (element.unterkategorieName === serieChange && element.markenName === markeSelectionChange))
                                                .map((element: modellGroup, i: number) => (
                                                    <SelectItem value={element.modellName} key={element.markenName + i}>{element.modellName}</SelectItem>)
                                                )}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </div>

                        </div>
                        <button id="modalSearch" className={`w-full h-full ${modellChange === "" && "pointer-events-none opacity-25"}`} onClick={modalSearchClicked}>
                            <div className={` w-14 h-14 flex align-middle p-4 items-center bg-amber-500 opacity-75 rounded-full ${modellChange === "" && "opacity-25"}`}>

                                <img src={magnifying} alt='magnifying' className=' w-8 mx-auto' />

                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
export default RepInfos;



