import Main from './Main';
import Navbar from './komponenten/NavigationsMenue';
import Footer from './komponenten/Footer';
import CookieBanner from './komponenten/CookieBanner';
export default function App() {
  return (
    <>  
        <Navbar />
        <CookieBanner />
        <Main />
        <Footer />
    </>
  )
}