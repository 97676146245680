import ansatz from './media/ansatz.webp';
import prinzipien from './media/prinzipien.webp';
import prozess from './media/prozess.webp';

const UeberUns = () => {

    return (
        <div className='lg:flex items-center py-4'>
            <div className='w-fit mx-auto bg-white p-3 md:p-10 rounded-3xl'>
                <div className='w-fit  lg:px-0 px-4 flex flex-col content-center flex-wrap min-h-screen gap-10 text-gray-500'>
                    <div className='w-full flex flex-col content-center flex-wrap items-center'>
                        <h1 className='text-4xl md:text-5xl font-bold w-fit'>Über uns</h1>
                        <div>
                            <p className='text-xl font-light w-fit text-center'>Wir sind Ihr ultimativer Ansprechpartner für alles was mit Mobilgeräten zu tun hat.</p>
                        </div>
                    </div>
                    <div >
                        <div className='px-3 mx-auto max-w-lg lg:max-w-6xl  overflow-x-hidden flex flex-col' >

                            <div className='lg:flex py-4 lg:py-0'>
                                <div className='flex-none lg:w-1/2 pr-4 pt-8 text-justify'>
                                    <h4 className='font-bold text-2xl lg:text-3xl   '>Unsere Mission</h4>
                                    <p className='text-sm'>Unsere Mission ist es, Ihr digitales Leben zu vereinfachen, indem wir herausragende Reparaturdienste für eine Vielzahl von Geräten anbieten. Wir verstehen, wie unverzichtbar Handys, Tablets und MacBooks in unserem Alltag sind, und setzen alles daran, Ihre technischen Herausforderungen zu bewältigen.</p>
                                    <p className='text-sm'><strong>Über 10 Jahre Erfahrung:</strong> Mit mehr als einem Jahrzehnt Erfahrung in der Branche haben wir ein tiefgehendes Verständnis für die Entwicklung und Reparatur von mobilen Geräten.</p>
                                    <p className='text-sm'><strong>Erfahrene Techniker:</strong> Unser Team besteht aus hochqualifizierten Technikern mit langjähriger Erfahrung in der Branche.</p>
                                    <p className='text-sm'><strong>Qualitätsorientierte Reparaturen:</strong> Bei Mac and Phone Doc verwenden wir ausschließlich hochwertige Ersatzteile, um sicherzustellen, dass jede Reparatur langlebig und zuverlässig ist.</p>
                                    <p className='text-sm'><strong>Kundenservice im Fokus:</strong> Unser engagiertes Kundenservice-Team ist bereit, all Ihre Fragen zu beantworten und Ihnen den bestmäglichen Service zu bieten. Wir hören aufmerksam zu, verstehen Ihre Bedürfnisse und bieten maßgeschneiderte Löcsungen.</p>
                                    <p className='text-sm'><strong>Nachhaltigkeit und Umweltschutz:</strong> Bei Mac and Phone Doc sind wir uns der Umweltauswirkungen bewusst. Wir glauben daran, dass Reparaturen nicht nur dazu dienen, Ihre Geräte wieder funktionsfähig zu machen, sondern auch einen wichtigen Beitrag zum Umweltschutz leisten. Indem wir defekte Geräte reparieren und ihre Lebensdauer verlängern, helfen wir aktiv, Elektromüll zu reduzieren und die nachhaltige Nutzung von Ressourcen zu fördern.</p>
                                </div>
                                <div className='flex-none lg:w-1/2'>
                                    <img className='lg:h-96 mx-auto rounded-2xl' src={prinzipien} alt="Foto von https://unsplash.com/de/@rmrdnl?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText Daniel Romero https://unsplash.com/de/s/fotos/ansatz-handy?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText Unsplash" />
                                </div>
                            </div>

                            <div className='lg:flex flex-row-reverse  py-4 lg:py-0'>
                                <div className='flex-none lg:w-1/2  pt-8 text-justify lg:pl-4' >
                                    <h4 className='font-bold  text-3xl lg:text-3xl   '>Unsere Dienstleistungen</h4>
                                    <p className='text-sm'><strong>Handyreparaturen: </strong>Egal ob Ihr Handy unter einem lästigen Displaybruch, Batterieproblemen oder komplexen Softwarefehlern leidet - bei Mac and Phone Doc sind wir darauf spezialisiert, Ihr Smartphone mit Präzision und Geschwindigkeit zu reparieren. Unsere Techniker verstehen die Vielfalt der auf dem Markt vorhandenen Modelle und Marken, und mit unserer langjährigen Erfahrung stellen wir sicher, dass Ihr Handy nach der Reparatur wieder in Bestform ist.</p>
                                    <p className='text-sm'><strong>Tablet-Reparaturen: </strong>Von defekten Bildschirmen bis hin zu Ladeproblemen bieten wir umfassende Tablet-Reparaturdienste an. Unser erfahrenes Team kennt sich mit verschiedenen Tablet-Marken aus und verwendet nur hochwertige Ersatzteile, um die Funktionalität Ihres Tablets wiederherzustellen.</p>
                                    <p className='text-sm'><strong>MacBook-Reparaturen: </strong>Unsere Experten sind darauf spezialisiert, MacBook-Probleme zu diagnostizieren und zu beheben. Ob es sich um Hardwaredefekte, Softwareprobleme oder Leistungsverbesserungen handelt - wir haben die F¥öhigkeiten und das Fachwissen, um Ihr MacBook wieder auf Höchstleistung zu bringen.</p>
                                </div>
                                <div className="flex-none lg:w-1/2   " >
                                    <img className='lg:h-96 mx-auto rounded-2xl' src={ansatz} alt="Foto von https://unsplash.com/de/@homajob?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText Scott Graham auf https://unsplash.com/de/s/fotos/buisness-meeting?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText Unsplash" />
                                </div>
                            </div>

                            <div className='lg:flex  py-4 lg:py-0'>
                                <div className='flex-none lg:w-1/2 pr-4 pt-8 text-justify'>
                                    <h4 className='font-bold text-3xl lg:text-3xl  '>Besuchen Sie uns!</h4>
                                    <p className='text-md'>Kommen Sie noch heute zu Mac and Phone Doc und erleben Sie, wie wir Ihre Technologie zum Leben erwecken. Mit über 10 Jahren Erfahrung sind wir Ihr verlässlicher Partner für alle Reparaturbedürfnisse.</p>
                                    <p><strong>Mac and Phone Doc - We fix, you smile!</strong></p>
                                </div>
                                <div className="flex-none lg:w-1/2 ">
                                    <img className='lg:h-96 mx-auto rounded-2xl' src={prozess} alt="Foto von https://unsplash.com/@alvarordesign?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText Alvaro Reyes auf https://unsplash.com/de/s/fotos/prozess?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText Unsplash" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default UeberUns;