import React, { useState, useEffect } from "react";
import { connection } from '../constants/global';
import { Separator } from "../components/ui/separator"
interface Review {
  author_url: string | undefined;
  author_name: string;
  rating: number;
  text: string;
}

const ReviewsComponent: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>([]);

  useEffect(() => {
    const url = `${connection}/MacAndPhoneDoc/GetReviews`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setReviews(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (

    <div className="md:w-3/4 lg:w-2/3  mx-auto text-black">
      <h2 id="panel3Reviews__Header" className="w-fit mx-auto text-4xl font-bold text-white text-center">Die besten Bewertungen der Stadt</h2>
      <div className="flex flex-col md:flex-row gap-10 mt-10" >
        {reviews.map((review, index) => (

          <div className="flex flex-col bg-white px-6 py-3 rounded-md shadow-lg w-2/3 mx-auto lg:w-1/3" id={index + "container"} key={index + "container"}>
            <div style={{ display: "flex", flexDirection: "row" }} key={index + "containerInhalt"}>
              <h3 className=" font-medium" key={index + "containerHeader"}>{review.author_name}</h3>
              <div className="flex items-center space-x-1 ml-auto" key={index + "containerReview"}>
                {
                  (() => {
                    const ratingElements = [];
                    let rating = review.rating;
                    for (let i = 0; i < 5; i++) {
                      if (rating >= 1) {
                          ratingElements.push(
                              <svg key={index + "PlusStar" + i} className="w-4 h-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                  <path id={index + "PlusStarPath" + i} d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                          );
                      } else {
                          ratingElements.push(
                              <svg key={index + "MinusStar" + i} className="w-4 h-4 text-gray-300 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                  <path id={index + "MinusStarPath" + i} d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                          );
                      }
                      rating--;
                  }

                    return ratingElements;
                  })()
                }
              </div>
            </div>
            <Separator className="mt-3 h-0.5 bg-primary" />
            <span className="text-sm pt-4">
              {review.text}
            </span><br />
            <a target="_blank" href={review.author_url} rel="noreferrer" className="mt-auto bg-primary shadow-md rounded-lg p-1 text-white font-bold text-center">Zur Bewertung</a>
          </div>
        ))}
      </div>

    </div>
  );
};

export default ReviewsComponent;
