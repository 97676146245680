import React, { useState } from 'react';

const FAQ = () => {
  // State to keep track of the open FAQ item
  const [openFaq, setOpenFaq] = useState(null);

  // Toggles the FAQ item's open state
  const toggleFaq = (index:any) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  // List of FAQ questions and answers
  const faqs = [
    {
      question: 'Welche Arten von Geräten repariert ihr?',
      answer: 'Wir sind spezialisiert auf die Reparatur von iPhones, iPads und MacBook sowie Handys, Tablets und Laptops verschiedener Marken und Modelle.'
    },
    {
      question: 'Wie lange dauert eine typische Reparatur?',
      answer: 'Die Dauer variiert je nach Art der Reparatur. Kleinere Reparaturen können wir innerhalb von 1-2 Stunden erledigen, während komplexere Probleme möglicherweise mehr Zeit in Anspruch nehmen.'
    },
    {
      question: 'Verwendet ihr Originalersatzteile?',
      answer: 'Ja, wir verwenden ausschließlich hochwertige Originalersatzteile oder qualitativ gleichwertige Komponenten, um die bestmögliche Leistung und Zuverlässigkeit sicherzustellen.'
    },
    {
      question: 'Bietet ihr Garantien für eure Reparaturen?',
      answer: 'Ja, wir bieten für alle von uns durchgeführten Reparaturen und eingebauten Ersatzteile Sechs Monate Garantie. Bitte kontaktieren Sie uns für weitere Informationen.'
    },
    {
      question: 'Wie kann ich einen Termin vereinbaren?',
      answer: 'Sie können direkt auf unserer Website einen Termin ausmachen oder uns telefonisch kontaktieren.'
    },
    {
      question: 'Welche Zahlungsmethoden akzeptiert ihr?',
      answer: 'Wir akzeptieren verschiedene Zahlungsmethoden, darunter Bargeld, Kreditkarten und andere elektronische Zahlungsoptionen.'
    },
    {
      question: 'Kann ich mein Gerät auch einsenden?',
      answer: 'Ja, Sie können Ihr Gerät einsenden oder einen Termin bei uns im Shop ausmachen.'
    },
    {
      question: 'Was mache ich, wenn ich mit der Reparatur nicht zufrieden bin?',
      answer: 'Ihre Zufriedenheit steht für uns an erster Stelle. Kontaktieren Sie uns bitte sofort, damit wir eine Lösung finden können.'
    },
    {
      question: 'Was sind Ihre Öffnungszeiten?',
      answer: 'Wir haben von Montag bis Freitag von 10:00 Uhr bis 18:00 Uhr durchgehend für Sie geöffnet.'
    },
  ];

  return (
    <div className='bg-primary p-10'>
      <div className=' w-full mx-auto bg-white md:p-10 rounded-3xl'>
        <div className='w-full lg:px-0  flex flex-col content-center flex-wrap min-h-screen gap-10 text-gray-500'>
          <div className='w-full flex flex-col content-center flex-wrap items-center'>
            <h1 className='text-4xl md:text-5xl font-bold w-fit mb-5 px-10 sm:px-0 text-center'>Häufig gestellte Fragen</h1>

            <div className='w-full md:w-2/3'>
              {faqs.map((faq, index) => (
                <div key={index} className="faq-item border flex flex-col justify-between w-full">
                  <div className="faq-question cursor-pointer flex flex-row bg-slate-50 p-5 text-xl items-center w-full justify-between" onClick={() => toggleFaq(index)}>
                    <h3>{faq.question}</h3>
                    <span className='text-xl '>
                      {openFaq === index ? '-' : '+'}
                    </span>
                  </div>
                  {openFaq === index && (
                    <div className="answer p-5">
                      <p>{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
