import Logo from '../media/MacAndPhoneLogo.svg';
import WienSvg from '../media/WienSvg.webp';


function Footer() {
  return (
    <footer className="bg-secondary align-bottom z-30 relative">
      <div className="max-w-6xl mx-auto pb-10">
        <div className="md:flex w-full">
          <div className="px-3 md:w-[25%] md:pt-20 md:pb-6 py-4">
            <img  src={Logo} className="w-40" alt="Firmen Logo"></img>
            <p className="text-black my-4 text-sm">
              Jahrelange Erfahrung, geschultes Fachpersonal und Express Reparatur ohne Aufpreis.
            </p>
          </div>
          <div className="px-3 md:w-1/2 md:pt-20 md:pb-6 py-6">
            <h3 className="text-black font-semibold mb-4">Quick Links</h3>
            <ul className="text-sm font-medium md:flex flex-wrap">
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a className="flex-1" href="/">Home</a>
              </li>
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a className="flex-1" href="/UeberUns">Über uns</a>
              </li>

              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>

                <a href="/ReparaturInfos?kategorie=4" className="flex-1">
                  <span className="sr-only">Macbook / iMac</span>

                  Macbook / iMac Reparatur
                </a>
              </li>
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a href="/ReparaturInfos?kategorie=5" className="flex-1">
                  <span className="sr-only">iWatch</span>

                  iWatch Reparatur
                </a>
              </li>
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a href="/ReparaturInfos?kategorie=1" className="flex-1">
                  <span className="sr-only">iPhone</span>

                  iPhone Reparatur
                </a>
              </li>
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a href="/ReparaturInfos?kategorie=2" className="flex-1">
                  <span className="sr-only">iPad</span>

                  iPad Reparatur
                </a>
              </li>
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a href="/ReparaturInfos?kategorie=3" className="flex-1">
                  <span className="sr-only">Smartphone</span>

                  Smartphone Reparatur
                </a>
              </li>
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a href="https://www.reparaturbonus.at/" className="flex-1">
                  <span className="sr-only">Reparaturbonus</span>

                  Reparaturbonus
                </a>
              </li>
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a className="flex-1" href="/Impressum">Impressum</a>
              </li>
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a className="flex-1" href="/Datenschutz">Datenschutz</a>
              </li>
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a className="flex-1" href="/ReparaturBedingungen">Reparaturbedingungen</a>
              </li>
              <li className="py-1 md:w-1/2 text-black">
                <i className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[11px] inline-block">
                    <path fill="#9ca3af" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                </i>
                <a className="flex-1" href="/FAQ">FAQ</a>
              </li>
            </ul>
          </div>
          <div className="px-3 md:w-[25%] md:pt-20 md:pb-6 ">
            <p className="text-black mb-4  font-semibold">
              Hier finden Sie uns:
            </p>
            <a href="https://www.google.com/maps/place/Mac+and+Phone+Doc/@48.1977608,16.3504534,15z/data=!4m2!3m1!1s0x0:0x6af33b67a0081359?sa=X&ved=2ahUKEwj_5IO3wvf_AhV8gv0HHYbVC3gQ_BJ6BAg_EAA&ved=2ahUKEwj_5IO3wvf_AhV8gv0HHYbVC3gQ_BJ6BAhLEAg" className="w-1/2" target='_blank' rel='noreferrer' >
              <img height={"250px"} width={"250px"}  src={WienSvg} alt="Wien" style={{ filter: "drop-shadow(rgba(190,190,190,0.5) 2px 2px 3px) drop-shadow(white -1px -1px 0px)", }} />
            </a>
          </div>
        </div>

      </div>
    </footer>
  );
}

export default Footer;
