import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { connection } from '../constants/global'


interface SourceType {
  markenID: number;
  modellID: number;
  markenName: string;
  modellName: string;
  kategorieID: number;
  unterkategorieName: string;
}

const SearchBarComp = () => {
  const [sources, setSources] = useState<SourceType[]>([]);
  const [loading, setLoading] = useState(true);
  const [, setInputValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const resultData = await fetch(connection + "/MacAndPhoneDoc/GetSearch", { method: "GET" });
      const resultJsonData = await resultData.json();
      setSources(resultJsonData);
      setLoading(false);
    }
    fetchData();
  }, [])
  var match = require('autosuggest-highlight/ie11/match');
  var parse = require('autosuggest-highlight/ie11/parse');
  return (
    loading ? <CircularProgress /> : (
      <div >
        <Autocomplete
          disablePortal
          autoComplete={true}
          className="AutoComplete "
          options={sources}
          noOptionsText="Keine Geräte gefunden"
          getOptionLabel={(option) => option.markenName + " " + option.modellName}
          sx={{ width: 300 }}
          renderInput={(params) =>
            <div ref={params.InputProps.ref}>
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
              </div>
              <input type="text" {...params.inputProps} placeholder="Gerät Suchen..." className="block w-full h-5 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            </div>

          }

          onChange={(event: any, newValue: SourceType | null) => {
            if (newValue) {
              window.location.href = `/ReparaturInfos?Marke=${newValue.markenID}&Modell=${newValue.modellID}&kategorie=${newValue.kategorieID}&markenName=${newValue.markenName}&modellName=${newValue.modellName}&unterkategorieName=${newValue.unterkategorieName}`;
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.modellName, inputValue, { insideWords: true });
            const parts = parse(option.modellName, matches);

            return (
              <>{inputValue !== "" &&
                <li {...props}>
                  <div>
                    {parts.map((part: any, index: any) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              }</>
            );
          }}
        />
      </div>
    )
  );
}

export default SearchBarComp;
